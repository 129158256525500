.multi-file-canvas-editor {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 500px;
  overflow: hidden;
  font-size: 14px;
}

.editor-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.editor-wrapper {
  flex: 1;
  height: calc(100% - 40px);
}

.no-file-selected {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #f9f9f9;
}

.no-file-message {
  color: #666;
  font-size: 16px;
}

.canvasEditorContainer {
    width: 100%;
    height: 100%;
}
