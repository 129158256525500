.dataset {
    background:rgb(12, 28, 28);
    width: 100%;
    display: flex;
    position: relative;
}


.benxtAppDesktop .datasetsRight .dataset {

}

.datasetFolder {
}

.datasetsTopLine {
    display: flex;
    align-items: center;
    background: rgb(12, 28, 28);
    position: relative;
}

.datasetsScroller {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    row-gap: 4px;
    overflow-y: auto;
}

.benxtAppDesktop .datasetsRight .datasetsScroller {
    flex-direction: row;
    column-gap: 4px;
    flex-wrap: wrap;
    height: auto;
    max-height: 100%;
}

.datasetsScroller:focus {
    outline: none;
}

.projectErrInfo {
    font-size: 11px;
    color: #c7c7c7;
    font-weight: 500;
    margin-top: 10px;
}

.projectMiddle {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    cursor: pointer;
    max-width: calc(100% - 64px);
    row-gap: 2px;
}

.projectMiddleTop {
    flex-direction: column;
    display: flex;
}

.projectModelInfoRow {
    margin-top: 10px;
    width: 100%;
    flex-wrap: wrap;
    display: none;
}

.projectModelInfoRow > div:last-child .infoSep {
    display: none;
}

.startFineTune {
    display: flex;
    justify-content: flex-end;
}

.dataset-control-buttons .simpleButton {
    background: rgb(1, 160, 144);
    width: 125px;
}

.fineTunedModelsPage {
    display: flex;
    width: 100%;
    height: calc(100% - env(safe-area-inset-bottom));
    row-gap: 1px;
    overflow-y: auto;
    flex-direction: column;
    box-sizing: border-box;
    padding-left: 0px;
    padding-right: 0px;
}

.benxtAppMobile .fineTunedModelsPage {
    padding-left: 0px;
    padding-right: 0px;
}

.fineTunedModelPage {
    display: flex;
    width: 100%;
    height: 100%;
    row-gap: 1px;
    overflow-y: auto;
    flex-direction: column;
}


.fineTunedModel .model {
    font-size: 12px;
}

.projectRight {
    display: flex;
    flex-direction: column;
    width: 32px;
    justify-content: space-between;
    height: 100%;
}

.projectSummary {
    color: #e7e7e7;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    padding-bottom: 10px;
}
.projectSummary h2 {
    font-size: 14px;
}

.projectModelRow {
    min-height: 32px;
    display: flex;
    font-size: 14px;
    column-gap: 8px;
}

.dataset-control-buttons {
    display: flex;
    width: 100%;
    column-gap: 2px;
    justify-content: flex-end;
}

.fileMenu {
    width: 32px;
    position: absolute;
    right: 0;
}

.projectTitle {
    cursor: pointer;
    color: #e7e7e7;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 32px;
    overflow: hidden;
}

.projectIcon {
    height: 32px;
    width: 32px;
    min-width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.projectLeft {
    display: flex;
    flex-direction: column;
    min-width: 32px;
    min-height: 32px;
}

.projectLeftBusy {
    min-width: 32px;
    min-height: 32px;
    display: flex;
    justify-content: center;
}

.projectDate {
    font-size: 13px;
    display: flex;
    justify-content: center;
    white-space: nowrap;
    margin-right: 5px;
}

.projectName {
    color: #e7e7e7;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    white-space: nowrap;
}

.projectFolderIcon svg {
    height: 32px;
    width: 32px;
}

.datasetsCal {
    position: relative;
    margin-top: 0px;
    color: #e7e7e7;
    box-sizing: border-box;
    width: 100%;
    background: rgb(12, 28, 28);
    padding-bottom: 15px;
    margin-bottom: 3px;
}


.projectNameEditor input:focus {
    outline: none;
}

.projectNameEditor input {
    font-size: 16px;
    font-family: apparat;
    min-width: calc(min(45vw, 280px));
}




.modelsPopup {
    height: 100%;
    width: 100%;
}

.modelsPopup .chatGptModelsMenu.fineTunedModelsView {
    transform: none;
}

.model-popup-buttons {
    width: 100%;
    display: flex;
    margin-top: 5px;
    padding-bottom: 10px;
    box-sizing: border-box;
    padding-right: 0px;
    padding-left: 15px;
}

.model-popup-buttons .simpleButton {
    background: rgb(1, 160, 144);
}

.modelsViewPopupButton {
    width: 32px;
    height: 32px;
    min-width: 32px;
}

.datasetsFinetuneJobsLine {
    display: flex;
    width: 100%;
    background:rgb(12, 28, 28);
    color: #e7e7e7;
    font-size: 14px;
    font-weight: 550;
    padding-left: 10px;
    padding-right: 0px;
    box-sizing: border-box;
    flex-direction: column;
}

.datasetsFinetuneJobsLineRow {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.datasetFineTuneJobsTitle {
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    display: flex;
}

.datasetsFinetuneJobsMainTitle {
    display: flex;
    width: 100%;
    background:rgb(12, 28, 28);
    align-items: center;
    color: #e7e7e7;
    font-size: 16px;
    font-weight: 550;
    padding-left: 10px;
    padding-right: 0px;
    min-height: 32px;
    box-sizing: border-box;
    column-gap: 10px;
}


.datasetLeft {
    display: flex;
}

.datasetLeftIcon {
    width: 32px;
    height: 32px;
    min-width: 32px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.datasetRight {
    min-width: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.fineTuneCancelButton {
    position: absolute;
    right: 5px;
    bottom: 5px;
    background: rgb(95, 18, 10);
}

.fineTuneContinueButton {
    position: absolute;
    right: 5px;
    bottom: 5px;
    background: rgb(1, 160, 144);
}

.checkpoints {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 500;
    border: 1px solid #e7e7e7a0;
    padding: 3px;
    color: #e7e7e7;
    margin-top: 5px;
    box-sizing: border-box;
}



.modelConfig {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    row-gap: 4px;
    width: 100%;
}

.modelConfig .tempSlider {
    background: transparent;
}

.continueTrain {
    margin-top: 10px;
    width: 100%;
}

.continueTrainButtons {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.continueTrainButtonsTopRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.continueFineTuneConfig {
    display: flex;
    flex-grow: 1;
    width: 100%;
}

.continueFineTuneConfig .tempSlider {
}

.continueFineTuneConfig .tempSlider .slider1Container {
    width: 100%;
}

.continueFineTuneConfig .tempSlider .slider1Container {
    padding-left: 0px;
    width: 100%;
}

.continueFineTuneConfig .tempSlider .sliderTrack {

}

.continueFineTuneConfig .tempSlider .slider1ContainerLabel {
    padding-left: 0;
}

.datasetExamplesViewChatGPT {
    height: 100%;
    width: 100%;
}

.datasetExamplesViewFineTune .attunewiseDiscussionCal {

}

.datasetExamplesViewFineTune .examplesMenu-recent {
    height: 100%;
}

.datasetExamplesViewFineTune .examplesMenu-all {
    height: 100%;
}

.chatGptThreadsMenuContainer {
    width: 100%;
    height: 100%;
}

.chatGptThreadsMenu {

    padding-top: 2px;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    overflow-y: auto;
    height: calc(100% - 225px - env(safe-area-inset-top));
    width: calc(100%);
}

.chatGptThreadsMenuInfiniteScroll {
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 225px - env(safe-area-inset-top));
    width: calc(100%);
}

.chatGptThreadsMenuInfiniteScroll .itemScroller {
    background: rgb(4, 17, 17);
    row-gap: 4px;
}

.examplesMenu-recent {
    height: calc(100%);
}

.examplesMenu-all {
    height: calc(100% - 150px - env(safe-area-inset-bottom));
}

.datasetChat .examplesMenu-recent {
    height: calc(100% - 190x - env(safe-area-inset-bottom));
}

.datasetChat .examplesMenu-all {
    height: calc(100% - 210px - env(safe-area-inset-bottom));
}

.examplesMenu-day {
    height: calc(100% - 145px - env(safe-area-inset-bottom));
}

.examplesMenu-week {
    height: calc(100% - 210px - env(safe-area-inset-bottom));
}


.trainingInputs {
    display: flex;
    width: 100%;
    flex-direction: column;
    row-gap: 1px;
    background: rgb(0, 25, 26);
    color: #e7e7e7;
    box-sizing: border-box;
    margin-top: 4px;
}

.trainingInputsTitle {
    padding: 10px;
    padding-bottom: -1px;
    width: 100%;
    background: rgb(12, 28, 28);
}

.trainingFiles {
    display: flex;
    width: 100%;
    flex-direction: column;
    row-gap: 1px;
    background: rgb(0, 25, 26);
}

.trainingFile {
    display: flex;
    background: rgb(12, 28, 28);
    flex-direction: column;
    width: 100%;
}

.trainingFileTopRow {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.trainingFileName {
    display: flex;
    color: #e7e7e7;
    align-items: center;
    font-size: 14px;
}

.trainingFileLabel {
    background: rgb(12, 28, 28);
    display: flex;
    height: 32px;
    align-items: center;
}

.trainingFileLabel .simpleIcon {
    min-width: 32px;
}

.trainingFileStatus {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
}


.twoWaySlider {
    display: flex;
    color: #e7e7e7;
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
}

.twoWaySliderLabel {
    height: 32px;
    display: flex;
    min-width: 60px;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    box-sizing: border-box;
    user-select: none;
    background: #3b65c9;
    margin-right: 1px;
    margin-left: 1px;
}

.twoWaySliderValue {
    display: flex;
    min-width: 120px;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    background: rgb(51, 51, 51);
    height: 32px;
    flex-grow: 1;
}

.twoWaySliderSlider {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.twoWaySliderButton {
    height: 32px;
    width: 32px;
    background: rgb(0, 160, 144);
}

.twoWaySliderPct {
    padding-left: 2px;
    padding-right: 2px;
}


.trainingFileStatus .twoWaySlider {
    width: 100%;
    justify-content: space-between;
}


.fineTunedModelsPage .inputControlInputEditor.inputControlEditorDownward {
    width: 100%;
}

.fineTunedModel {
    background: rgb(12, 28, 28);    
    width: 100%;
    padding-bottom: 10px;
    position: relative;
    display: flex;
}

.fineTunedModel-succeeded .datasetLeft svg path {
    fill: rgb(0, 255, 0);
}

.fineTunedModel-failed .datasetLeft svg path {
    fill: rgb(202, 6, 8);
    
}

.fineTunedModel-failed .datasetLeft svg  circle {
    fill: rgb(202, 6, 8);
    
}

.fineTunedModel-cancelled .datasetLeft svg path {
    fill: rgb(255, 104, 18);
    
}

.fineTunedModel-cancelled .datasetLeft svg  circle {
    fill: rgb(255, 104, 18);
    
}

.fineTunedModel-running .datasetLeft svg path {
    fill: rgb(255, 255, 0);
    
}


.fineTunedModel-succeeded .toolsetLeft svg path {
    fill: rgb(0, 255, 0);
}

.fineTunedModel-failed .toolsetLeft svg path {
    fill: rgb(202, 6, 8);
    
}

.fineTunedModel-failed .toolsetLeft svg  circle {
    fill: rgb(202, 6, 8);
    
}

.fineTunedModel-cancelled .toolsetLeft svg path {
    fill: rgb(255, 104, 18);
    
}

.fineTunedModel-cancelled .toolsetLeft svg  circle {
    fill: rgb(255, 104, 18);
    
}

.fineTunedModel-running .toolsetLeft svg path {
    fill: rgb(255, 255, 0);
    
}
.fineTunedModel-running .toolsetLeft svg rect {
    stroke: rgb(255, 255, 0) !important;
    
}

.fineTunedModel-cancelled .jobInfoValue {
    color: rgb(255, 104, 18);
}

fineTunedModel-failed .jobInfoValue {
    color: rgb(202, 6, 8);
}

.fineTunedModel-running .jobInfoValue {
    color: rgb(255, 255, 0);
}

.benxtAppMobile .fineTunedModel {
    padding-bottom: 32px;
}

.taskModels {
    width: 100%;
    display: flex;
    column-gap: 10px;
    flex-wrap: wrap;
    opacity: 0.8;
}

.exampleTitle {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

.infoItem {
    padding-right: 2px;
}

.infoSep {
    display: inline-block;
    padding-left: 2px;
}

.fineTunedModel .editMenuPopup {
    background: transparent;
}

.fineTuningTaskActions .editMenuPopup {
    background: transparent;
}

.fineTuningTaskMenu .simpleButton {

}

.editMenu.fineTuningJobMenu  {
}

.fineTuningJobMenu .simpleButton {
}

.huggingFaceDataset {
    display: inline-flex;
    flex-grow: 1;
    align-items: center;
    color: #e7e7e7;
    flex-grow: 1;
}

.huggingFaceDataset .simpleIcon {
    min-width: 32px;
}

.datasetIcon {
    height: 32px;
    width: 32px;
}

.datasetIcon .simpleIcon {
    height: 32px;
    width: 32px;
}

.providerViewSamples {
    display: flex;
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    padding-right: 0px;
}

.providerViewSamples .slider1Container {
    padding-left: 0px;
}

.providerViewSamples .slider1ContainerLabel {
    padding-left: 0;
}

.providerViewSamples .sliderTrack {

}

.datasetChat .keyboardHeaderButtonCancel {
    visibility: visible;
    background: transparent;
}

.datasetChat .keyboardHeaderButtonCancel .simpleButton .simpleIcon svg path {
    fill: rgb(0, 255, 0) !important;
}

.providerViewModels .importedModel {
    cursor: pointer;
}

.benxtAppDesktop .providerViewModels {
    height: calc(100% - 32px);
}

.benxtAppMobile .providerViewModels {
    height: calc(100% - 32px);
}

.benxtAppDesktop providerView {
    overflow-y: visible;
}

.importedDatsetTopRowRight {
    display: flex;
    justify-content: space-between;
}

.importedDatasetTopRow {
    display: flex;
    align-items: center;
    width: calc(100% - 10px);
    justify-content: space-between;
    padding-right: 10px;
}

.importedDatasetBottomRow {
    display: flex;
    align-items: center;
    width: calc(100% - 32px);
    padding-left: 32px;
    padding-bottom: 5px;
}

.importedDatasetTopRowLeft {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 550;
}

.importedDatasetExampleCount {
    font-size: 11px;
    color: #e7e7e7;
    display: flex;
    align-items: center;
    font-weight: 500;
}

.datasetProvider {
    font-weight: 500;
    padding-right: 5px;
    display: inline-block;
}

.datasetLabel {
    overflow-wrap: break-word; /* Allows wrapping on words and special characters */
    word-break: break-all;
    align-items: center;
    display: inline-block;
    line-height: 1.5;
}

.huggingFaceDatasetLeft {
    display: flex;
    width: 32px;
    flex-direction: column;
}

.huggingFaceDatasetLeft .simpleIcon {
    width: 32px;
    height: 32px;
}

.huggingFaceDatasetRight {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.benxtAppDesktop .fineTuningJobSelected {
    background: rgb(10, 69, 97);
}

.datasetsRight .providerView {
}

.sampled {
    height: 100%;
    width: 100%;
}

.sampledSamples {
    white-space: pre-wrap;
    font-size: 13px;
    padding-left: 10px;
    padding-right: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 40px);
    overflow-y: auto;
    color: #d0d0d0;
    padding: 15px;
    padding-top: 0px;
}

.sampledTitle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: rgb(12, 28, 28);
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 4px;
}

.sampledTitleIcon {
    display: flex;
    align-items: center;
}

.sampledTitle .simpleButton {
    background: #3b65c9;
    margin-right: 10px;
    min-width: 90px;
}

.benxtAppMobile .sampledTitle .simpleButton {
    min-width: 105px;
}
    

.ftLauncherModelSelectionRow {
    margin-top: 5px;
    display: flex;
    align-items: center;
    width: 100%;
    column-gap: 4px;
}

.ftLauncherModelSelectionRow .simpleButton {
    background: #3b65c9;
}

.ftLauncherSection {
    width: 100%;
    display: flex;
    min-height: 0px;
    padding-top: 0px;
    flex-direction: column;
    padding-bottom: 8px;
    width: 400px;
}

.ftLauncherSectionTitle {
    font-size: 14px;
    padding: 5px;
}

.ftLauncherSectionContent {
    font-size: 14px;
}

.hfDatasetDescription {
    font-size: 14px;
    padding: 15px;
    white-space: pre-wrap;
}

.ingestedSamples {
    height: 100%;
    width: 100%;
}

.samplesView {
    height: calc(100% - 90px);
}

.samplesView .itemScroller {
}

.simpleChatMessage {
    font-size: 13px;
    padding: 10px;
    background: rgb(13, 32, 51);
    margin-top: 4px;
}

.simpleChatMessageModels {
    background: rgb(13, 32, 51);
}

.simpleChatMessage-User {
    background: rgb(12, 28, 28);
    margin-bottom: 4px;
}

.simpleChatMessage-Assistant {
    background: rgb(13, 32, 51);
}

.simpleChatMessage-System {
    background: #0f2733;
    margin-bottom: 4px;
}

.simpleChatMessageModelName {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
}

.sampleViewport {
    display: flex;
    height: calc(100% - 10px);
    width: 100%;
}

.providerViewDatasets {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1px;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
}

.jobInfo {
    width: 100%;
    display: flex;
    column-gap: 4px;
    row-gap: 4px;
    padding-top: 15px;
    display: flex;
    flex-wrap: wrap;
}

.benxtAppMobile .jobInfo {
    flex-direction: column;
}

.jobInfoLabel {
    display: flex;
    align-items: center;
    white-space: nowrap;
    height: 100%;
}

.jobInfoLine {
    display: flex;
    height: 32px;
    align-items: center;
    column-gap: 7px;
    width: calc(100% / 6 - 1%);
    justify-content: space-between;
}

.jobInfoValue {
    padding-right: 8px;
    color: rgb(0, 255, 0);
}

.jobInfoValue .modelLabel {
    color: rgb(0, 255, 0);
}

.jobInfoBox {
    width: 16px;
    height: 32px;
    margin-right: 5px;
}

.jobInfoBox-Succeeded {
    background: rgb(0, 255, 0);
}

.jobButtons {
    display: flex;
}

.jobButtons .simpleIcon svg path {
    fill: rgb(0, 255, 0);
}

.breadcrumb-model {
    height: 32px;
    overflow: visible;
    display: flex;
    align-items: center;
}

.datasetExamplesSidebar {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 0px;

}

.datasetExamplesSidebarButtons {
    margin-top: 4px;
    display: flex;
    column-gap: 4px;
    margin-bottom: 4px;
    justify-content: space-between;
    width: 100%;
}

.datasetExamplesSidebarButtonsLeft {
    display: flex;
}

.datasetExamplesSidebarButtonsRight {
    display: flex;
}

.datasetExamplesSidebarButtons .simpleButton {
    background: rgb(12, 28, 28);
}
    
.datasetExamplesSidebarButtons .simpleButton:hover {
  /*  background: rgb(1, 79, 75); */
}
    
.datasetExamplesSidebar .bnContentPage {
    height: calc(100% - 64px);
}

.datasetExamplesSidebarWithSelection .bnContentPage {

}

.datasetExamplesCount {
    height: 32px;
    padding-left: 32px;
    padding-top: 0px;
    font-size: 14px;
    color: rgb(209, 152, 19);
    align-items: center;
    display: flex;
}

.heightMapTitle {
    display: flex;
    margin-top: 10px;
    align-items: center;
}

.trainingLoss {
    cursor: pointer;
    color: #979797;
    cursor: pointer;
}

.validationLoss {
    margin-left: 8px;
    cursor: pointer;
    color: #979797;
}

.showValidationLoss .validationLoss {
    color: #e7e7e7;
}

.showTrainingLoss .trainingLoss {
    color: #e7e7e7;
}

.heightMapContainer {
    margin-top: -15px;
    transform: translate(-16px, 0);
}

.fineTuningJobDetails .toolsetContent {
}

.fineTuningJobDetails .toolsetRight {
    width: auto;
}

.fineTuningJobLauncherView {
    display: flex;
    min-height: 32px;
    width: 100%;
    flex-direction: column;
}

.fineTuningJobLauncherViewTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.fineTuningJobLauncherContent {
    display: flex;
    min-height: 0;
    width: 100%;
    column-gap: 4px;
}

.fineTuningJobModelSelection {
    display: flex;
    min-height: 0;
    width: 100%;
    padding-bottom: 0px;
}


.datasetFineTuningJobLauncherDetail {
    display: flex;
    min-height: 0;
    width: 100%;
}

.datasetFineTuningJobLauncherDetail .bnContentPage {
    display: flex;
    min-height: 0;
    height: auto;

}
.datasetFineTuningJobLauncherDetail .bnPageBackground {
    display: flex;
    min-height: 0;
    height: auto;

}

.datasetFineTuningJobLauncherDetail .modelsView {
    width: 100%;
}

.datasetFineTuningJobLauncherDetail .bnPageContentSidebarContainer {
    width: 100%;
}
.datasetFineTuningJobLauncherDetail .bnPageContentSidebar {
    width: 100%;
}

.datasetFineTuningJobLauncherDetail .bnPageContentDetail {
    display: none;
}

.datasetFineTuningJobLauncherDetail .vendorItemsInline {
    max-height: none;
}

.modelError {
    width: 100%;
    display: flex;
    justify-content: center;
    color: rgb(202, 6, 8);
    font-size: 13px;
    padding: 4px;
}

.jobTop {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.jobBottom {
    width: 100%;
}

.continueConfigPopup {
    display: flex;
    width: 700px;
    column-gap: 32px;
    padding-right: 15px;
}

.continueConfigPopupTitle {
    font-size: 14px;
    display: flex;
    color: #e7e7e7;
    padding: 5px;
}

.continueConfigPopupContent {
    display: flex;
    min-width: 350px;
    max-width: 350px;
    flex-direction: column;
}

.exampleReps {
    color: rgb(209, 152, 19);
    height: 32px;
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-right: 8px;
}
