.lossGraphContainerStyle {
    width: 100%;
    height: calc(100% - 50px);
    margin-top: 50px;
    padding: 15px;
    padding-top: 0px;
    border-radius: 5px;
    background: rgb(19, 35, 36);
    box-sizing: border-box;
}

.lossGraphTitleStyle {
    margin-top: 10px;
    font-size: 13px;
    marginBottom: 16px;
    color: #e7e7e7;
    transform: translate(10px, 10px);
}

.lossGraphDataset {
    height: 40px;
    display: flex;
    align-items: center;
    position: absolute;
    top: 28px;
    width: 100%;
    max-width: 770px;
    justify-content: center;
}

.lossGraphGraph {
    font-size: 13px;
    width: 100%;
    height: calc(100% - 80px);
}
