.chatGptToolsetsMenu {
    width: calc(100% - 0px);
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
}

.benxtAppMobile .chatGptToolsetsMenu {
    width: 100%;
    padding: 0;
}

.chatGptToolsetsMenuToolsets {
    width: 100%;
    display: flex;
    flex-direction: column;
    column-gap: 4px;
    row-gap: 4px;
    overflow-y: auto;
}

.toolsetTopLine {
    width: calc(100% - 0px);
    display: flex;
    margin-bottom: 1px;
    margin-left: 0px;
}

.toolsetTopLine .simpleButton {
    background: rgb(49, 47, 102);
    background: rgb(12, 28, 28);
}

.toolsetsCal {
    position: relative;
    margin-top: 0px;
    color: #e7e7e7;
    box-sizing: border-box;
    width: 100%;
    background: rgb(49, 47, 102);
    background: rgb(12, 28, 28);
    padding-bottom: 15px;
}

.toolsetsCal .usageCalendar {
    background: rgb(12, 28, 28);
}


.toolsetTopLineSelect .simpleButton {
    display: none;
}

.toolsetTopLine .searchFieldInput {
    width: calc(100% - 32px);
}

.toolsetTopLineSelect .searchFieldInput {
    width: calc(100%);
}

.toolsetTopLine .searchFieldInput .inputControlInputEditor.inputControlEditorDownward {
    width: 100%;
}

.toolsetTask {
    background: rgb(12, 28, 28);
    display: flex;
    width: 100%;
    padding-bottom: 15px;
}

.toolsetTaskSelected {
    background: rgb(10, 69, 97);
}

.toolsetRight {
    display: flex;
    flex-direction: column;
    width: 32px;
    height: 100%;
}

.toolsetRight .editMenuPopup .{
    background: transparent;
}

.toolsetTaskSelected .toolsetRight .editMenuPopup .simpleButton {
    background: rgb(10, 69, 97);
}

.toolsetLeft {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.toolsetLeftTopRow {
    display: flex;
}

.toolsetLeftTopRowLeft {
    padding-right: 5px;
    display: flex;
    flex-grow: 1;
}

.toolsetLeftTopRowLeftCentered {
    padding-right: 5px;
    display: flex;
    flex-grow: 1;
    align-items: center;
}

.toolsetLeftTopRowLeftIcon {
    display: flex;
}

.toolsetTopRowRight {
    display: flex;
    align-items: center;
    height: 32px;
    padding-right: 10px;
}

.toolsetLeft .simpleIcon {
    width: 32px;
    height: 32px;
}

.toolsetMiddle {
    display: flex;
    flex-grow: 1;
    color: #e7e7e7;
    flex-direction: column;
    width: calc(100% - 64px);
    font-size: 14px;
}

.toolsetMiddleTopRow {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 32px;
}

.toolsetTitle {
    display: flex;
    color: #e7e7e7;
    font-weight: 500;
    padding-top: 5px;
}

.toolsetContent {
}

.toolsetMiddleDescription {
    width: calc(100% - 0px);
}

.toolsetDate {
    font-size: 13px;
}

.toolsetTitle input {
    font-size: 16px;
    font-family: apparat;
    min-width: calc(min(45vw, 280px));
    color: #e7e7e7;
    background: rgb(12, 28, 28);
    border: 0;
}

.benxtAppDesktop .toolsetTitle input {
    min-width: 250px;
}

.toolsetTitle input:focus {
    caret-color: white;
    background: rgb(255, 255, 255, 0.1);
    outline: none;
    margin-left: -5px;
    padding-left: 5px
}

.toolsetEditorContainer {
    height: calc(100%  - 0px);
    width: 100%;
    position: relative;
}

.toolsetEditorBottomRow {
    padding: 32px;
    white-space: pre-wrap;
    font-size: 14px;
}

.toolsetEditorButtons {
    width: 100%;
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    justify-content: flex-end;
}

.toolsetEditorBg {
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 5px);
    background: rgb(12, 28, 28);
    display: flex;
    justify-content: space-between;
}

.toolsetEditorLeft {
    width: calc(100%);
    height: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    row-gap: 4px;
    background: rgb(12, 28, 28);
}

.toolsetEditorRight {
    position: absolute;
    right: 0;
}


.toolsetEditor {
    color: #e7e7e7;
    font-size: 16px;
    font-family: apparat;
    caret-color: white;
    box-sizing: border-box;
    padding-top: 10px;
    padding-left: 10px;
    height: calc(100% - 5px);
    padding-right: 32px;
    overflow-y: auto;
}

.toolsetEditor:focus {
    background: rgb(255, 255, 255, 0.1);
    outline: none;
}

.toolsetEditorButtons {

}
.toolsetEditorButtons .simpleButton {
    background: #3c65c9;
}

.toolsetActions .simpleButton {
    background: rgb(12, 28, 28);
}

.toolsetDelete .simpleButton {
    background: rgb(95, 18, 10);
}

.toolsetTool {
    width: calc(100% - 32px);
    display: flex;
    background: rgb(12, 28, 28);
    padding: 20px;
}


.toolsetTool code {
    white-space: pre-wrap;
    max-width: calc(100% - 32px);
    font-family: apparat;
    width: 100%;
}

.desktopToolsetDetailsTitle {
    background: rgb(12, 28, 28);
    height: 32px;
    margin-bottom: 0px;
    color: #e7e7e7;
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.toolsetEditorPage {
    height: 100%;
    width: 100%;
    overflow-y: auto;
}

.toolsetEditorPage:focus {
    outline: none;
}

.toolServer {
    width: 100%;
    height: 100%;
}

.toolServerFields {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 4px;
    background: rgb(19, 35, 36);
}

.toolServerField {
    background: rgb(8, 59, 50);
    background: rgb(12, 28, 28);
    width: 100%;
    padding-top: 10px;
}

.toolServerButtons {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
}

.toolServerButtons .simpleButton {
    background: #3c65c9;
}

.toolServerTools {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    
}

.toolServerToolset {
    display: flex;
    width: 100%;
    flex-direction: column;
    row-gap: 4px;
    background: rgb(19, 35, 36);
    overflow-y: auto;
    height: 100%;
}

.toolServerToolset .toolsetTool {
    background: rgb(12, 28, 28);
}

.toolsetServerToolsetTitle {
    min-height: 32px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.toolServerDetail {
    width: 100%;
    height: 100%;
}

.toolServerLogs {
    position: relative;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    padding: 5px;
}

.toolsetSelectButton {
    position: relative;
    height: 40px;
}

.toolsetSelectButton .simpleButton {
    right: 10px;
    bottom: 0px;
}

.desktopToolsetDetails .chatGptToolsetsMenuToolsets {
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 4px;
}

.desktopToolsetDetails .toolsetTask {
}

.serverStatus {
    padding: 10px;
    height: calc(100%  - 20px);
    width: calc(100% - 20px);
}

.serverStatusTop {
    display: flex;
    width: 100%;
    column-gap: 10px;
}

.serverStatuses {
    background: rgb(12, 28, 28);
    width: 100%;
    display: none;
}

.serverStatusBottom {
    width: 100%;
    height: calc(100% - 32px);
    overflow-y: auto;
}

.desktopToolsetFiles {
    width: 100%;
    height: 100%;
}
.desktopToolsetFilesScroller {
    height: calc(100% - 32px);
}

.desktopToolsetFiles .itemScroller {
    display: flex;
}
.desktopToolsetFiles .chatGptToolsetsMenuToolsets .toolsetTask {

}

.toolsetTask {
    cursor: pointer;
    flex-direction: column;
    font-size: 14px;
}

.toolsetBottom {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: -10px;
}

.toolsetBottom .simpleButton {
    margin-right: 10px;
}

.toolsetTop {
    display: flex;
    width: 100%;
}

.toolsetHeader {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 7.5px;
    font-weight: 500;
    font-size: 15px;
}

.toolsetHeading {
    width: calc(100% - 32px);
    transform: translate(0, -1px);
}

.toolsetSummary {
    padding-top: 5px;
    width: 100%;
    padding-bottom: 10px;
    font-weight: 400;
    font-size: 13px;
}

.toolsetBottomRow {
    padding-left: 32px;
    width: calc(100% - 64px);
}

.toolServerStatus-running svg path {
    fill: rgb(0, 255, 0);
}


.toolServerLogs .itemScroller {
    row-gap: 4px;
}

.benxtAppMobile .toolServer {
    padding-left: 10px;
    padding-right: 10px;
    width: calc(100% - 20px)
}

.logRecord {
    font-size: 14px;
    display: flex;
    background: rgb(12, 28, 28);
    flex-wrap: wrap;
}

.logRecordLog {
}

.logRecordTimestamp {
    min-width: 32px;
}

.logRecordSeverity {
    min-width: 50px;
}

.logRecordLog {
}

.desktopToolsetActions {
    display: flex;
    flex-wrap: wrap;
    column-gap: 4px;
}

.desktopToolsetActions .simpleButton {
    font-size: 14px;
    background: rgb(17, 26, 26);
}

.toolsetFooter {
    background: rgb(17, 26, 26);
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.toolsetFooterRight {
    width: calc(100% - 435px);
    background: yellow;
    align-items: center;
    position: absolute;
    right: 0;
}

.toolsetItemCount {
    display: flex;
    color: rgb(209, 152, 19);
    justify-content: center;
    align-items: center;
    font-size: 12px;
    height: 32px;
    position: absolute;
    left: 0;
}

.toolsetCalendarPopup {
    width: 400px;
    height: 32px;
    position: absolute;
    left: 375px;
}

.toolsetFooterRight .toolsetItemCount {
    position: absolute;
    left: 0px;
}

.toolsetCalendarPopup .toolsetsCal {
    position: absolute;
    bottom: 0px;
    left: 0
}

.toolsetCalendarPopup .usageCalendar {
    display: flex;
    flex-direction: column-reverse;
    background: rgb(1, 60, 84);
    justify-content: flex-end;
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 100%;
    padding: 0;
}

.toolsetCalendarPopup .toolsetsCal .usageCalendarViewChoiceSelected {
    background: rgb(1, 60, 84);
}

.toolsetCalendarPopup .toolsetsCal .usageCalendarViewSelector {
    padding-top: 4px;
    background: rgb(17, 17, 26);
}

.toolsetContent {
    width: 100%;
}

.toolsetPageHeader {
    margin-bottom: 1px;
    display: flex;
}

.toolServerLogLink {
    cursor: pointer;
    display: inline-block;
}

.toolServerLogLink:hover {
    color: rgb(0, 255, 0);
}

.logInfo {
    position: absolute;
    top: 0;
    color: #e7e7e7;
}
