.bnPage {
    /*background: rgb(239, 239, 244);*/
    width: 100%;
    height: 100%;
}

.bnFastDetectClientIPad .bnPage {
    overflow-x: hidden;
}

.bnPageCamera {
    width: 100%;
    height: 100%;

    position: relative;
}

.bnPageSafeArea .bnPageButtons` {
}

.bnPageScroller {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.bnPageScrollerTitle {
    minHeight: 25px;
    width: 100%;
    text-align: center;
}

.bnPageContentArea {
    width: 100%;
    min-height: 0;
    flex-grow: 1;
}

.bextAppDesktop .bnPageContentArea {
    width: 350px;
    left: 0;
}

.bnPageContentAreaWithHeader {
    height: auto;
    flex-grow: 1;
    flex-shrink: 1;
}

.bnPageContent {
    position: absolute;
    transition: left 0.4s cubic-bezier(0.25, 0.1, 0.25, 1.0);
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0%;
    background: rgb(4, 17, 17);
}

.bnSubpageTopLevel {
    background: rgb(4, 17, 17);
}

.benxtAppMobile1 .bnPageContent {
    margin-top: calc(env(safe-area-inset-top));
    height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom));
}

.benxtAppMobile .bnPageSubpage .bnPageContentArea {
    /*
    width: calc(100% - 20px);
    margin-left: 10px;
   */
}

.bnPageBackground {
/*    background: black; */
    width: 100%;
    height: 100%;
}

.bnPageButtons {
    min-height: 32px;
    height: 32px;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
    top: 0;
    z-index: 1;
    margin-bottom: 0px;
}

.bnPageBack {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.bnPageBackLabel {
    color: rgb(40, 140, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 520;
}

.bnPageNextLabel {
    color: rgb(40, 140, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 520;
    cursor: pointer;
}


.bnPageCancelLabel {
    color: #c90000;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}



.bnPageHeaderSpacer {
    width: 55px;
    height: 32px;
}


.bnPageTitle {
    max-width: calc(100% - 32px);
    overflow: hidden;
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.bnPageTitleContent {
    width: 100%;
    color: white;
    font-size: 16px;
    display: flex;
    align-items: center;
    pointer-events: none;
    white-space: nowrap;
}

.bnPageSubtitle {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    color: rgb(112, 112, 112);
    line-height: 0.5;
}


.bnPageHeaderButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
}

.bnPageEmbed .bnPageHeaderButtons {
    padding-top: 0;
}

.bnPageEmbed .bnPageTitleRow {
    display: none;
}

.bnPageBackIcon {
    height: 35px;
    width: 33px;
    overflow: hidden;    
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.bnPageBackIcon div {
    display: flex;
    justify-content: center;
    align-items: center;
}
    
.bnPageBackIcon svg {
    fill: #007bff;
    fill: rgb(40, 140, 255);
    height: 45px;
    width: 45px;
}

.bnPageBackIcon .cls-2 {
    fill: #007bff;
    fill: rgb(40, 140, 255);
    height: 32px;
    width: 32px;
}

.bnPageBackIcon .cls-3 {
    display: none;
}

.bnPageSearch {
    padding-top: 14px;
    margin-left: 15px;
    margin-right: 15px;
    padding-bottom: 15px;
}

.bnPageCustomSearch {
    padding-top: 14px;
    margin-left: 15px;
    margin-right: 15px;
    width: calc(100% - 30px);
    padding-bottom: 15px;
}

.bnPageHeader {
}

.bnPageBody {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    height: 100%;
    transition: opacity 0.4s cubic-bezier(0.25, 0.1, 0.25, 1.0);
}


.bnPageTitleRow {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
}

.bnPageUserIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
}

.bnSubpageContainer {
    width: 100%;
    min-height: 100%;
}

.bnSubpageContent {
    width: 100%;
}

.bnPageSubpage {
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    transition: opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1.0);
}

.benxtAppDesktop .bnPageSubpage {
}

.bnPagePopupBg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: -100vh;
    left: 0;
    background: rgb(0, 25, 26);
    z-index: 2000;
    transition: top 0.4s cubic-bezier(0.25, 0.1, 0.25, 1.0);
}

.bnPagePopup {
    position: absolute;
    top: 0px;
    left: 0;
    height: calc(100% - 45px);
    width: 100%;
    border-radius: 10px 10px 0px 0px;
}

.bnPagePopupBgSlideOut {
    top: 100%;
}

.bnPagePopupBgSlideIn {
    top: 0;
}

.bnPagePopup .bnPageHeaderButtons {
    margin-top: 0;
}

.bnPagePopup .bnContentPage {
    padding-top: 10px;
    background: rgb(0, 21, 26);;
    height: calc(100% - 10px);
}

.bnPagePopup .bnPagePopupTitle .bnLabel1 {
    color: white;
}

.bnPagePopupTitle .bnLabel1 {
    margin-bottom: 0;
    height: 100%;
    font-size: 16px;
}

.bnPagePopup .bnPageScroller {
}

.bnPagePopupTop {
    height: 45px;
    width: 100%;
    left: 0;
    top: -45px;
    position: absolute;
}

.bnPagePopupBump {
    position: absolute;
    bottom: 0;
    left: 16px;
    height: 10px;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    background: #bababa;
    display: none;
}

.uiTeTeAppAndroid.uiTeTeAppNative .bnPagePopupBump {
}
            
.bnPageTitleImage {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 15px;
}

.bnPageTitleImage img {
    height: 85px;
    object-fit: cover;
}

.bnPageFooter {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
}

.bnContentPage {
    height: 100%;
    width: 100%;
}

.bnPagePopupTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: calc(100% - 150px);
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
}

.bnMobileWebPopup {
    border-radius: 12px 12px;
    overflow: hidden;
}


.bnMobileWebPopup .bnOKButtonLabel {
    color: white;
}

.bnPagePopup .bnPageButtons .bnOKButton {
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
    overflow: hidden;
}

.bnPagePopup .bnPageButtons .bnBackButton:hover {
    color: white;
    background: rgb(138, 0, 0);
}

.bnPagePopup .bnPageButtons .bnBackButtonLabel {
    width: 100%;
}

.bnPagePopup .bnPageButtons {
    transform: none;
    margin-top: 0;
    height: 35px;
}

.bnPagePopup .bnPageButtons .bnBackButton {
    align-items: center;
    background: black;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    height: 100%;
    width: 60px;
    
}

.bnPageContentSlideOut {
    top: 0;
    left: 0%;
}

.bnPageContentSlideIn {
    top: 0;
    left: -100%;
}

.bnPageContentFadeOut {
    opacity: 0;
    pointer-events: none;
}

.bnPageContentFadeIn {
    opacity: 1;
    pointer-events: auto;
}


.bnPageContentWithDetail {
    position: relative;
    height: 100%;
    width: 100%;
    column-gap: 4px;
    display: flex;
}

.bnPageContentDetail {
    height: 100%;
    width: calc(100% - 430px - 4px);
    background: rgb(4, 17, 17);
}

.bnPageContentSidebar {
    height: 100%;
    width: 430px;
}

.bnPageContentSidebarContainer {
    height: 100%;
    width: 430px;
}

.bnPageContentWithFooter {
    height: calc(100% - 0px);
}

.bnPageContentFooter {
    height: 32px;
    width: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 50%);
    position: fixed;
    bottom: 0;
}


.breadcrumb-root {
    cursor: pointer;
}

.breadcrumb-root svg {
    height: 48px;
    width: 48px;
}

.breadcrumb-root svg path {
    fill: rgb(0, 255, 0);
}

.breadcrumb-file {
    color: #e7e7e7;
    max-height: 32px;
    display: flex;
    align-items: center;
}

.breadcrumb-file-active {
    cursor: pointer;
}

.breadcrumbPath {
    display: flex;
    align-items: center;
    height: 32px;
    overflow: visible;
    flex-grow: 0;
}

.breadcrumbs {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.pageDoneButton .simpleButton {
    /*
    background: rgb(59, 101, 201);
*/
}

.breadcrumb-sep {
    margin-left: 4px;
    margin-right: 4px;
}

.bnPageContentHeader {
    width: 100%;
    flex-grow: 0;
}
