.crossfade-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.fade-component {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1.0);
}

.fade-component-visible {
  opacity: 1;
}
