.searchGpt {
    width: calc(100% - 20px);
    height: calc(100% - 0px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    margin-left: 10px;
    margin-right: 10px;
    position: relative;
    overflow: hidden;
}

.benxtAppDesktop .searchGpt {
    overflow: hidden;
}

.benxtAppDesktop .searchGptNonEmpty {
    overflow: auto
}

.benxtAppMobile .searchGpt {
    height: 100%;
}

.bnAppContentSearchGpt .attunewiseHeader {
    display: none;

}

.searchGptContent {
    padding-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.benxtAppDesktop .searchGptContent {
    margin-top: 70px;
    width: 100%;
}

.benxtAppDesktop .searchGptNonEmpty .searchGptContent {
    margin-top: 0px;
    width: 100%;
    max-width: 1200px;
    flex-direction: row;
    justify-content: space-between;
}

.benxtAppDesktop .searchGpt {
}

.benxtAppDesktop .searchGptBody {
}

.searchGptBody svg {
    height: 32px;
    width: 32px;
}

.searchGptBody .inputControl svg {
    height: 32px;
    width: 32px;
}

.searchGptBody .mardownContainer svg {
    height: 32x;
    width: 32px;
}

.benxtAppMobile .searchGptInputContainer {
    width: 100%;
}

.searchGptInput {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-shrink: 0;
}

.benxtAppDesktop .searchGptInput {
    min-width: 650px;
    width: 650px;
    flex-grow: 0;
}

.searchGptHeaderLeft  {
    height: 100%;
    display: flex;
    min-width: 230px;
    flex-grow: 1;
    justify-content: flex-end;
}

.searchGptHeaderLeft div {
    height: 40px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
}

.searchGptHeaderLeft div svg {
    height: 28px;
    min-width: 230px;
    display: flex;
    align-items: center;
}

.searchGptHeaderRight  {
    flex-grow: 1;
    height: 100%;
    min-width: 230px;
    display: flex;
    justify-content: flex-end;
}

.searchGptHeaderRightSpacer  {
    min-width: 230px;
}

.searchGptHeaderRight .div {
    width: 230px;
    height: 40px;
    display: flex;
    align-items: center;
}

.searchGptHeaderRight div svg {
    height: 32px !important;
    width: 32px !important;
    display: flex;
    align-items: center;
    fill: #e7e7e7;
}

.searchGptBody {
    width: 100%;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    display: flex;
}


.searchGptBodyTopRow {
    flex-grow: 1;
    flex-direction: row;
    align-items: center;
    display: flex;
    min-width: calc(650px);
}

.benxtAppMobile .searchGptBodyTopRow {
    width: 100%;
    min-width: auto;
}

.searchGptNonEmpty {
    height: calc(100% - 0px);
    overflow-y: auto;
}

.searchGptNonEmpty .searchGptBodyTopRow {
    width: 100%;
}

.searchGptHeader {
    display: flex;
    width: 100%;
    align-items: center;
    column-gap: 5px;
    justify-content: center;
    margin-bottom: 10px;
}

.searchGptHeader div {
    height: 40px;
}

.searchGptHeader svg {
    height: 100%;
}

.searchGptAutocompletes {
    position: absolute;
    z-index: 10;
    display: flex;
    flex-direction: column;
    row-gap: 0px;
    width: 100%;
}

.benxtAppMobile .searchGptAutocompletes {
    max-height: 44vh;
    overflow-y: auto;
}

 
.searchGptAutocomplete {
    display: flex;
    background: rgb(3, 74, 122);
    color: #e7e7e7;
    column-gap: 0px;
    cursor: pointer;
}

.searchGptAutocompleteImg {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
}

.searchGptAutocompleteImg img {
    height: 30px;
    width: 30px;
}

.searchGptAutocompleteText {
    display: flex;
    transform: translate(0, 0px);
}
.searchGptAutocompleteText .markdownContainer {
    padding-top: 6px;
    padding-bottom: 0;
}

.searchGptSearchTopRow {
    width: 100%;
    display: flex;
}

.searchGptSearchTopRow .simpleButton {
    background: rgb(3, 74, 122);
    width: 30px;
}

.searchGptBody1 .simpleIcon svg {
    height: 32px;
    width: 32px;
}

.searchIconStop .simpleIcon svg {
    height: 32px;
    width: 32px;
}

.searchIconSpin .simpleIcon svg {
    height: 32px;
    width: 32px;
}

.searchGptSearchProgress {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;

}

.searchGptProgressMessage {
    color: #e7e7e7;
    background: rgb(1, 42, 44);
    padding: 20px;
    box-sizing: border-box;
    display: flex;
}

.searchGptProgressMessage .simpleIcon {
}

.searchQuery {
    color: #e7e7e7;
    background: #0f2733;
    box-sizing: border-box;
    margin-bottom: 0px;
    display: flex;
    padding-top: 10px;
}

.searchQueryIcon .simpleIcon {
    height: 32px;
    width: 32px;
}

.searchQueryIcon .simpleIcon svg {
    height: 32px;
    width: 32px;
}

.searchQueryContent {
    width: calc(100% - 72px);
    padding-top: 5px;
    padding-bottom: 15px;
}

.searchGptSearch {
    width: 100%;
    position: relative;
}

.searchGptSearchResult {
    width: 100%;
    background: rgb(1, 42, 44);
    color: #e7e7e7;
    box-sizing: border-box;
    margin-top: 5px;
}

.searchGptSearchResult .markdownContainer {
    box-sizing: border-box;
    margin-left: 24px;
    width: calc(100% - 40px);
    padding: 10px;
}

.benxtAppMobile .searchGptSearchResult {
    width: 100%;
}

.searchGptBody .model {
    margin-left: -10px;
    margin-top: -10px;
    background: transparent;
}

.searchGpt .modelVendorLabel {
}

.searchGpt .modelName {
    color: #87a7a1;
}

.searchGpt .model.modelLegacy  {
    color: #87a7a1;
    text-decoration-color: #87a7a1 !important;
    text-decoration: none;
}

.searchGpt .modelVision {
    display: none;
}

.deleteResult {
    position: absolute;
    right: 0;
    top: 5px;
}

.searchResultContainer {
    width: 100%;
    position: relative;
}

.deleteResult .simpleIcon svg {
    height: 32x;
    width: 32px;
}

.searchGptModels {
    min-height: 40px;
    margin-left: 0px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    overflow: hidden;
    position: relative;
}

.benxtAppMobile .searchGptModels {
    min-height: 40px;
}

.benxtAppMobile .searchGptButtonNav {
    height: 40px;
    width: 40px;
}

.modelsBack {
    background: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 50%);
    position: absolute;
    left: 0px;
    z-index: 2;
    width: 40px
}

.modelsFwd {
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 50%);
    position: absolute;
    right: 0px;
    z-index: 2;
    width: 40px
}

.swiperNavHidden .simpleButton {
    display: none;
}

.swiperNavHidden.modelsFwd {
    background: transparent;
}

.swiperNavHidden.modelsBack {
    background: transparent;
}

.searchGptButtonNav {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
}

.searchGptButtonNav .simpleButton {
    position: absolute;
    height: 25px;
    width: 25px;
}

.searchGptButtonNav .simpleButton .simpleIcon svg  {
    height: 32px;
    width: 32px;
}

.searchGptButtonNav .simpleButton .simpleIcon svg path {

    fill: rgb(0, 255, 0) !important;
}

.benxtAppMobile .searchGptModels {
    justify-content: flex-start;
}

.benxtAppDesktop .searchGptModels {
    justify-content: flex-start;
    max-width: calc(650px);

}

.searchGptModels .swiper {
    flex-grow: 1;
}

.searchGptModels .swiper-slide {
    flex: 1 0 auto;
    width: auto;
}

.searchTranscript {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    margin-top: 15px;
    row-gap: 5px;
    height: calc(100% - 70px);
}

.benxtAppDesktop .searchTranscript {
    max-width: 650px;
}

.searchGptModelButton {
    height: 32px;
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.searchGptModelButton .simpleButton  {
    height: 100%;
    min-height: auto;
    min-width: auto;
    column-gap: 0;
}

.searchGptModelButton .simpleButton .simpleIcon {
    height: 32px;
    width: 32px;
    min-height: auto;
    min-width: auto;
}

.searchGptModelButton .simpleButton .simpleIcon svg {
    height: 32px;
    width: 32px;
}

.searchGptModelButton .simpleButtonWithIcon.simpleButtonWithLabel .simpleButtonLabel {
    padding-right: 0px;
}

.searchGptModelButton .simpleButton .simpleIcon svg path {
    fill: #50605D !important;
}

.searchGptModelButtonSelected .simpleButton .simpleIcon svg path {
    fill: #e7e7e7 !important;
}

.searchGptModelButton .simpleButtonLabel {
    color: #50605D;
    font-size: 14px;
}


.searchGptModelButtonSelected .simpleButtonLabel {
    font-size: 14px;
    color: #e7e7e7;
}

.searchGptModels .model {
    margin: 0;
    width: auto;
}

.searchGptModels .modelVendorLabel {
    display: none;
}


.searchGpt .modelIcon svg {
    height: 32px;
    width: 32px;
}

.searchGpt .modelIcon  {
    height: 40px;
    width: 30px;
}


.searchGptSearchTopRow .searchFieldInput .inputControlInputEditorInput {
    left: 32px;
    width: calc(100% - 55px - 12px);
}

.searchGpt .aiLink {

}

.searchGpt .aiLink {
    display: inline-block;
    max-width: 100%;

}

.searchGptSearchResultError .aiLink {
    color: #ad4423;
}

.searchGptSearchProgressDisclosure {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    background: rgb(1, 42, 44);
    position: relative
}

.searchGptSearchProgressDisclosure .simpleButton .simpleIcon svg {
    height: 32px;
    width: 32px;
    fill: rgb(0, 255, 0);
}

.searchGptSearchProgressDisclosure .simpleButton .simpleIcon svg path {
    fill: rgb(0, 255, 0) !important;
}

.searchProgressDuration {
    width: 100%;
    display: flex;
    align-items: center;
    height: 40px;
    color: white;
    opacity: 0.6;
    font-size: 13px;
    flex-grow: 1;
    justify-content: flex-end;
    column-gap: 5px
}

.searchProgressDuration .modelPrice {
    position: static;
    font-size: 13px;
}



.miniHomePage svg {
    height: 32px;
    width: 32px;
}

.searchGptMenuContainer {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 20;
    right: 0px;
    top: 30px;
    display: flex;
    flex-direction: column;
    width: 40px;
    align-items: center;
}

.benxtAppMobile .searchGptMenuContainer {
    width: 100%;
    height: 100%;    
}

.miniHomePage {
    z-index: 5;
    width: 430px;
    max-width: 430px;
    height: calc(100% - 50px);
    max-height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-left: 10px;
    border-radius: 15px;
}

.benxtAppMobile .miniHomePage {
    transform: translate(0, -20px);
    width: 100vw;
    max-height: none;
    height: 100vh;
}

.miniHomePage .bnPageButtons {
    background: rgb(19, 35, 36);
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.miniHomePage .bnPageContentArea {
    background: rgb(19, 35, 36);
}

.miniHomePage .bnPageSubpage {
    background: #000a05;
    overflow: hidden;
    border-radius: 10px;
    padding-bottom: 10px;
}

.miniHomePage .bnPageSubpage .bnPageBody {
    background: rgb(19, 35, 36);
    border-radius: 10px;
}

.miniHomePage .bnPageSubpage {
    background: rgb(19, 35, 36);
}

.benxtAppDesktop .miniHomePage .keyboardHomeAccount {
    height: calc(100% - 25px);
    margin-top: -10px;
    padding-top: 10px;
    padding-bottom: 20px;

}

.benxtAppDesktop .miniHomePage .keyboardWordPackContent {
    margin-left: 10px;
    height: calc(100% - 20px);
    padding-top: 10px;
}

.miniHomePage .bnPaymentMethodInput {
    padding-left: 0;
    padding-right: 0;
}

.miniHomePage .homeButtonSignOut .homeButtonButton {
    background: rgb(3, 74, 122);
}

.miniHomePage .homeButtonSignOut .homeButtonButton .simpleIcon svg path  {
    fill: #e7e7e7 !important;
}

.miniHomePage .keyboardLogin .homeButtonButton {
    background: rgb(3, 74, 122);
}

.miniHomePage .keyboardLogin .homeButtonButton .simpleIcon svg path  {
    fill: #e7e7e7 !important;
}

.miniHomePage .keyboardHomeAccountDelete .homeButtonButton {
    background: #63130a;;
}

.miniHomePage .keyboardOof .homeButtonButton {
    background: #63130a;;
}

.miniHomePage .keyboardHomeAccountDelete .simpleIcon svg path  {
    fill: #e7e7e7 !important;
}

.miniHomeContainer {
    width: 450px;
    height: 100%;
    position: absolute;
    right: -10px;
    top: 30px;
}

.searchGptSettings .creditsPreview {
    width: calc(100% - 10px);
}


.searchGptSettings {
    width: 100%;
    position: relative;
    width: 450px;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    color: #e7e7e7;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: 0px;
    padding-left: 10px;
    box-sizing: border-box;
    opacity: 0;
    animation: opacity 0.4s cubic-bezier(0.25, 0.1, 0.25, 1.0);
    filter: drop-shadow(-4px 4px 4px rgb(0, 44, 44, 0.7));
}

.benxtAppMobile .searchGptSettings {
    width: 100%;
}

.benxtAppMobile .miniHomeContainer {
    position: absolute;
    width: 100%;
    left: 0;
    top: 50px;
}


.benxtAppMobile .miniHomePage {
    width: 100%;
    border-radius: 0;
    padding-left: 0
}

.benxtAppMobile .searchGptSettings {
    padding-left: 0;
}

.benxtAppDesktop .searchGptSettings .homeCard {

}

.searchGptSettingsOpen .searchGptModels {

}


.searchGptMenuButton {
    pointer-events: auto;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.searchGptMenuButton svg {
    height: 32px;
    width: 32px;
}

benxtAppDesktop .miniHomeContainer {
    height: calc(100% - 40px);
}

.miniHomePage .bnPageSubpage .attunewiseUsageCal {
    padding: 0;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100% - 20px);
}

.benxtAppDesktop .miniHomePage .bnPageSubpage .attunewiseUsageView {

    max-height: calc(100% - 0px);
}

.miniHomePage .bnPageSubpage .attunewiseUsage {
    overflow-y: auto;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100% - 20px);
    max-height: calc(100% - 370px);
}

.benxtAppDesktop .miniHomePage .homeCardTitleBar {
    padding-left: 10px;
    width: calc(100% - 20px);
}

.benxtAppDesktop .miniHomePage .homeCardContent {
    width: calc(100% - 0px);
}

.miniHomePage .recentUsage  {
    display: flex;
    flex-grow: 1;
}

.benxtAppDesktop .miniHomePage .keyboardHomeAccountInfo  {

}

.searchFieldFileChooser {
    padding-right: 1px;
    background: #000a05;
}

.benxtAppDesktop .miniHomePage .recentUsage .attunewiseUsagePreview {
    max-height: calc(5 * 40px + 4 * 3px);
    overflow-y:auto;
    width: 100%;
}

.benxtAppDesktop .miniHomePage .keyboardGetStartedContent {
    background: transparent;
    width: calc(100% - 20px);
}

.miniHomePage .bnPageContentAreaWithHeader {
    height: 100%;
}

.miniHomePage .bnPageContent {
    background: transparent;
}

.miniHomePage .vendorItemsInline {
    height: auto;
    width: 100%;
    max-height: none
}

.miniHomePage .chatGptThreadsVendorOptions {

}

.miniHomePage .chatGptModelsMenu {
    background: rgb(19, 35, 36);
}

.miniHomePage .chatGptThreadsVendorOptions {
    column-gap: 4px;
}


/** this is insane...*/

.miniHomePage .bnPageSubpage .modelsPreviewScroller {
}

.miniHomePage .recentUsage .bnPageContentSidebarContainer {
    width: 100% !important;
}
.miniHomePage .recentUsage .bnPageContentSidebar {
    width: 100% !important;
}

.miniHomePage .bnPageSubpage .modelsMenuInline {
    height: 100%;
    width: calc(100% - 20px);
    box-sizing: content-box;
    margin-left: 10px;
    height: calc(100% - 0px);
}

.miniHomePage .apiHighlights {
    display: none;
}

.scrollableSettings {
    width: 100%;
    display: flex;
    flex-direction: column;
    /*
    overflow-y: auto;
    height: calc(100% - 50px);
   */
    margin-top: -11px;
    padding-top: 2px;
    background: transparent;
    row-gap: 6px;
}




.embedFrame {
    display: flex;
    justify-content: center;
    background: rgb(1, 42, 44);
    margin-top: 4px;
}

.embedFrame iframe {
    margin-top: 50px;
    max-width: 400px;
    min-height: 600px;
    margin-bottom: 50px;
}

.webPageResult {
    max-height: 800px;
    overflow-y:auto;
}

.website-preview {
    color: #e7e7e7;
    background: rgb(1, 42, 44);
    margin-top: 4px;
    padding: 32px;
    padding-bottom: 0px;
    padding-top: 20px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    aligns-item: center;
}

.website-preview-site {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  height: 16px;
}

.website-preview-site p {
  font-size: 11px;
  vertical-align: middle;
  font-weight: bold;
}

.website-preview-site img {
  height: 16px;                      
  margin-right: 4px;
}

.website-preview-title {
  padding-top: 5px;
  padding-bottom: 5px;
}

.website-preview-title a {
  color: #6dad88;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
}

.website-preview-description {

}

.website-preview-description p {
   font-size: 14px;
   max-width: 100%;
   padding: 0;
}

.website-preview-image {
    height: 100%;
    padding: 0;
    margin: 0;
}

.website-preview-image img {
    max-height: 120px;
    object-fit: cover;
    opacity: 0.9;
}

.fakeInputFieldContainer {
    margin-top: 4px;
    background: rgb(1, 42, 44);
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 10px;
    padding-bottom: 5px;
}

.fakeInputField {
    background: #e7e7e7;
    opacity: 0.9;
    display: flex;
    align-items: center;
    padding: 7px;
}

.searchError {
    margin-top: 30px;
    color: #e7e7e7;
    display: flex;
    align-items: center;
    column-gap: 4px;
}

.searchError .simpleButton {
    background: rgb(3, 74, 122);
}

.searchErrorMessage {
    background: rgb(95, 18, 10);
    padding-left: 10px;
    padding-right: 10px;
    color: #e7e7e7;
    height: 100%;
    display: flex;
    align-items: center;
}
