
.bnInputFieldInput {
    font-size: 16px;
    background: transparent;
    border: 0;
    outline: none;
    padding: 0;
    padding-left: 15px;
    width: calc(100% - 15px);
    color: #333333;
}

.bnInputFieldInput input {
    color: white;
}


.bnInputFieldInputContainer {
    background: rgb(238, 238, 238);
    display: flex;
    width: 100%;
}

.bnInputFieldClear  {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bnInputFieldClear div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bnInputFieldClear svg  {
    height: 32px;
    width: 32px;
    fill: #6d6d6d;
}

.bnInputFieldClear svg path {
    fill: #6d6d6d !important;
}

.bnInputFieldClear svg rect {
    fill: #6d6d6d !important;
}

.bnInputField {
    height: 32px;
    width: 100%;
    display: flex;
    align-items: center;
}

.bnInputField input::placeholder {
    color: rgb(142, 142, 142) !important;
}

.bnInputFieldErr .bnInputFieldInput::placeholder {
    color: rgb(238, 238, 238) !important;
}

.bnInputFieldPhoneInput {
    outline: none;
    border: none;
    padding-left: 15px;
    font-size: 16px;
    width: calc(100% - 15px);
}

.bnInputField input:focus {
    outline: none;
}

.bnInputFieldSeparator {
    width: calc(100% - 15px);
    height: 1px;
    margin-left: 15px;
    background: gray;
}

.bnSearchField {
    display: flex;
    width: 100%;
    height: 50px;
    background: white;
    border-radius: 25px;
    align-items: center;
    border: 1px solid gray;
}

.bnSearchFieldInput {
    width: calc(100% - 80px);
    margin-left: -5px;
}

.bnSearchFieldIcon.bnBusy {
}

.bnSearchFieldIcon.bnBusy svg {
    transform: scale(2.25, 2.25);
}

.bnSearchFieldIcon.bnBusy #Layer_5 {
    transform-box: view-box;
    transform-origin: center center;
    animation: spinx 1s linear infinite;
}

.bnSearchFieldIcon.bnBusy svg g path {
    stroke: #aaaaaa;    
}

.bnSearchField .spinner-cls-2 {
}

.bnSearchFieldInput input {
    max-width: none;
    width: 100%;
    font-size: 16px;
    border: 0;
    color: white;
}

.bnSearchFieldInput input:focus {
    outline: none;
}

.bnSearchFieldInput input::placeholder {
    color: #c4c4c4;
}

.bnSearchFieldIcon {
    padding-left: 15px;
    padding-right: 15px;
    height: 50px;
}

.bnSearchFieldIcon div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bnSearchFieldIcon svg {
    height: 32px;
}

.bnSearchFieldIcon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bnBoolInput {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    width: 100%;
    height: 32px;
}

.bnNegInput .mbsc-ios.mbsc-switch input:checked+.mbsc-switch-track {
    background: rgb(138, 0, 0) !important;
    border-color: rgb(138, 0, 0) !important;
}

.bnBoolInputLabel {
    color: #6d6d6d;
    margin-left: 15px;
    font-size: 16px;
}

.bnBoolInput .mbsc-switch {
    height: 10px;
}

.bnCurrencyInput {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 8px;
    padding-left: 0;
    padding-right: 20px;
    width: calc(100% - 20px);
    background: white;
    height: 20px;
    font-size: 16px;
}

.bnCurrencyInputLabel {
    color: #6d6d6d;
    margin-left: 15px;
    white-space: nowrap;
}

.bnCurrencyInputInput {
    text-align: right;
    border: 0;
    outline: none;
    font-weight: bold;
    color: rgb(245, 80, 0);
    font-size: 16px;
    width: 80px;
}

.bnCurrencyInputInput:focus  {
    color: white;
    background: rgb(245, 80, 0);
}

.bnCurrencyInputInput::selection {
    color: white;
    background: rgb(245, 80, 0);
}

.bnTextArea textarea {
    border: none;
    resize: none;
    width: 100%;
    min-height: 80px;
    font-size: 16px;
    
}

.bnTextArea textarea:focus {
    outline: none;
}

.bnDateInput input {
    margin-right: 0;
    padding-right: 0;
    border: 0;
    color: rgb(245, 90, 0);
    font-size: 16px;
    font-weight: bold;
    text-align: right;
    cursor: pointer;
}

.bnDateInput input:focus {
    outline: none;
}

.uiSelectionList {
    display: flex;
    flex-direction: column;
    min-width: calc(100%);
    justify-content: flex-start;
    position: absolute;
    background: rgb(0, 26, 25);
    padding-top: 1px;
    row-gap: 1px;
}

.benxtAppMobile .uiSelectionList {
}

.uiSelectionListOption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 32px;
    height: 32px;
    font-size: 16px;
    cursor: pointer;
    white-space: nowrap;
    background: rgb(211, 211, 211);
    border: 0;
    color: #333333;
    font-weight: normal;
}

@media (hover: hover) {
.uiSelectionListOption:hover {
    background:  #e7e7e7;
    color: #666666;
}
}

.uiSelectionListOptionName {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    max-width: 100%;
    text-overflow: wrap;
    overflow: hidden;
}

.uiSelectionListOptionRight {
    display: flex;
    align-items: center;
}

.uiSelectionListOptionCheck {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
}

.uiSelectionListOptionCheck div  {
    display: flex;
    justify-content: center;
    align-items: center;
}

.uiSelectionListOptionCheck svg {
    height: 32px;
    width: 32px;
    fill: #333333 !important;
}

.uiSelectionListOptionCheck svg path {
    fill: #333333 !important;
}

.bnInputFieldPhoneInputField {
    display: flex;
    position: relative;
    width: 100%;
}

.bnInputFieldPhoneInputCountryCode {
    min-height: 32px;
    max-width: 100px;
    font-size: 16px;
    font-weight: normal;
    display: flex;
    align-items: center;
    cursor: pointer;
    background: rgb(211, 211, 211);
    padding-left: 10px;
    border-right: 1px solid  rgb(0, 26, 25);
}

.bnInputFieldPhoneInputCountryCodeInput {
    color: #333333;
    min-width: 100px;
    z-index: 5;
}

.bnInputFieldFieldPhone {
    overflow: visible;
}

.bnInputFieldFieldPhone .uiSelectionList {
}

.bnInputFieldPhoneInput input {
    font-size: 16px;
    background: transparent;
    border: 0;
    outline: none;
    padding: 0;
    padding-left: 15px;
    width: calc(100% - 0px);
    color: #333333;
    outline: none;
    border: none;
    padding-left: 0px;
    font-size: 16px;
    width: calc(100% - 15px);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
