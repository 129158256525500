.canvas-editor-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.monaco-editor-instance {
  width: 100%;
  height: 100%;
}

