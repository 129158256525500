.editMenu {
    display: flex;
    flex-direction: column;
    background: rgb(12, 28, 28);
    min-width: 120px;
    box-sizing: border-box;
    filter: drop-shadow(-4px 4px 4px rgb(0, 25, 26, 0.8));
    row-gap: 0px;
    margin-top: 5px;
}
.benxtAppDesktop .editMenuPopup .simpleIcon:hover {
    /* background: rgb(1, 79, 75); */
}

.actionMenuLeft {
    filter: drop-shadow(4px 4px 4px rgb(0, 25, 26, 0.8));
}

.editMenu .deleteButtonHolder {
    width: 100%;
}

.editMenu .deleteButtonHolder .threadDeleteButton {
    position: static;
}

.editMenu .deleteButtonHolder .threadDeleteButtonConfirm {

}

.editMenu .simpleButton {
    background: rgb(12, 28, 28);
}

.benxtAppDesktop  .editMenu .simpleButton:hover {
   /*  background: rgb(1, 79, 75); */
}

#editMenuTrigger {
    cursor: pointer;
}

.popup-content .editMenu {
    transform: translate(0, -15px);
}

.popup-arrow {
    visibility: hidden;
}

/*
.popup-content > div:nth-child(1) {
    display: none;
}

.popup-content > div:nth-child(2) {
    transform: translate(0, -15px);
}

*/
