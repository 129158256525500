
.keyboardWordPackContent {
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 0px;
}

.keyboardWordPackHeader {
    color: #e7e7e7;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
}

.keyboardWordPackHeaderAvailable {
}

.keyboardWordPackHeaderAvailableAmount {
    font-size: 25px;
    font-weight: bold;
    color: #e7e7e7;
    display: flex;
    justify-content: flex-end;
}

.keyboardWordPackHeaderAvailableLabel {
    text-transform: uppercase;
    color: #e5e0bf;
    font-weight: bold;
    font-size: 11px;
    margin-bottom: 6px;
}

.keyboardWordPackHeaderLabel {
    text-transform: uppercase;
    font-size: 12px;
    color: rgb(115, 153, 157);
    margin-bottom: 6px;
}

.keyboardWordPurchase {
    color: #e7e7e7;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 6px;
}

.keyboardWordPurchaseBlurb {
    width: 100%;
    font-weight: bold;
    text-align: center;
}

.keyboardWordPackIcon {
    height: 32px;
    width: 32px;
}

.keyboardWordPack {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.keyboardWordPackRow1 {
    display: flex;
    align-items: center;
    background: rgb(8, 59, 50);
    box-shadow: inset 3px 4px 5px rgb(0, 0, 0, 0.1);
    width: 100%;
    cursor: pointer;
    height: 32px;
    justify-content: space-between;
}

.keyboardWordPackPaymentStatusCanceled .keyboardWordPackRow1 {
    background:  rgb(184, 38, 26);
}

.keyboardWordPackPaymentStatusProcessing .keyboardWordPackRow1 {
    background:  rgb(202, 76, 27);
}

.keyboardWordPackPaymentStatusComplete .keyboardWordPackRow1 {
    background:  rgb(99, 97, 142);
}

.keyboardWordPackPaymentStatusCanceled .keyboardWordPackBuyButton {
    display: none;
}

.wordPackButtonSelected .keyboardWordPackRow1 {
    background: rgb(10, 69, 97);
}

.keyboardWordPackPaymentStatus {
    text-transform: uppercase;
    font-weight: bold;
}

.keyboardWordPackBuyButton {
    background: rgb(19, 35, 36);
    box-shadow: inset 3px 4px 5px rgb(0, 0, 0, 0.1);
    align-items: center;
    display: flex;
    height: 32px;
    width: 100px;
    cursor: pointer;
}

.keyboardWordPackPayment {
    cursor: auto;
}

.keyboardWordPackBuyButtonIcon {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.keyboardWordPackBuyButtonIcon div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.keyboardWordPackBuyButtonIcon svg {
    fill: #e7e7e7;
    height: 32px;
    width: 32px;
}

.keyboardWordPackRow2 {
    width: 100%;
    display: flex;
    row-gap: 1px;
    flex-direction: column;
    align-items: flex-end;
}

.keyboardWordPackSummaryLine {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.keyboardWordPackTotalLine {
    font-weight: bold;
    color: #e54d25;
}

.keyboardWordPackOrderSummary {
    row-gap: 6px;
    box-shadow: inset 3px 4px 5px rgb(0, 0, 0, 0.1);
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #e7e7e7;
    color: #888888;
    font-weight: normal;
    width: 100%;
    width: calc(100% - 20px);
    padding: 10px;
}

.wordCount {
    font-size: 16px;
    font-weight: bold;
}


.keyboardWordPackLeft {
    display: flex;
    align-items: center;
}

.keyboardWordPackRight {
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.keyboardWordPackWordCount {
    font-size: 16px;
    color: #e7e7e7;
}

.keyboardWordPackWordPrice {
    font-size: 16px;
    color: #e7e7e7;
    font-weight: bold;
}

.keyboardWordPackStatus {
    font-size: 13px;
    margin-top: 5px;
    padding: 5px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center
}

.keyboardWordPackStatusFail {
    background: #CA0000;
    color: #e7e7e7;
}

.wordPackSpinContainer {
    margin-top: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

.wordPackSpinContainer div {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wordPackSpinContainer svg {
    height: 182px;
    width: 182px;
}

.bnPaymentMethodInputContainer {
    width: 100%;
}

.bnPaymentMethodInput {
    font-size: 16px;
    color: #e7e7e7;
    width: 100%;

    padding: 10px;
    margin-top: 6px;
    box-sizing: border-box;
    width: 100%;
}

.bnPaymentMethodInput html {
    color: #e7e7e7 !important;
}

.bnCustomerPaymentCard {
    background: white;
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    width: calc(100% - 30px);
}

.bnCustomerPaymentCard .bnInputFieldSeparator {
    margin-left: 0;
    width: 100%;
}

.bnCardElement {
    box-shadow: inset 3px 4px 5px rgb(0, 0, 0, 0.1);
    width: calc(100% - 30px);

    margin-top: 6px;
    padding: 15px;
}

.wordpackPaymentMethodError {
    width: 100%;
}
.wordpackPaymentMethodError .homeButton {
    width: 100%;
    margin: 0;
    margin-top: 6px;
}

.wordpackPaymentMethodError .homeButtonButton {
    background: #63130a;
}

.keyboardWordPackRefer {
    margin-top: 32px;
}

.benxtAppDesktop .keyboardWordPackRefer {
    margin-top: 60px;
}

.FREE {
    font-weight: bold;
}

.bnPaymentMethodInputContainer {
    position: relative;
}

.keyboardWordPackRow2 .inputControlKeyboardButton .uiOKCancelOK {
    background: #3c65c9;
}

.keyboardWordPackRow2 .simpleButton {
    background: #3c65c9;
    width: 120px;
    margin-top: 10px;
    margin-bottom: 15px;
}


.wordPackCreditsPreview {
    color: #e7e7e7;
    margin-bottom: 10px
}

.desktopPurchase {
    width: 100%;
    height: 100%;
}

