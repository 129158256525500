.tempSlider {
    width: 100%;
    background: rgb(4, 45, 50);
}

.slider1Container {
    position: relative;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    padding: 5px;
    height: 32px;
    align-items: center;
    width: calc(100%);
}

.slider1ContainerLabel {
    width: 100%;
    display: flex;
    align-items: center;
    color: #e7e7e7;
    height: 32px;
    max-width: 80px;
    font-size: 14px;
    justify-content: center;
}

.slider1 {
    min-width: 55px;
    height: 32px;
    position: relative;
    cursor: pointer;
    position: relative;
    flex-grow: 1;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.sliderButtonContainer {
    position: absolute;
    top: 0px;
    left: 0px;
    width: calc(100% - 25px);
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

}

.sliderButtonContainerHigh .sliderValue {

}

.sliderButton {
    background: #3b65c9;
    height: calc(100% - 8px);
    width: 32px;
    height: 32px;
    border-radius: 1px;
    position: absolute;
    font-size: 14px;
    filter: drop-shadow(-2px 2px 2px rgb(0, 25, 26, 0.8));
}

.sliderValue {
    left: 0px;
    right: auto;
    position: absolute;
    height: 32px;
    width: 32px;
    color: #e7e7e7;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}


.sliderTrack {
    width: 100%;
    height: 10px;
    background: #3b65c9;
}


.configurableModel .sliderTrack {
    width: calc(100% - 15px);
    right: 20px;
}
