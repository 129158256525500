.fade-span {
  opacity: 0;
  display: none;
}

/* Custom list item styling */
.custom-li-container {
  position: relative;
  padding-left: 2em;
  margin: 0.5em 0;
}

.ol-list > .custom-li-container > .custom-li-marker {
    height: 0;
    width: 0;
    margin-left: 10px;
}

.custom-li-marker {
  position: absolute;
  left: 0.5em;
  top: 0.5em;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: currentColor;
}

.custom-list.ol-list .custom-li-marker::before {
  content: counter(list-counter) '.';
  position: absolute;
  left: -1em;
  top: -0.5em;
  counter-increment: list-counter;
}

.custom-list {
  list-style: none;
  padding-left: 0;
  counter-reset: list-counter;
}

/* Table styles */
.table-container {
  width: 100%;
  overflow-x: auto;
  margin: 1em 0;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

th, td {
  padding: 8px 12px;
  border: 1px solid #ddd;
  text-align: left;
}

th {
  background-color: #f5f5f5;
  font-weight: bold;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* Fake HR styling */
.fake-hr {
  border: none;
  height: 1px;
  background: linear-gradient(to right, transparent, currentColor, transparent);
  margin: 2em 0;
}
.task-list-item {
    position: relative;
}

.aiPre {
    white-space: pre-wrap;
    margin-left: 10px;
}
