.scrollList {
    width: 100%;
    background: rgb(19, 36, 36);
}

.scrollList .titleRow {
    color: #e7e7e7;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100%  - 20px);
    height: 32px;
    padding-top: 4px;
    padding-bottom: 4px;
    transform: translate(0, 5px);
}

.scrollListTitle {
}

.scrollList .contentRow {
    width: 100%;
    display: flex;
}

.scrollList .contentRow .swiper {
    width: calc(100% - 64px);
}

.scrollList .simpleCheckbox  {
    flex-grow: 0;
}

.scrollList .simpleButton {
    background: rgb(10, 69, 97);
}

.scrollList .swiper-slide {
    flex-shrink: 1;
    margin-left: 3px;
    margin-rigt: 2px;
}
