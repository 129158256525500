.usageCalendar {
    width: calc(100% - 20px);
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0px;
    margin-top: 2px;
}

.usageCalendarViewSelector {
    width: 100%;
    display: flex;
    justify-content: space-between;
    min-height: 32px;
    align-items: center;
}

.usageCalendarViewChoice {
    font-weight: 500;
    font-size: 14px;
    width: 100%;
    cursor: pointer;
    height: 32px;
}

.usageCalendarViewChoice {
    display: flex;
    align-items: center;
    justify-content: center;
}

.usageCalendarViewChoiceSelected {
    background: rgb(1, 60, 84)
}

.mbsc-empty {
    display: none;
}

.mbsc-cal-hdr {
    padding: 0 !important;
    margin: 0;
    height: 32px;
    display: flex;
    align-items: center;
    margin-top: 4px;
    margin-bottom: 4px;
}

.mbsc-cal-table {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
}

.mbsc-cal-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 32px;
    overflow: hidden;
}

.mbsc-cal-cell {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: none !important;
}

.mbsc-cal-days {
    height: 32px;
    display: flex;
    align-items: center;
    max-height: none;
    margin-bottom: 4px;
}

.mbsc-cal-day {
    height: 100%;
    max-height: none !important;
    max-height: 32px;
}

.mbsc-cal-day-i {
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 1px;
    margin-top: 1px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    min-height: 32px !important;
}

.mbsc-windows.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-cell.mbsc-cal-today {
    color: #e7e7e7 !important;
    background:  #20A1C4 !important;
}

.mbsc-cal-cell-txt{
    display: flex;
    justify-content: center;
    align-items: center;
}

.mbsc-cal-today :not(.mbsc-selected):hover .mbsc-cal-cell-txt{
    color: #e7e7e7 !important;

}

.mbsc-windows .mbsc-cal-today:not(.mbsc-selected):hover .mbsc-cal-day-i {
    color: #e7e7e7 !important;
    background: #11CEED !important;
}

.mbsc-windows .mbsc-cal-today:not(.mbsc-selected) .mbsc-cal-day-i .mbsc-cal-cell-txt {
    color: #e7e7e7 !important;
}

.mbsc-windows .mbsc-cal-today:not(.mbsc-selected):hover .mbsc-cal-day-i .mbsc-cal-cell-txt {
    color: #e7e7e7 !important;
}


.mbsc-windows .mbsc-cal-today:not(.mbsc-selected) .mbsc-cal-day-date {
    color: #e7e7e7 !important;
    height: 100%;
}

.mbsc-windows .mbsc-cal .mbsc-cal-day.mbsc-selected:not(.mbsc-disabled) .mbsc-cal-cell-i.mbsc-cal-day-i {
    /*
    color: #666666;
    background-color: #B6EEFF;
   */
    height: 100%;
    color: #e7e7e7 !important;
    background: rgb(37, 183, 68); 
}

.mbsc-windows .mbsc-cal .mbsc-cal-day .mbsc-selected:not(.mbsc-disabled):hover .mbsc-cal-cell-i:hover .mbsc-cal-day-i {
}


.mbsc-windows .mbsc-cal .mbsc-cal-day.mbsc-selected:not(.mbsc-disabled) .mbsc-cal-cell-txt {
    /*
    color: #666666 !important;
    background-color: #B6EEFF;
    color: #e7e7e7;
    background: rgb(37, 183, 68); 
   */
    color: #e7e7e7;
}


/*
.mbsc-cal-day:hover {
    color: #666666 !important;
    background-color: #B6EEFF !important;
    height: 100%;;
}

.mbsc-windows .mbsc-cal-day-diff .mbsc-cal-day-i:hover {
    color: #666666 !important;
    background-color: #B6EEFF !important;
}

*/

.mbsc-cal-day-marked:not(.mbsc-cal-today) .mbsc-cal-day-i {
    color: #e7e7e7 !important;
    background: rgb(1, 79, 75);
    height: 100%;
}

.mbsc-cal-day-marked.mbsc-today .mbsc-cal-day-i {
    color: #e7e7e7 !important;
    background:  #20A1C4;
    height: 100%;
}

.mbsc-cal-day-marked .mbsc-cal-cell-txt {
    color: #e7e7e7 !important;
}

.mbsc-cal-day-marked .mbsc-cal-day-i:hover {
    background: rgb(1, 79, 75);
}

.mbsc-cal-day-marked.mbsc-selected  .mbsc-cal-day-date {
    color: #e7e7e7 !important;
    background: rgb(37, 183, 68) !important;
    height: 100%;
}

.mbsc-cal-day.mbsc-selected:not(.mbsc-disabled):hover .mbsc-cal-cell-i.mbsc-cal-day-i {
    /*background: #B6EEFF; */
    color: #e7e7e7 !important;
    background: #11D316 !important;
}

.mbsc-cal-day.mbsc-selected:not(.mbsc-disabled):hover .mbsc-cal-cell-txt {
    /*background: #B6EEFF; */
    color: #e7e7e7 !important;
    background: #11D316 !important;
}

.mbsc-cal-has-marks .mbsc-cal-day {
    padding-bottom: 0 !important;
}

.mbsc-windows.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-marks {
    height: 100%;
    display: none;
    transform: translate(0, calc(-100% + 14px));
    overflow: visible;
    width: 100%;
    background: rgb(37, 183, 68) !important;
    position: absolute;
    z-index: -1;
}

.mbsc-cal-day-markup {
}

.mbsc-cal-marks {
}

.mbsc-cal-mark {
    display: none;
}

.mbsc-event-list {
    display: none;
}

.mbsc-fr-w {
    font-family:  apparat, sans-serif;
    font-weight: 550;
    font-size: 16px;
}

.mbsc-ic-arrow-right6 {
    width: 32px;
    height: 32px;
    transform: scale(2, 2);
    transform-origin: center top;
}

.mbsc-ic-arrow-left6 {
    width: 32px;
    height: 32px;
    transform: scale(2, 2);
    transform-origin: center top;
}

.mbsc-ic-arrow-left6 svg {
    width: 32px;
    height: 32px;
}

.mbsc-ic-arrow-right6::before {
  content: "";
  display: inline-block;
  width: 1em; /* adjust width as needed */
  height: 1em; /* adjust height as needed */
  background: url('../../assets/Icons/Forward.svg') no-repeat center center;
  background-size: contain;
}


.mbsc-ic-arrow-left6::before {
  content: "";
  display: inline-block;
  width: 1em; /* adjust width as needed */
  height: 1em; /* adjust height as needed */
  background: url('../../assets/Icons/Back.svg') no-repeat center center;
  background-size: contain;
}

.mbsc-cal-month {
    font-size: 16px;
}

.mbsc-cal-btn-w {
    height: 21px;
    display: flex;
    align-items: center;
}

.mbsc-cal-picker {
    background: rgb(8, 59, 50);
}
