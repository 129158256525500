.canvas-editor-tabs {
  width: 100%;
  border-bottom: 1px solid #1e3a3a; /* subtle edge line */
  background-color: #0c1c1c; /* primary background */
  height: 32px;
}

.tab-list {
  display: flex;
  overflow-x: auto;
  scrollbar-width: thin;
}

.tab {
  display: flex;
  align-items: center;
  background-color: #102a2a; /* slightly lighter than primary */
  border-right: 1px solid #1e3a3a;
  cursor: pointer;
  white-space: nowrap;
  min-width: 100px;
  max-width: 200px;
  position: relative;
  height: 32px;
  color: #e7e7e7;
}

.tab.active {
  background-color: #0d2033; /* secondary bg for active tab */
  font-weight: 500;
}

.tab-icon {
  margin-right: 8px;
  width: 16px;
  height: 16px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  filter: brightness(0.8); /* subtle tinting for light icons */
}

.tab-close {
  display: none !important;
}
