.chatGPT {
    font-size: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
}

.benxtAppMobile .chatGPT {
    background: rgb(4, 17, 17);
    height: calc(100% - 0px - env(safe-area-inset-bottom) - env(safe-area-inset-top));
}

.popupFixed {
}


.benxtAppNative .chatGPT {

}

.chatGPT .template {
    width: calc(100% - 0px);
}

.chatGPT .template .keyboardCode {
    width: calc(100% + 32px);
    transform: translate(0px, 0);
}

.chatGPT .keyboardEditDocumentText {
    width: calc(100% - 80px);
}

.chatGPT .keyboardEditCode {
    transform: none;
}

.keyboardHeaderButtonCancel {
    visibility: hidden;
}


.chatGPTKeyboard {
    z-index: 100;
    position: absolute;
    width: 100%;
    top: 32px;
    height: 100px;
}

.chatGPTKeyboard .keyboardHeader {
    display: none;
}

.chatGPTKeyboard .keyboardWindow {
    overflow-y: visible;
    min-height: 160px;
}

.chatGPTKeyboard .keyboardInputContainer {
    display: none;
}

.chatGPTKeyboard .keyboardInstructionListContainer {
    background: rgb(0, 26, 25);
}

.benxtAppDesktop .chatGPT {
    font-size: 14px;
}

.chatGPT .inputControlTopRow {
    display: none;
}

.chatGPTInput {
    bottom: 5px;
    width: calc(100%);
    position: absolute;
    box-sizing: border-box;
    z-index: 100;
}

.chatGPTInput .inputControlInputEditor {

}

.chatGPT .uiChatMarginBottom {
    display: flex;
    width: 100%;
    min-width: 100%;
    height: calc(100% - 5px);
    margin-bottom: 5px;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
/*    border: 1px solid yellow; */
}

.chatGPTNoInput .uiChatMarginBottom {

}

.chatGPT .uiChatMarginBottomSearchField {
    height: calc(100% - (132px - 42px)); 
}

.chatGPTNoInput .uiChatMarginBottomSearchField {
   height: calc(100%); 
}
.chatGPT .chatMessagesSliderEnabled .uiChatMarginBottom {
    width: 50%;
    min-width: 50%;
}

.chatGptChatMessageHeaderTopic {
    color: #888888;
}

.chatGptChatMessageHeaderTopic2 {
    font-weight: 550;
    height: 32px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.chatGPT .uiChatMarginBottomThread {
    justify-content: space-between;
}

.chatGPT .keyboardEditDocument {
    margin-top: 0;
}

.chatGPT .keyboardHeader {
    margin-bottom: 0px;
}

.chatGPT .uiChatMessages {
    padding-top: 0px;
    overflow-y: auto;
    width: 100%;
    max-width: 100%;
}

.chatGPTNoInput .uiChatMessages {
}

.chatGPT .uiChatMessagesContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 3px;
}

.chatGPT .keyboardEditInstruction {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
}

.chatGPT .aiUser .keyboardEditInstructionText {
    overflow: visible;

}


.chatGPTMessageReactions {
    width: 100%;
    transform: translate(32px, 0);
    letter-spacing: 4px;
    padding-bottom: 2px;
}

.chatGptSearchFieldContainer {
    background: rgb(0, 26, 25);
    top: 0px;
    left: 0;
    z-index: 10;
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.chatGptSearchFieldContainer .inputControlMiddleRowContainer {
    position: relative;
    flex-grow: 0;
    display: flex;
    width: auto;
}

.chatGPTInput .inputControlMiddleRowContainer {
    position: relative;
    flex-grow: 0;
    display: flex;
    width: auto;
}

.chatGptSearchFieldContainer .keyboardAddButton {
    position: relative;
}

.chatGptQuestions .keyboardAddButton {
    position: relative;
}

.chatGptSearchFieldContainer .keyboardInstructionInput {
    min-height: auto;
    height: 32px;
}

.chatGPT .keyboardMenuAutocompletes {
    position: absolute;
    top: 81px;
    right: 0;
    left: auto;
    bottom: auto;
}

.chatGPT .chatGPTQuestionsAutocomplete {
    width: calc(100vw - 10px);
    justify-content: flex-end;
}

.benxtAppDesktop .chatGPTQuestionsAutocomplete {
    width: calc(600px - 10px);
}

.chatGPT .keyboardMenuAutocompletes {
    row-gap: 1px;
}

.chatGPT .keyboardMenuAutocompletes.chatGPTQuestionsAutocomplete .keyboardMenuItemAutocomplete {
    background: rgb(0, 26, 25);
    overflow-y: auto;
    max-height: calc(100% - 80px - env(safe-area-inset-top) - 10px);
}

.chatGPT .keyboardInstructionInput .inputControlBottomRow {
    display: none;
}

.chatGPT .keyboardMenuAutocompletes .keyboardMenuItem {
    background: rgb(12, 28, 28);
}

.chatGptThreads {
    width: 100%;
    position: relative;
    z-index: 10;
    top: 0;
    max-width: 32px;
}

.chatGptQuestions {
    width: 100%;
    position: relative;
    z-index: 10;
    top: 0;
}
.chatGptThreadsMenuContainer {
    width: 100%;
    height: 100%;
}

.chatGptThreadsMenu {

    padding-top: 2px;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    overflow-y: auto;
    height: calc(100% - 225px - env(safe-area-inset-top));
    width: calc(100%);
}

.chatGptThreadsMenuInfiniteScroll {
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 225px - env(safe-area-inset-top));
    width: calc(100%);
}

.chatGptThreadsMenuInfiniteScroll .itemScroller {
    background: rgb(4, 17, 17);
    row-gap: 4px;
}

.chatGptThreadsMenu-recent {
    height: calc(100%);
}

.chatGptThreadsMenu-all {
    height: calc(100% - 150px - env(safe-area-inset-bottom));
}

.datasetChat .chatGptThreadsMenu-recent {
    height: calc(100% - 190x - env(safe-area-inset-bottom));
}

.datasetChat .chatGptThreadsMenu-all {
    height: calc(100% - 210px - env(safe-area-inset-bottom));
}

.chatGptThreadsMenu-day {
    height: calc(100% - 145px - env(safe-area-inset-bottom));
}

.chatGptThreadsMenu-week {
    height: calc(100% - 210px - env(safe-area-inset-bottom));
}

.modelsMenuForce {
    width: 100%;
}

.chatGPTModelsMenuTitle {
    color: #e7e7e7;
    padding: 10px;
    padding-left: 0px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    font-weight: 500;
    background: rgb(181, 67, 26);
    align-items: center;
    max-height: 32px;
    position: relative;
}

.chatGPTModelsMenuTitleNormal {
    position: relative;
    color: #e7e7e7;
    padding: 10px;
    padding-left: 0px;
    padding-right: 0px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    font-weight: 500;
    background: rgb(12, 28, 28);
    align-items: center;
    max-height: 32px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 1px;
}


.chatGPTModelsMenuTitle .simpleIcon {
    min-width: 32px;
}

.chatGPTModelsMenuTitleNormal .simpleIcon {
    min-width: 32px;
}

.simpleIcon .SVG-Nano-001 {
    height: 20px !important;
    width: 20px !important;
}


.chatGptModelsMenu {
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    width: 100vw;
    row-gap: 1px;
    margin-top: -4px;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: calc(100% - 10px - env(safe-area-inset-top) - env(safe-area-inset-bottomm));
    background: rgb(4, 17, 17);
    filter: drop-shadow(-4px 4px 4px rgb(0, 25, 26, 0.8));
}

.benxtAppMobile .chatGptModelsMenu {
}

.benxtAppNative .chatGptModelsMenu {
    max-height: calc(100% - 60px - env(safe-area-inset-top) - env(safe-area-inset-bottomm));
}

.chatGptModelsMenuDesktop {
    margin-top: -4px;
    width: 766px;
    margin-left: 0;
}

.chatGptModelsMenuDesktop.fineTunedModelsView {
    width: 430px;
}


.chatGptThreadsMenuOptions {
    display: flex;
    column-gap: 1px;
    background: rgb(12, 28, 28);
    flex-grow: 0;
    width: 100%;
    flex-wrap: wrap;
}


.chatGptThreadsVendorOptions {
    flex-grow: 0;
    display: flex;
    max-width: 100%;
    background: rgb(4, 17, 17);
    flex-wrap: wrap;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    align-items: center;
    column-gap: 4px;
    row-gap: 4px;
}

.chatGptThreadsVendorOptions .simpleButton {
    background: rgb(12, 28, 28);
}

.chatGptThreadsVendorOptions .modelsMenuVendor {
    background: rgb(12, 28, 28);
}


.chatGptModelsMenuDesktop .chatGptThreadsVendorOptions .modelsMenuVendor {
    min-width: calc((100%  - 15 * 4px) / 16);
}

.chatGptModelsMenuDesktop .chatGptThreadsVendorOptions .simpleButton {
    min-width: 100%;
    justify-content: center;
}


.chatGptThreadsMenuOptionsContainer {
    background: rgb(12, 28, 28);
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 0px;
}

.chatGptQuestionsMenu {
    background: rgb(0, 26, 25);
    position: absolute;
    bottom: calc(80px + env(safe-area-inset-bottom) + 6px);
    padding-bottom: 2px;
    display: flex;
    flex-direction: column;
    row-gap: 2px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100% - 80px - env(safe-area-inset-bottom) - 10px);
    width: calc(100% - 10px);
    left: 5px;
    z-index: 20;
    min-height: 45px;
}


.chatGptChatMessageHeader {
    color: #c7c7c7;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 10px);
    padding-left: 5px;
    padding-right: 5px;
    font-size: 15px;
    padding-bottom: 5px;
}

.benxtAppDesktop .chatGptChatMessageHeader {
    font-size: 14px;
}

.chatGptChatMessageHeader.chatMessageFromUser {
    background: rgb(0, 26, 25);
}

.chatGptChatMessageHeader.chatMessageFromGpt {

}

.chatGptChatMessageTimestamp {
    font-size: 15px;
    white-space: nowrap;
    padding-left: 10px;
    color: #888888;
}

.chatGPT .keyboardAddButton {
    right: auto;
    left: 0;
}

.benxtAppSafari .chatGPT .keyboardAddButton {
    box-sizing: border-box;
}


.benxtAppDesktop .chatGptChatMessageTimestamp {
    font-size: 14px;
}


.chatMessagesSlider {
    display: flex;
    height: calc(100% - 32px * 2 - 70px);
    width: 100%;
    position: relative;
    flex-direction: column;
}

.chatMessagesSliderEnabled {
    overflow-x: auto;
    min-width: 200%;
}

.chatMessagesSliderContainer {
    display: flex;
    width: 100%;
    height: calc(100% - 105px);
    flex-direction: column;
    justify-content: flex-end;
}

.chatGPTNoInput .chatMessagesSliderContainer {
    height: calc(100% - 95px);
    margin-bottom: 10px;
}

.chatMessagesBack {
    position: absolute;
    top: 0px;
}

.benxtAppDesktop .chatMessagesBack.keyboardMenuItem {
    font-size: 14px;
}

.chatMessagesBackBusy .keyboardMenuItemIconRight {
    position: absolute;
    right: 0;
}

.chatGptThreadMenuThreads {
    display: flex;
    flex-direction: column;
    margin-top: 2px;
    row-gap: 4px;
    width: 100%;
    height: 100%;
}
.chatGptThreadMenuThreads:focus {
    outline: none;
}

.chatGptThreadMenuThreads .keyboardMenuItem {
    display: flex;
}

.chatGptQuestionMenuQuestions {
    display: flex;
    flex-direction: column;
    row-gap: 1px;
    width: 100%;
    max-width: 100%;
    z-index: 1;
}

.chatGptQuestionMenuQuestions .keyboardMenuItem {
    font-size: 15px;
}

.benxtAppDesktop .chatGptQuestionMenuQuestions .keyboardMenuItem {
    font-size: 15px;
}
.chatGptThreadMenuThreads .keyboardMenuItem {
    font-size: 15px;
}

.benxtAppDesktop .chatGptThreadMenuThreads .keyboardMenuItem {
    font-size: 14px;
}

.copyAISaid {
    display: flex;
}

.retryAISaid .uiOKCancelOK {
    background: rgb(181, 67, 26) !important;
}

.bottomRowButtons .uiOKCancelOK {
    background: #444444 !important;
}

.retryAISaid {
}

.aiCheckButton svg path {
    fill: #e7e7e7 !important;
}

.chatGptChatMessage {
    width: 100%;
}

.chatGptChatMessage .swiper {
    width: 100%;
}

.judgementSwiper {
    background: #0f2733;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.judgementSwiper .swiper {
    width: 100%;
}


.chatMessagesSliderContainer .swiper {
    width: 100%;
    flex-grow: 1;
}

.chatGptChatMessageBody {
    width: 100%;
}

.copyAISaid .uiOKCancelCancel {
    background: transparent;
}

.keyboardEditDocumentError .copyAISaid {

}

.keyboardEditDocumentError .uiOKCancelOK {
    background: transparent;
}

.keyboardEditDocumentError .aiCommentError {
    margin-top: 6px !important;
    background: #63130a;
}

.chatGptChatMessageError .chatGptChatMessageHeader  {
    background: #63130a;
    display: none;
}

.chatGptChatMessageError .aiCheck {
    display: none;
}

.chatGptChatMessageError .keyboardEditDocument {
    background: #63130a;
}

.userSaidDel .uiOKCancelCancel {
    background: transparent;
}

.userSaidPause {
    display: flex;
    position: absolute;
    bottom: 0;
}
.userSaidPause .uiOKCancelCancel {
    background: rgb(181, 67, 26);
}

.keyboardAddButton .uiOKCancelCancel {
    background: rgb(12, 28, 28);
}


.aiComment {
    margin-top: 0px !important;
    background: #0f2733;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 32px;
    padding-right: 32px;
    width: calc(100% - 80px)
}

.aiWorking {
    margin-top: 0px !important;
    background: #0f2733;
}


.aiTrace {
    margin-bottom: 0px !important;
    background: rgb(2, 77, 87);
    background: rgb(13, 32, 51);
}

.aiTrace.aiHallucinated {
    margin-top: 0px !important;
    opacity: 0.7;
    background: rgb(49,0,0) !important;
}

.aiReply {
    margin-top: 6px !important;
}

.aiComment .uiOKCancelCancel {
    opacity: 0.5;
    background: transparent;
}

.aiCheck {
    display: inline-block;
    height: 20px;
    width: 20px;
    vertical-align: middle;
    padding-left: 4px;
}

.aiCheck svg {
    display: inline-block;
    height: 20px;
    width: 20px;
}

.aiCheck div {
    display: inline-block;
}

.keyboardEditDocumentTextInline {
    width: 100%;
    overflow: hidden;
    display: inline-block;
    white-space: pre-wrap;
}


.aiLinkWithTooltip {
    display: inline-block;
}

.aiLink {
    color: #6dad88;
    display: inline;
    cursor: pointer;
    white-space: normal;
    display: flex;
    vertical-align: top;
}


.keyboardEditDocumentTextInline  a {
    color: #6dad88;
    display: inline;
    cursor: pointer;
    white-space: normal;
}

.keyboardCode {
    padding-top: 10px;
}

.keyboardCode code {
    display: flex;
    color: #e5e5e5;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 13px;
}


.keyboardEditDocumentTextInline  .language-text {
    color: #1144cc !important;
}

.chatGptTooltip {
    position: absolute;
    left: 10px;
    bottom: 12px;
    color: #e7e7e7;
    font-size: 11px;
    opacity: .8;
    max-width: calc(100% - 10px - 120px)
}

.inputControlKeyboardButton.newTopicButton .uiOKCancelOK {
    background: rgb(12, 28, 28);
    width: 100px;
}

.aiCode {
    display: inline-block;
    font-family: apparat;
    font-size: 13px;
    font-weight: 500;
    white-space: pre-wrap;
    position: relative;
    overflow-wrap: break-word;
    background: rgb(255, 255, 255, 0.1);
    padding: 10px;
    margin-bottom: 5px;
    color: #f7f7f7;
    word-break: break-all;
    max-width: calc(100% - 32px);
}

.aiCodeInline {
    max-width: none;
    display: inline;
    padding: 0px;
    margin: 0;
    background: none;
    color: #f7f7f7;
}

.aiPre {

}

.aiPre .aiCode {
}

.chatGPT .inputControlSpectrumAnalyzer {
}

.chatGPT .inputControlSpectrumAnalyzer .micSpectrumAnalyzerContainer {
}

.messageSelection {
    max-width: 100%;
    width: 100%;
}

.modelSelection {
    max-width: 100%;
    width: 100%;
    overflow-x: auto;
    display: flex;
    column-gap: 4px;
    min-height: 32px;
    background: rgb(4, 17, 17);
}


.modelSelectionModel {
    background: rgb(12, 28, 28);
    display: flex;
    flex-grow: 0;
    width: fit-content;
    height: 32px;
}

.modelSelectionModelSelected {
    background: rgb(59, 101, 201);
}

.modelSelectionModelActiveSelected {
    background: rgb(2, 77, 87);
    background: rgb(13, 32, 51);
}

.modelSelectionModelActive .modelName {
    color: #b7b7b7;
}

.modelSelectionModelActive .contextSize {
    color: #b7b7b7;
}

.modelSelectionModelActiveSelected .contextSize {
    color: #e7e7e7;
}

.modelSelectionModelActiveSelected .modelName {
    color: #e7e7e7;
}

.modelSelectionModelActive .simpleIcon svg path,circle {
    fill: #b7b7b7 !important;
}

.modelSelectionModelActive .simpleIcon svg circle {
    fill: #b7b7b7 !important;
}

.modelSelectionModelActiveSelected .simpleIcon svg path {
    fill: #e7e7e7 !important;
}

.modelSelectionModelActiveSelected .simpleIcon svg circle {
    fill: #e7e7e7 !important;
}

.modelSelectionModel .simpleButton .simpleButtonLabel {
    padding-left: 0;
}

.modelSelection .swiper {
    width: auto;
    height: auto;
}

.modelSelection .swiper-slide {
    flex: 1 0 auto;
    width: auto;
}

.modelSelection .simpleIcon {

}

.modelSelection .simpleButton {

}

.simpleButton {
    display: flex;
    align-items: center;
    color: #e7e7e7;
    height: 32px;
    min-height: 32px;
    min-width: 32px;
    column-gap: 5px;
    cursor: pointer;
    user-select: none;
}

.simpleButtonWithIcon .simpleButtonNoLabel {
    justify-content: center;
}

.simpleButtonAutorepeat {
    -webkit-touch-callout: none;
    touch-action: none;
}

.simpleButtonInactive {
    cursor: default;
}

.simpleButtonLabel {
    white-space: nowrap;
    padding-left: 10px;
    padding-right: 10px;
    user-select: none;
}

.simpleButtonWithIcon .simpleButtonLabel {
    margin-left: -5px;
    padding-left: 0px;
    justify-content: center;
}

.simpleButtonWithLabel .simpleButtonLabel {
    display: flex;
    justify-content: center;
    padding-right: 20px;
    padding-left: 20px;
    margin-left: 0px;
}

.simpleButtonWithIcon.simpleButtonWithLabel .simpleButtonLabel {
    display: flex;
    justify-content: center;
    padding-right: 20px;
    padding-left: 0px;
    margin-left: 0;
}

.simpleButtonLabelComponent .simpleButtonLabel {
}

.simpleButtonLongPressable {
    user-select: none;
}

.benxtAppSafari .simpleButtonLongPressable {
    user-select: auto;
}


.keyboardRadioButton {
    width: 100%;
    display: flex;
    height: 32px;
    column-gap: 1px;
}

.keyboardRadioButtonIcon {
    display: flex;
    align-items: center;
    
}

.keyboardRadioButtonLabel {
    height: 32px;
    color: #e7e7e7;
    background: rgb(7, 100, 97);
    display: flex;
    align-items: center;
    max-width: 80px;
    justify-content: center;
    display: none;
}

.keyboardRadioButtonLeft {
    height: 32px;
    min-width: 32px;
    background: rgb(1, 154, 158);
}

.benxtAppMobile .keyboardRadioButtonLabel {
    display: none;
}

.benxtAppMobile .keyboardRadioButtonIcon {
    display: none;
}

.benxtAppMobile .keyboardRadioButtonOn {
    padding-left: 15px;
}

.benxtAppMobile .keyboardRadioButtonOff {
    padding-left: 15px;
}

.keyboardRadioButtonSmall .keyboardRadioButtonOff {
    display: none !important;
}

.keyboardRadioButtonSmall .keyboardRadioButtonOn {
    display: none !important;
}

.keyboardRadioButtonSmall {
    width: calc(46px * 3);
}

.benxtAppMobile .keyboardRadioButtonOff {
    padding: 0;
}

.benxtAppMobile .keyboardRadioButtonOn {
    display: flex;
    justify-content: center;
    padding: 0;
    width: 65px;
}

.benxtAppMobile .keyboardRadioButtonOff {
    display: flex;
    justify-content: center;
    padding: 0;
    width: 65px;
}

.benxtAppMobile .keyboardRadioButtonSmall .keyboardRadioButtonOn {

}
                  

.benxtAppMobile .keyboardRadioButtonSmall .keyboardRadioButtonIcon {
    display: flex;
}
    
.benxtAppMobile .keyboardRadioButtonRight {
    display: none;
}

.buttonz {
    display: flex;
    max-width: calc(100vw - 132px);
}

.keyboardRadioButtonRight {
    height: 32px;
    width: 32px;
    background: rgb(7, 100, 97);
}

.keyboardRadioButtonIcon {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.keyboardRadioButtonIcon div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.keyboardRadioButtonIcon svg {
    height: 20px;
    width: 20px;
}

.keyboardRadioButtonOn {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32px;
    color: #e7e7e7;
    cursor: pointer;
    min-width: 80px;
}

.keyboardRadioButtonOff {
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #e7e7e7;
    height: 32px;
    min-width: 80px;
}

.keyboardRadioButtonUnselected {
    display: flex;
    column-gap: 1px;
    background: rgb(12, 28, 28);
    cursor: pointer;    
}

.keyboardRadioButtonSelected {
    background: rgb(59, 101, 201);
    display: flex;
    column-gap: 1px;
    cursor: pointer;
}

.keyboardRadioButtonOffButton {
    height: 32px;
    width: 32px;
    background: rgb(99, 19, 9);
}

.benxtAppMobile .keyboardRadioButtonLabel {
}

.benxtAppMobile .keyboardRadioButtonRight {

}

.benxtAppMobile .keyboardRadioButtonOn {
}

.benxtAppMobile .keyboardRadioButtonOff {

}


.chatGPTModelHeader {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding-right: 10px;
}

.chatGPTModelHeaderClickable {
    cursor: pointer
}

.chatGPTModelHeaderAssistantView {
    background: rgb(59, 101, 201);
}

.keyboardEditDocumentText li p {
    display: inline;
}


.threadDeleteButton {
    right: 0;
}

.threadDeleteButtonWithLabel {
    position:relative;
}


.threadDeleteButton .uiOKCancelCancel {
    background: transparent;
}

.chatGPT .threadDeleteButtonConfirm {

}

.keyboardHeader .threadDeleteButton .uiOKCancelCancel {

}


.keyboardMenuItemRight {
    position: relative;
    display: flex;
    
}



.aiSaidImages  {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.aiSaidImages img {
    width: 100%;
    object-fit: contain;
}

.toolUnselected {
    background: rgb(0, 26, 25);
}
    
.taskDescription {
    display: flex;
    flex-direction: column;
    width: calc(100% - 50px);
    margin: 0;
    padding: 0;
    padding-left: 10px;
    padding-right: 10px;
}

.taskDescriptionLabel {
    max-width: calc(100% - 80px) !important;
    flex-grow: 1;
}

.taskDescriptionLabel .markdownContainer > h1 {
    margin-block-start: 0px;
}

.markdownContainer {
    width: 100%;
}

.markdownContainer blockquote {
    white-space: pre-wrap;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
}

.taskDate {
    margin-right: 32px;
}

.taskTitle .keyboardMenuItemRight {
}

.taskLastTopic {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 90px);
    padding-left: 32px;
    padding-bottom: 10px;
    font-size: 13px;
}
.taskLastTopic .taskDate {
    margin-right: 10px;
}

.taskDate {
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    margin-right: 5px;
    height: 32px;
}

.taskTopic {

}

.taskTitle {
    cursor: pointer;
    font-size: 14px;
    display: flex;
    width: 100%;
    background: rgb(12, 28, 28);
    color: #e7e7e7;
    padding-bottom: 5px;
}

.benxtAppDesktop .taskTitleSelected {
    background: rgb(10, 69, 97) !important;
}

.threadDeleteButtonConfirm {
    background: rgb(184, 38, 26);
}

.deleteButtonHolder .threadDeleteButtonConfirm .simpleButton {
    background: rgb(184, 38, 26);
}

.aiLinkImg {
    margin-left: 5px;
    margin-right: 5px;
    max-width: 100%;
}

.tokenDollars {
    position: absolute;
    right: 0;
    color: white;
    min-width: 150px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #3c3c3c;
    padding: 5px;
    
}

.sendError {
    position: absolute;
    width: calc(100% - 0px);
    background: #63130a;
    color: #e7e7e7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
}


.inputControlInputEditorInput img {
    max-width: 100%;
}


.chatGPT .keyboardInputControlSpeech {
    display: none;
}

.attunewiseLayer .keyboardHeader {
    margin-bottom: 5px;
}

.chatHeaderFiller {
    color: #e7e7e7;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
}

.modelRadioButtonIcon {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modelRadioButtonIcon div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modelRadioButtonIcon svg {
    height: 20px;
    width: 20px;
}

.modelRadioButtonOn {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32px;
    color: #e7e7e7;
    cursor: pointer;

}

.modelRadioButtonOff {
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #e7e7e7;
    height: 32px;
}

.modelRadioButtonUnselected {
    position: relative;
    display: flex;
    column-gap: 1px;
    background: rgb(12, 28, 28);
    cursor: pointer;
}

.modelRadioButtonSelected {
    position: relative;
    background: rgb(59, 101, 201);
    display: flex;
    column-gap: 1px;
    cursor: pointer;
}

.model.modelSubmenu {
    width: calc(100% - 25px);
    padding-left: 25px;
    position: relative;
}

.modelRadioButtonOffButton {
    height: 32px;
    width: 32px;
    background: rgb(99, 19, 9);
}

.modelRadioButtonRight {
    position: absolute;
    right: 0;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modelRadioButtonRight div {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modelRadioButtonRight svg {
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modelSpacer {
    height: 2px;
}

.aiHallucinated {
    opacity: 1.0;
    background: rgb(49,0,0) !important;
}

.aiNonHallucinated {
    opacity: 1.0;
    background: rgb(0, 102, 65) !important;
}

.chatGptChatMessageBody .aiUser  {
    background: rgb(10, 95, 145);
    background: rgb(12, 28, 28);
}


.haluExplainer {
    width: calc(100% - 80px);
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #999999;
    background: rgb(0, 26, 25);
}


.chatGptThreadsMenuTitle {
    display: flex;
    align-items: center;
    color: #e7e7e7;
    min-height: 32px;
    padding-left: 10px;
    width: calc(100% - 10px)
}

.questionRecall {
    background: rgb(0, 102, 65) !important;
}

.questionPrecision {
    background: rgb(49,0,0) !important;
}


.trainToggle {
    position: absolute;
    right: 0px;
    bottom: 0px;
    background: rgb(0, 26, 25);
    z-index: 2;
}

.uiOKCancelOofIcon svg {
    height: 32px !important;
    width: 32px !important;
}


.chatHeader {
    display: flex;
    justify-content: space-between;
    width: 100%;
    column-gap: 1px;
}

.chatHeaderFiller {
    position: relative;
    flex-grow:1;
    background: rgb(12, 28, 28);
}

.chatHeaderSelectedModels {
    color: #e7e7e7;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: rgb(12, 28, 28);
    padding-left: 5px;
    padding-right: 5px;
    font-size: 16px;
    width: 30px;
    color: transparent;
}

.chatBack .uiOKCancelOK {
    background: rgb(12, 28, 28);
}

.inputControlContainer {
    left: 0%;
    width: 100%;
}

.keyboardInstructionInput {
    width: 100%;
}


.modelCategory {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.modelCategoryMenu {
    display: flex;
    align-items: center;
    font-size: 16px;
    background: rgb(12, 28, 28);
    cursor: pointer;
    justify-content: space-between;
}

.modelCategoryOpen {
}
.modelCategoryOpen .modelCategoryMenu {

}

.modelCategoryIcon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modelIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
}

.modelIcon div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modelIcon svg {
    height: 32px;
    width: 32px;
}

.model {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    background: rgb(12, 28, 28);
    width: calc(100% - 0px);
    column-gap: 1px;
    cursor: pointer;
    position: relative;
}

.modelRadioButtonSelected {
    background: rgb(59, 101, 201);
}

.modelCategoryButtons {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    margin-top: 4px;
}

.modelLabel {
    display: flex;
    align-items: center;
    color: #e7e7e7;
    column-gap: 10px;
    white-space: nowrap;
    padding-left: 0px;
    padding-right: 0px;
    position: relative;
}

.modelId {
}


.modelVendorAndLabel {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    align-items: center;
}

.modelVersionDate {
    color: #e7e7e7;
}

.modelVersion {
    color: #c0c0c0;
    height: 32px;
    display: flex;
    font-size: 10px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    height: 32px;
    justify-content: center;
}

.modelVersionId {
    font-size: 8px;
    position: absolute;
    right: 0;
    bottom: 1px;
    opacity: 0.8;
}

.modelVendorSelectionCountContainer {
    display: flex;
    height: 32px;
    align-items: center;
    column-gap: 10px;
}

.modelVendorSelectionCount {
    color: #e7e7e7;
}

.modelVendorCheck {
    display: none;
}

.modelVendorSelectionCountContainer svg {
    height: 32px;
    width: 32px;
}

.modelVendorLabel {
    padding-right: 4px;
    font-weight: 550;
    min-height: 32px;
}

.modelLeft {
    display: flex;
}

.modelSelector {
    display: flex;
    flex-grow: 1;
}

.modelRight {
    column-gap: 1px;
    display: flex;
}

.modelCategoryMenu .modelIcon svg {
    height: 32px;
    width: 32px;
}

.modelCategoryLeft {
    display: flex;
}

.modelCategoryRight {
    display: flex;
}

.imageChooser svg {
    width: 100%;
    height: 100%;
}

.imageChooser svg path {
    fill: #e7e7e7;
}

.imageChooser div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.imageChooser {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    flex-shrink: 0;

}

.newFineTune {
    width: 100%;
    position: absolute;
    top: 100px;
    display: flex;
    flex-direction: column;
    row-gap: 2px;
}

.dialogField {
    background: rgb(12, 28, 28);
    display: flex;
    min-height: 45px;
    align-items: center;
    padding-left: 45px;
    width: calc(100% - 45px);
    color: #e7e7e7;
    column-gap: 2px;
    justify-content: space-between;
}

.dialogField .keyboardAddButton {
    position: static;
}


.inputControlKeyboardButton1.gear .uiOKCancelCancel {
    background: rgb(12, 28, 28);
}

.inputControlKeyboardButton1.gear svg {
    height: 32px !important;
    width: 32px !important;
}

.inputControlKeyboardButton1.gear svg {
    fill: #e7e7e7;
}

.modelsMenuVendorSelected .inputControlKeyboardButton1 .uiOKCancelCancel {
    background: rgb(59, 101, 201) 
}

.modelsMenuVendorSelected .simpleButton {
    background: rgb(59, 101, 201) 
}


.simpleCheckbox {
    color: #e7e7e7;
    font-weight: 500;
    background: rgb(12, 28, 28);
    height: 32px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    user-select: none;
}

.simpleCheckboxNoLabel {
    width: 32px;
    padding-right: 0;
}

.chatGptThreadsMenuOptionsContainer .simpleCheckbox {
    font-size: 14px;
}

.modelsMenuOptionsSize {
    row-gap: 4px;
    background: rgb(10, 35, 36);
    padding-top: 2px;
    padding-bottom: 0px;
    flex-direction: column;
}

.modelsMenuGroup {
    column-gap: 4px;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.modelsMenuOptionsSize .simpleCheckbox {
    flex-grow: 1;
    width: calc((600px - 20px) / 4 - 4 * 4px);
}

.bnAppMobile .modelsMenuOptionsSize .simpleCheckbox {
    flex-grow: 1;
    width: calc((100vw - 20px) / 4 - 4 * 4px);
}

.simpleCheckboxWithIcon {
    padding-left: 2px;
}

.simpleCheckboxSelected {
    background: rgb(59, 101, 201);
}

.simpleCheckboxIcon {
    height: 32px;
    width: 32px;
    min-width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.simpleCheckboxIcon div {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.simpleCheckboxIcon svg {
    height: 32px;
    width: 32px;
    fill: #e7e7e7;
}

.legacyIconSize .simpleCheckboxIcon svg {

}

.simpleIcon.legacyIconSize {
}

.simpleIcon.legacyIconSize svg {
}

.modelVision {
    position: absolute;
    right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
}

.modelVision div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.modelVision svg {
    height: 100%;
    width: 100%;
    fill: #aeaaae;
}

.modelsMenuVendor {
    height: 32px;
    width: 32px;
    min-width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modelsMenuVendor .simpleButton  {
}

.modelsMenuVendor .simpleButton .simpleIcon {
}
.modelsMenuVendor .simpleButton .simpleIcon svg {
    height: 32px;
    width: 32px;
}


.modelPriceStyle {
    color: #e7e7e7;
    font-size: 11px;
    font-weight: 500;
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.modelPrice {
    color: rgb(209, 152, 19);
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: right;
    font-size: 11px;
    position: absolute;
    right: 70px;
}

.optionLabel {
    color: #e7e7e7;
    height: 32px;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    justify-content: center;
    justify-content: flex-start;
    white-space: nowrap;
}

.attunewiseContent {
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    min-height: 100%;
    position: absolute;
    transition: left 0.4s cubic-bezier(0.25, 0.1, 0.25, 1.0);
    left: 0;
    overflow: visible;
}

.attunewiseTaskActive {
    left: -100%;
}

.attunewiseLayer {
    position: relative;
    height: 100%;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    max-width: 100%;
    max-height: 100%;
    background: rgb(0, 25, 26);
    box-sizing: border-box;
}

.attunewiseLayerDiscussion {
    /*    overflow: hidden; */
    display:  flex;
    flex-direction: column;
}

.attunewiseLayerChat {
    position: absolute;
    left: 100%;
    top: 0px;
    overflow: hidden;
}

.discussionSearch  {
    min-height: auto;
    height: 32px;
    width: 100%;
}

.forModelsMenu {
    display: flex;
    min-width: 100%;
    width: 100%;
    min-height: 32px;
    height: 32px;
    justify-content: flex-end;
    background: rgb(0, 25, 26);
    overflow: hidden;
    z-index: 80;
}

.modelMenuWithTriggger {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 0;
    left: 0;
}

.popupHolder {
    position: absolute;
    top: 0;
    left: 0;
    width: 32px;
    height: 32px;
    z-index: -1;
}

.modelMenuWithTriggger .keyboardAddButton {
    position: static;
}

.forModelsMenu .modelPrice {
    position: static;
    background: rgb(12, 28, 28);
    padding-right: 8px;
}


.chatHeaderSelectedModelIcons {
    display: flex;
    max-width: calc(32px * 5 + 4px);
    overflow-x: auto;
}

.chatHeaderSelectedModelIcon {
    min-width: 32px;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.chatHeaderSelectedModelIconSelected {
    background: rgb(59, 101, 201);
}

.forModelsMenu .chatHeaderSelectedModelIcons {
    display: flex;
    align-items: center;
    height: 100%;
    column-gap: 1px;
    background: rgb(12, 28, 28);
}

.simpleIcon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.legacyIcon {
    height: 32px;
    width: 32px;
    min-width: 32px;
}

.simpleIcon div {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.simpleIcon svg {
    width: 32px;
    height: 32px;
    fill: #e7e7e7;
}

.simpleIcon svg path {
    fill: #e7e7e7;
}

.simpleIconWithSize svg {
    width: 100%;
    height: 100%;
}

.gear {
    display: none;
}

.messageBodySwiperContainer {
    display: flex;
    width: 100%;
    flex-direction: column-reverse;
    position: relative;
}

.messageBodySwiperContainer .swiper {
    width: 100%;
}

.messageBodyDotsContainer {
    background: rgb(2, 77, 87);
    background: rgb(13, 32, 51);
    width: 100%;
    display: flex;
    justify-content: center;
    height: 32px;
    min-height: 32px;
    align-items: center;
}

.messageBodyDotsContainer .slider {
    background: rgb(2, 77, 87);
    background: rgb(13, 32, 51);
}

.attunewiseDiscussionCal {
    position: relative;
    padding-top: 10px;
    margin-top: 2px;
    width: 100%;
    background: rgb(12, 28, 28);
    color: #e7e7e7;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
}

.attunewiseDiscussionCal .usageCalendarViewChoice {
    margin-top: 2px;
    font-size: 13px;
    margin-bottom: 10px;
}


.discussionHeader {
    width: 100%;
    background: rgb(12, 28, 28);
    color: #e7e7e7;
    padding-right: 10px;
    padding-left: 0px;
    font-size: 14px;
    box-sizing: border-box;
    padding-bottom: 5px;
    padding-top: 15px;
}

.mainDots {
    width: calc(100% - 10px);
    margin-left: 10px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.horizontalMessageLayout {
    width: 100%;
    background: rgb(2, 77, 87);
    background: rgb(13, 32, 51);
    color: #e7e7e7;
    box-sizing: border-box;
}

.horizontalMessageLayout .markdownContainer {
    padding-bottom: 5px;
}

.leftColumn1 {
    display: flex;
}

.middleColumn1 {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: calc(100% - 64px);
}


.rightColumn1  {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.horizontalTextLayout {
    display: flex;
    width: 100%;
    max-width: 100%;
    overflow-wrap: anywhere;
}

.benxtAppMobile .horizontalTextLayout {
    width: 100vw;
}

.aiJudge {
    background: #0f2733;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    padding-top: 2px;
}


.modelJudgement {
    background: #0f2733;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
}


.modelJudgementInline {
    background: #0f2733;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 10px;
    margin-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 20px
}

.modelJudgementText {
    margin-top: 10px;
    padding-bottom: 25px;
}

.modelJudgementHeader {
    display: flex;
    width: 100%;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
    height: 32px;
}


.modelJudgementHeaderLeft {
    display: flex;
    column-gap: 4px;
    align-items: center;
}

.modelJudgementHeaderRight {
    display: inline-flex;
    align-items: center;
}

.taskTitle .modelPrice {
    position: absolute;
    bottom: -10px;
    right: 44px;
}

.discussionHeader  {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.discussionHeader .modelPrice {
    position: static;
    height: auto;
    margin-right: 35px;
}



.codeCopy {
    position: absolute;
    top: 0px;
    right: -32px;
    height: 32px;
    width: 32px;
    display: inline-block;
    justify-content: center;
    align-items: center;
}

.codeCopy .inputControlKeyboardButton1 .uiOKCancelCancel {
    background: transparent;
    transform: translate(8px, 8px);
}

.codeCopy .inputControlKeyboardButton .uiOKCancelOK {
    background: transparent;
    max-width: 90px;
}
 
.modelLegacy {
    text-decoration: line-through;
    text-decoration-color: #e0e0e0;
}


.claims {
    display: flex;
    width: 100;
    flex-direction: column;
    margin-top: 15px;
    row-gap: 5px;
}

.claim {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.claimCheck svg {
    display: flex;
    width: 20px;
    fill: #e7e7e7;
}


.forModelsMenu .bnContentPage {
    left: 0;
    top: 0;
    width: 0px;
    visibility: hidden;
}

.singleMessageBody {
    width: 100%;
}

.judgeWinnerMsg {
    background: #0f2733;
    padding: 10px;
    color: #e7e7e7;
    display: flex;
    box-sizing: border-box;
    padding-left: 32px;
    padding-right: 32px;
    flex-direction: column;
    width: 100%;
}

.judgesLine {
    margin-top: -10px;
    margin-left: -10px;
}

.judgeWinnerMsgText {
    width: 100%;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 5px;
}

.judgeLosers {
    margin-top: 0px;
    margin-left: -5px;
}

.judgeLoser {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
}

.benxtAppMobile .judgeLoser {
    width: 80%;
}


.modelSelection2 {
    padding-left: 5px;
    padding-right: 5px;
}

.modelSelection1 .simpleButton {
    height: 30px;
    margin-left: -5px;
    margin-right: -5px;
    display: inline-flex;
}

.modelSelection1 .simpleButtonLabel {
    padding-left: 0;
    
}

.modelTitle {
    padding-left: 5px;
}

.modelSelectionGap {
    width: 1px;
    height: 100%;
    background: black;
}

.modelSelectionModelActive .modelSelectionGap {
    background: black;
}

.modelSelectionModelSelected .modelSelectionGap {
    background: rgb(0, 26, 25);
}

.judgesLine {
    display: flex;
    transform: translate(-30px, 0);
    margin-bottom: 15px;
    font-weight: 550;
    display: none;
}

.benxtAppMobile .judgesLine {
    display: none;
}

.deleteButtonHolder {
    height: 32px;
    width: 32px;
    position: relative;
}

.deleteButtonHolder .threadDeleteButton {
    position: absolute;
    right: 0;
}

.deleteButtonHolder .threadDeleteButtonConfirm {
    min-width: 100%;
    position: absolute;
    right: 0;
}

.swiperNav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
}

.swiperNavButton {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.swiperNavButton div {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiperNavButton svg {
    height: 32px;
    width: 32px;
}

.gearButton {
    display: flex;
    height: 32px;
    width: 32px;
    align-items: center;
    justify-content: center;
}

.gearButton .simpleIcon {
    height: 32px;
    width: 32px;
}
.gearButton .simpleIcon svg {
    height: 32px;
    width: 32px;
}

.settingsPopup {
    position: absolute;
    z-index: 20;
    bottom: 2px;
    left: 32px;
    width: 32px;
    display: none;
}

.popup1 {
    display: flex;
}

.chatSettings1 {
    position: absolute;
    width: calc(min(100vw, 600px));
    min-height: 32px;
    bottom: 50px;
    background: rgb(0, 26, 25);
    z-index: 10;
    box-sizing: border-box;
    padding-left: 5px;
    padding-top: 5px;
    display: flex;
    flex-direction: column;
}

.tempLabel {
    color: #e7e7e7;
    white-space: nowrap;
    display: flex;
    min-width: 55px;
    margin-right: 10px;
}

.systemInstructions {
    color: #e7e7e7;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
}

.systemInstructionLabel  {
    display: flex;
    justify-content: flex-start;
    font-weight: 550;
    margin-left: 35px;
    height: 32px;
    dislay: flex;
    align-items: center;
}

.systemInstructionInput  {
    display: flex;
    justify-content: flex-start;
    color: #e7e7e7;
    min-height: 80px;
    padding: 5px;
    margin-left: 35px;
    width: calc(100% - 32px);
    height: calc(100% - 0px);
}

.systemInstructionInput:focus {
    background: rgb(255, 255, 255, 0.1);
}

.systemInstructionRight  {
    margin-top: 32px;
    width: 32px;
    min-width: 32px;
    display: flex;
    height: calc(100% - 32px);
    flex-direction: column;
    justify-content: space-between;
}

.systemInstructionInput:focus {
    outline: none;
}

.systemInstructionControls {
    display: flex;
}

.systemPromptInvisible svg path {
    fill: rgb(12, 28, 28);
}

.systemPromptInvisible svg circle {
    fill: rgb(12, 28, 28);
}

.editableContent {
    white-space: pre-wrap;
    box-sizing: border-box;
}

.editableContent:focus {
    outline: none;
    background: rgb(255, 255, 255, 0.1);
    caret-color: white;
    padding: 5px;
    margin-top: -5px;
    margin-bottom: -10px;
    margin-left: -5px;
    width: calc(100% + 10px);
}

.editableContentUser:focus {
}

.editableContentContainer {
    padding-bottom: 15px;
    width: 100%;
}

.editToolbar {
    background: rgb(0, 0, 0, 0.1);
    display: flex;
    width: 100%;
    display: none;
}

.editToolbar .inputControlKeyboardButton1 .uiOKCancelCancel {
    background: transparent !important;
}

.modelTrashButton {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(0, 0, 0, 0.2);
}

.modelTrashButton .simpleButton {
    padding: 0;
}

.simpleButton .simpleIcon {
    width: 32px;
    height: 32px;
}


.judgeSummary {
    position: relative;
    background: #0f2733;
    color: #e7e7e7;
    width: 100%;
}

.judgeDeleteButton {
    position: absolute;
    top: 0;
    right: 0;
}

.judgeSummaryContent {
    padding-left: 32px;
    padding-right: 32px;
    box-sizing: border-box;
}

.discussionSearch .inputControlContainer {
    position: relative;
    height: 32px;
    width: 100%;
}

.discussionSearch .inputControl {
    position: absolute;
    z-index: 10;
}

.fileMenuButton {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    cursor: pointer;
}

.fileMenuButton div {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fileMenuButton.fileMenuButtonActive svg {
    height: 32px;
    width: 32px;
}

.fileMenuButton svg {
    height: 100%;
    width: 100%;
}
.fileMenuButton svg path {
    fill: #232323 !important;
}


.uploadErrorDialog {
    color: #e7e7e7;
    padding: 10px;
    padding-left: 15px;
    box-sizing: border-box;
    width: calc(100% - 10px);
    display: flex;
    font-weight: 500;
    background: rgb(181, 67, 26);
    align-items: center;
    position: absolute;
    z-index: 20;
    transform: translate(0, 32px);
}

.uploadErrorDialog .simpleIcon {
    padding-right: 5px;
}

.lengthInfo {
}


.searchFieldInput {
    width: 100%;
}


.searchFieldInput .chatHeader {
    width: 100%;
}

.message-fade-nout {
    opacity: 0;
}

.answerYellow {
    padding: 5px;
    margin-top: -5px;
    margin-left: -5px;
    margin-bottom: 5px;
}

.answerRed {
    padding: 5px;
    margin-top: -5px;
    margin-left: -5px;
    margin-bottom: 10px;
    text-decoration: line-through;
}

.judgeButton {
    background: rgb(1, 160, 144);
    flex-grow: 0;
    position: absolute;
    bottom: 7px;
    right: 10px;
}


.correctButtonHolder .legacyIconSize svg {
}

.correctButtonHolder .legacyIconSize svg path {
    fill: #e7e7e7 !important;
}

.answerGreen {
}

.answerGreen .simpleIcon {
    display: inline-block;
}

.answerYellow {
}

.answerYellow .simpleIcon {
    display: inline-block;
}

.defaultAnswer .markdownContainer {
    opacity: 0.7;
}

.defaultAnswer .markdownContainer * {
}

.certifiedJudgement {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.certifiedJudge {
    display: flex;
}

.certifiedJudge .modelLabel {
    color: #5cad88;
    margin-left: 5px;
}

.certifiedJudge .modelLabel .modelVersion {
    color: #5cad88;
}

.certifiedJudge .modelLabel .modelVersionDate {
    color: #5cad88;
}

.certifiedSummary {
    display: inline-block;
    color: #5cad88;
    font-size: 12px;
}

.certifiedJudge .simpleIcon svg path {
    fill: #5cad88 !important;
}

.answerYellow .certifiedSummary {
    color: #adad00;
}

.answerYellow .certifiedJudge .modelLabel {
    color: #adad00;
    margin-left: 5px;
}
.answerYellow .certifiedJudge svg path {
    fill: #adad00 !important;
}


.aiUserEditMenu .simpleButton {
    background: rgb(10, 28, 28);
}

.modelRight .gearButton svg {
    height: 30px;
    width: 30px;
}

.modelRight .gearButton svg path {
    fill: #aeaeae;
}

.customNewTopicButton {
    min-height: 32px;
    min-width: 32px;
}

.selectionAddButton {
    background: #3b65c9;
}

.forModelsMenu {
    background: rgb(12, 28, 28);
}

.chatGPT .forModelsMenu.modelsMenuPopupHidden #modelMenuTrigger  {
    visibility: hidden;
    transform: translate(0, 32px);
    pointer-events: none;
    width: 0px;
}

.chatGPT .modelMenuPopupHolder {
    width: 0px;
}

.editMenuPopup {
    /* background: rgb(12, 28, 28); */
}

.chatGptThreadsMenu .editMenuPopup {
    background: transparent;
}

.copyAISaid .editMenuPopup {
    background: none;
}

.systemInstructionMessage {
    font-family: apparat;
    color: #e7e7e7;
    width: 100%;
    min-width: 100%;
    display: flex;
    box-sizing: border-box;
    background: #0f2733;
}

.systemInstructionMessage .editableContentContainer {
    width: calc(100% - 64px);
    margin-left: 32px;
}

.systemInstructionMessageVisible {
    opacity: 1
}

.systemInstructionMessageLabel  {
    display: flex;
    justify-content: flex-start;
    font-weight: 550;
    margin-left: 35px;
    height: 32px;
    dislay: flex;
    align-items: center;
}

.systemInstructionMessageLeftTop  {
    display: flex;
    align-items: center;
    height: 32px;
    width: 100%;
    justify-content: space-between;
}

.systemInstructionMessageContent  {
    display: flex;
    color: #e7e7e7;
    margin-left: 32px;
    flex-grow: 1
}

.systemInstructionTitle  {
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.systemInstructionHost {
    padding-left: 32px;
    padding-bottom: 15px;
}

.systemInstructionMessageInput  {
    display: flex;
    justify-content: flex-start;
    color: #e7e7e7;
    min-height: 80px;
    padding: 5px;
    margin-left: 35px;
    width: calc(100% - 32px);
    height: calc(100% - 0px);
}

.systemInstructionMessageInput:focus {
    background: rgb(255, 255, 255, 0.1);
}

.systemInstructionMessageLeft {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.systemInstructionMessageLeftTop .simpleIcon {
    min-width: 32px;
}

.systemInstructionMessageRight  {
    width: 32px;
    min-width: 32px;
    display: flex;
    height: calc(100% - 32px);
    flex-direction: column;
}

.systemInstructionMessageInput:focus {
    outline: none;
}


.chatHeader .inputControlInputEditor.inputControlEditorDownward {
    width: 100%;
}

.chatGptThreadsMenu-search {
    height: calc(100% - 45px - env(safe-area-inset-bottom));
}

.datasetExamplesViewChatGPT .chatGptThreadsMenu-search {
    height: calc(100% - 85px - env(safe-area-inset-bottom));
}


.chatPageContent {
    height: 100%;
    width: 100%;
    background: rgb(0, 25, 26);
}

.baseModelMenu .simpleButton {
    background: rgb(59, 101, 201);
}

.benxtAppDesktop .baseModelMenu .simpleButton:hover {
/*    background: rgb(59, 101, 201); */
}

.benxtAppDesktop .baseModelMenu .simpleIcon:hover {
/*    background: rgb(59, 101, 201); */
}

.baseModelMenuActive .simpleButton {
    background: rgb(2, 77, 87);
    background: rgb(13, 32, 51);
}

.baseModelMenu {
    overflow-y: auto;
}

.modelConfigPopupContainer {
    background: rgb(0, 25, 26);
    filter: drop-shadow(-4px 4px 4px rgb(0, 25, 26, 0.8));
    min-width: 350px;
    transform: translate(-10px, -8px);
    padding-right: 20px;
}

.modelConfigPopup {
    margin-top: 1px;
    width: 100%;
}

.modelConfigPopupMobile {
    position: fixed;
    width: calc(100vw - 32px);
    left: 20px;
    min-width: auto;
}
    
.modelConfigPopup .modelConfig  {
    width: 100%;
}

.modelConfigPopup .modelConfig .tempSlider {
    padding-right: 20px;
}

.modelConfigPopupSelected .modelConfig .tempSlider {

}

.modelConfigPopupActive .modelConfig .tempSlider {

}

.modelConfigPopup .slider1Container {

    padding-left: 0px;
    padding-right: 0px;
    box-sizing: border-box;
}

.modelConfigPopupSelected .slider1Container {

}

.modelConfigPopupActive .slider1Container {

}

.modelConfigPopupActiveSelected .modelConfig .tempSlider {

}

.modelConfigPopupActiveSelected .slider1Container {

}


.modelsMenuPreview .chatGPTModelsMenuTitleNormal {
    display: none;
}

.modelsMenuPreview .chatGptThreadsMenuOptions {
    display: none;
}


.modelRight .simpleButton .simpleIcon svg path {
    fill: rgb(0, 255, 0) !important;
}

.modelIconOpen .simpleIcon svg path {
    fill: rgb(0, 255, 0) !important;
}

.modelIconOpen .simpleIcon {
    width: 32px;
    height: 32px;
}

.modelsMenuOptionsInputContext {
    display: none;
}

.modelsMenuOptionsOutputContext {
    display: none;
}

.judgeButtonHolder {
    display: flex;
}

.keyboardMenuItemActions {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.aiSaidMenu .simpleButton {

}

.emptyAssistantBody {
    min-height: 32px;
    width: 100%;
}

.threadsFiller {
    flex-grow: 1;
    width: 100%;
    display: flex;
}

.messageScroller {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-anchor: none;
    overscroll-behavior: none;
    min-height: 100%;
    max-height: 100%;
}

.messageScrollerContent {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.chatGptMessagesViewContainer {
    margin-top: 0px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.chatGptMessagesView {
    width: 100%;
    height: calc(100% - 68px);
    row-gap: 3px;
    display: flex;
    flex-direction: column;
}

.uiChatMarginBottom .itemScroller {
    row-gap: 4px;
    background: rgb(4, 17, 17);
    min-height: 0;
    height: auto;
 /*   border: 1px solid red; */
}

.toolsetMessageTask {
    font-size: 14px;
    background: rgb(12, 28, 28);
    box-sizing: border-box;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
}

.toolsetMessageTaskSelected {
    padding-top: 10px;
    background: rgb(10, 69, 97) !important;
}


.toolsetMessageTask .toolsetMiddle {
    margin-left: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start
}

.toolsetMessageTask .toolsetLeftTopRow {
    align-items: flex-start
}

.toolsetMessageTask .toolsetLeftTopRowLeft {
    align-items: flex-start;
    height: 100%;
}

.toolsetMessageTask .toolsetLeftTopRow .simpleIcon {
    align-items: flex-start;
    height: 100%;
    transform: translate(0, -6px);
}

.toolsetMessageTask .toolsetLeftTopRow .toolsetMiddleDescription {
    font-size: 15px;
    font-weight: 500;
    margin-top: 0px;
}

.toolsetMessageTask .toolsetMiddleDescription {
    margin-top: 10px;
}

.toolsetMessageTask .toolsetLeft .simpleIcon {

}

.toolsetMessageTaskNo {
    display: none;
}

.chatGptMessagesView:focus {
    outline: none;
}

.discussionHeader {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.threadsPage {
    height: 100%;
    min-width: 100%;
    margin-top: 0px;
}

.chatGptPage {
    height: 100%;
    width: 100%;
}

.benxtAppDesktop .chatGptPage {
    overflow: visible;
}

.benxtAppDesktop .chatGptPage .threadsPage {
    transform: translate(-350appx);
}

.chatGptContentPage {
    height: 100%;
    min-width: 100%;
    width: 100%;
}

.autoheight {
    width: 100%;
}

/** none of this works **/
.li-marker-container::marker {
}

.li-fade-in {
    list-style: none;
    position: relative;
}

.li-fade-in::before {
    content: counter(list-item) ")";
    position: absolute;
    left: -1em;
    animation: fadeIn 0.4s linear;
    animation-delay: inherit;
}

@keyframes fadeIn {
    0% { color: transparent; }
    100% { color: #e7e7e7; }
}

.li-fade-in::marker {
    animation: fadeIn 0.4s linear;/*cubic-bezier(0.25, 0.1, 0.25, 1.0);*/
    animation-delay: inherit;
    color: transparent;
    content: counter(list-item) ")";
}

@keyframes fadeIn {
    0% { color: transparent; }
    100% { color: #e7e7e7; }
}


.contextSize {
    display: flex;
    align-items: center;
    padding-right: 5px;
}

.chatGptSystemPromptView {
    height: 100%;
    width: 100%;
}


.toolUseDiv {
    width: calc(100%);
    margin-bottom: 10px;
}

.toolUseHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgb(255, 255, 255, 0.1);
}

.toolUseHeaderLeft {
    display: flex;
    align-items: center;
}

.toolUseHeaderTitle {
    padding: 10px;
    padding-left: 0px;
}

.toolUseContent {
    background: rgb(255, 255, 255, 0.1);
    padding: 10px;
    display: flex;
    width: calc(100% - 20px);
}

.toolUsesContent {
    display: flex;
    flex-direction: column;
    width: 100%;
}
                 
.toolUsesContent .swiper {
    width: 100%;
}

.messageCount {
    font-size: 13px;
    height: 32px;
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.toolUseId {
    font-size: 11px;
    margin-bottom: 10px;
}

.terminalEmulator {
    width: 100%;
    white-space: pre-wrap;
    font-family: Monaco;
}

.terminalEmulatorCommand {
    width: 100%;
    white-space: pre-wrap;
}

.terminalEmulatorOutput {
    width: 100%;
    white-space: pre-wrap;
}
                   

.webPageResult {
    width: calc(100% - 50px);
    margin-left: 10px;
    background: rgb(1, 42, 44);
    padding: 10px;
    
}

.systemPromptContainer {
    display: flex;
    flex-direction: column;
    background: rgb(255, 255, 255, 0.1);
    padding: 0px;
    width: calc(100% - 0px);
    padding-right: 0px;
    margin-left: 0px;
}

.systemPrompts {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    row-gap: 4px;
    padding-bottom: 4px;
}

.systemPromptHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.systemPromptHeaderLeft {
    display: flex;
    align-items: center;
}

.systemPromptBody {
    width: calc(100% - 0px);
    padding-top: 5px;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
}

.systemPromptBody .markdownContainer {
    width: calc(100% - 64px);
    margin-left: 32px;
}

.thinking {
    opacity: 0.7;
    margin-bottom: 5px;
}


.textEditViewer {
    width: 100%;
    max-height: 500px;
}

.textEditViewerFullPath {
    padding: 10px;
    padding-top: 0px;
}

.systemPromptHeaderDisclosure {
    display: flex;
    align-items: center;
    column-gap: 2px;
    padding-right: 0px;
}
.systemPromptHeaderDisclosure .simpleButton {
    width: 32px;
}

.systemInstructionMessage .systemPromptContainer {
    padding-top: 4px;
    padding-right: 0px;
    width: 100%;
}


.finderFiles {
    max-height: 500px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

finderFiles > div:nth-of-type(odd) {

}

.finderFiles > div:nth-of-type(even) {
    background-color: #f9f9f9; /* Light gray */
}

.codeOutput {
    padding-top: 16px;
    color: #BBBBBB;
    flex-wrap: wrap;
    display: flex;
    white-space: pre-wrap;
}

.finderNameCell {
    display: flex;
    align-items: center;
    min-width: 250px;
}

.finderNameCell svg path {
    fill: rgb(81, 81, 81);
}

.toolUseDiv .googleSearch {
    background: #1e1e1e;
    width: 100%;
}

.toolUseDiv .googleSearch .google-results {
    row-gap: 0;
    max-height: 600px;
    overflow-y: auto;
}

.toolUseDiv .googleSearch .result-item {
    background: #1e1e1e;
}


.toolUseContentN {
    width: calc(100% - 0px);
    display: flex;
    flex-direction: column;
}

.toolUseContentNFunctionName {
    height: 32px;
    padding-left: 22px;
}


.queryChatDbDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 32px;
}

.windowButtons {
    padding-top: 5px;
    display: flex;
    column-gap: 10px;
}

.benxtAppMobile .windowButtons {
    display: none !important;
}

.codeDisplayTitle {
    flex-shrink: 1;
    padding-left: 15px;
    padding-right: 15px;
}

.codeContainer {
    height: calc(100% - 40px);
    width: 100%;
    overflow-y: auto;
}

.codeContent {
    width: 100%;
    padding-bottom: 0px;
    overflow-x: auto;
}

.dateTime {
    display: flex;
    width: 100%;
    justify-content: space-between;
    white-space: nowrap;
}

.benxtAppMobile .dateTime {
    flex-direction: column;
    row-gap: 10px;
    align-items: center;
}

.dateTime > div {
    box-sizing: border-box;
}

.benxtAppMobile .dateTime > div {
    min-width: 300px;
    min-height: 300px;
    transform: scale(0.9);
}

.chatGPTDetail {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.chatGPTDetailBody {
    width: 100%;
    flex-grow: 1;
    min-height: 0;
    height: calc(100% - 32px);
}

.chatGPTDetailHeader {
    width: 100%;
}

.chatGPTDetailBody .keyboardHeader {
    display: none;
}

.signed {
    display: none;
}

.signature {
    background: yellow;
}


