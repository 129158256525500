.infiniteScroller {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-anchor: none;
    overscroll-behavior: none;
    min-height: 100%;
    max-height: 100%;
}

.infiniteScrollerContent {
    width: 100%;
    display: flex;
    flex-direction: column;
}


.infiniteScrollMargin {
    height: 100%;
    width: 100%;
}

.infiniteScrollContainer {
    height: 100%;
    width: 100%;
}

.itemScroller {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    --webkit-overflow-scrolling: touch;
    min-height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    transform: translate3d(0, 0, 0);
    will-change: transform;
}

/* major wtfs but needed for proper Viewport.js behavior on safari */
.benxtAppSafari .itemScroller {
    overflow-anchor: none;
    overscroll-behavior: none;
}

.itemScrollerNoMomentum {
    -webkit-overflow-scrolling: auto !important;
}

.itemScroller:focus {
    outline: none;
}
