.chatGptSystemPromptsMenu {
    width: calc(100% - 20px);
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    flex-direction: column;
}

.chatGptSystemPromptsMenuSystemPrompts {
    color: #e7e7e7;
    display: flex;
    flex-direction: column;
    row-gap: 1px;
    overflow-y: auto;
    height: calc(100% - 80px - env(safe-area-inset-bottom) - env(safe-area-inset-top));
}

.systemPromptTopLine {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 1px;
}

.systemPromptTopLine .simpleButton {
    background: rgb(49, 47, 102);
    background: #0f2733;
}

.systemPromptsCal {
    position: relative;
    margin-top: 0px;
    color: #e7e7e7;
    box-sizing: border-box;
    width: 100%;
    background: rgb(49, 47, 102);
    background: #0f2733;
    padding-bottom: 15px;
}

.systemPromptsCal .usageCalendar {
    background: #0f2733;
}


.systemPromptTopLineSelect .simpleButton {
    display: none;
}

.systemPromptTopLine .searchFieldInput {
    width: calc(100% - 32px);
}

.systemPromptTopLineSelect .searchFieldInput {
    width: calc(100%);
}

.systemPromptTopLine .searchFieldInput .inputControlInputEditor.inputControlEditorDownward {
    width: 100%;
}

.systemPromptTask {
    background: #0f2733;
    display: flex;
    width: 100%;
    padding-bottom: 15px;
}

.systemPromptRight {
    display: flex;
    flex-direction: column;
    width: 32px;
    height: 100%;
}

.systemPromptLeft {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.systemPromptLeftTopRow {
    display: flex;
}

.systemPromptLeftTopRowLeft {
    display: flex;
    flex-grow: 1;
    align-items: center;
}

.systemPromptTopRowRight {
    display: flex;
    align-items: center;
    height: 32px;
    padding-right: 10px;
}

.systemPromptLeft .simpleIcon {
    width: 32px;
    height: 32px;
}

.systemPromptMiddle {
    display: flex;
    flex-grow: 1;
    color: #e7e7e7;
    flex-direction: column;
    width: calc(100% - 32px);
    margin-left: 32px;
}

.systemPromptMiddleTopRow {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 32px;
}

.systemPromptTitle {
    display: flex;
    color: #e7e7e7;
    max-height: 32px;
    align-items: center;
    font-weight: 500;
}

.systemPromptContent {
}

.systemPromptMiddleDescription {
    width: calc(100% - 0px);
}

.systemPromptDate {
    font-size: 13px;
}

.systemPromptTitle input {
    font-size: 16px;
    font-family: apparat;
    min-width: calc(min(45vw, 280px));
    color: #e7e7e7;
    background: #0f2733;
    border: 0;
}

.benxtAppDesktop .systemPromptTitle input {
    min-width: 250px;
}

.systemPromptTitleEditor:focus {
    caret-color: white;
    background: rgb(255, 255, 255, 0.1);
    outline: none;
    transform: translate(-5px, 0);
    padding-left: 5px;
    width: calc(100% - 20px);
}

.systemPromptEditorContainer {
    height: 100%;
    width: 100%;
    position: relative;
    flex-grow: 1;
}

.bnextAppDesktop .systemPromptEditorContainer {
    height: 100%;
}

.benxtAppMobile .systemPromptEditorContainer {
    overflow-y: auto;
    height: 100%;
}

.systemPromptEditorButtons {
    width: 100%;
    position: absolute;
    bottom: 10px;
    right: 15px;
    display: flex;
    justify-content: flex-end;
    column-gap: 2px;
}

.systemPromptEditorBg {
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 0px;
    width: calc(100% - 20px);
    height: calc(100% - 0px);
    display: flex;
    justify-content: space-between;
}

.systemPromptEditorLeft {
    flex-grow: 1;
    width: 100%;
}

.systemPromptEditorRight {
    position: absolute;
    right: 0;
}


.systemPromptEditor {
    color: #e7e7e7;
    font-size: 16px;
    font-family: apparat;
    caret-color: white;
    box-sizing: border-box;
    padding-top: 10px;
    padding-left: 10px;
    height: calc(100% - 0px);
    padding-right: 32px;
    overflow-y: auto;
}

.systemPromptEditor:focus {
    background: rgb(255, 255, 255, 0.1);
    outline: none;
}

.systemPromptEditorButtons {

}
.systemPromptEditorButtons .simpleButton {
    background: #3c65c9;
}

.systemPromptActions .simpleButton {
    background: #0f2733;
}

.systemPromptDelete .simpleButton {
    background: rgb(95, 18, 10);
}

.systemPromptEditorSections {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    row-gap: 4px;
    box-sizing: border-box;
    height: calc(100% - 0px);
}

.systemPromptEditorSectionTitle {
    height: 32px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 10px;
    font-weight: 500;
}

.systemPromptEditorSectionContent {
    padding-top: 10px;
    flex-grow: 1;
}

.systemPromptEditorSection {
    background: rgb(19, 35, 36);
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    width: calc(100% - 0px);
    column-gap: 10px;
    max-height: 100%;
}

.systemPromptEditorSectionRow1 {
    background: rgb(12, 28, 28);
    justify-content: space-between;
    display: flex;
    width: 100%;
    box-sizing: border-box;
}

.systemPromptEditorSectionRow2 {
    background: rgb(12, 28, 28);
    display: flex;
    width: 100%;
    flex-direction: column;
    min-height: 0;
}

.systemPromptEditorSectionRight {
    display: flex;
    height: 100%;
    flex-direction: column;
    width: 32px;
}

.systemPromptEditorContainerEditor {
    width: 100%;
    flex-grow: 1;
    height: 100%;
}

.systemPromptTitleEditor {
    margin-left: 10px;
    width: 100%;
}

.systemPromptTaskSelected {
    background: rgb(19, 35, 36);

}

.systemPromptDetails {
    padding-right: 15px;
    width: calc(100% - 15px);
    height: 100%;
}

.systemPromptToolset {
    height: 100%;
    max-height: 100%;
    width: 100%;
}

.systemPromptToolset .desktopToolsetDetailsTitle {
    display: none;
}

.systemPromptToolsetTitle {
    padding-right: 10px;
    padding-left: 10px;
    width: calc(100% - 20px);
}

.systemPromptToolsetTools {
    width: 100%;
    height: calc(100%);
    overflow-y: auto;
    row-gap: 4px;
    display: flex;
    flex-direction: column;
}

.benxtAppMobile .systemPromptToolsetTools {
    height: auto;
}

.systemPromptToolsetTool {
    width: 100%;
    display: flex;
}

.systemPromptToolsetTools .toolsetEditorBg {
    width: 100%;
    margin: 0;
}

.toolsetEditorLeft1 {
    width: 100%;
}

.systemPromptToolsetTopRow {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 32px;
    align-items: center;
}
.systemPromptEditorSectionRow2.sysPromptToolsetRow2 {
    flex-grow: 0;
    position: relative;
}

.systemPromptToolset {
}
