.modelsView {
    width: 100%;
}

.benxtAppMobile .modelsMenu {
    height: 100%;
}

.modelsMenuInline {
    position: relative;
    overflow-y: auto;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 0px;
    height: calc(100% - 12px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    width: calc(100%  - 20px);
}

.benxtAppMobile .modelsMenuInline {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
}

.modelsMenuInlinePreview {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
}

.modelsMenuInline .chatGptModelsMenu {
    margin-top: 0px;
    width: 100%;
}

.modelsMenuInline .chatGPTModelsMenuTitle {
    position: static;
}

.legacy {
    text-decoration: line-through;
}

.modelsMenuButtons {
    width: 100%;
}


.configurableModel .modelConfig {
    margin-top: 1px;
}

.configurableModel .modelConfig .tempSlider {
}

.doneButton {
    right: 0px;
    display: flex;
    column-gap: 1px;
}

.doneButton .simpleButton {
    background: rgb(10, 69, 97);
    min-width: auto;
}

.modelsMenuVendor {
}

.modelsMenuOptionsPrice {
}

.modelsMenuOptionsPrice .simpleCheckbox {
}

.model.modelSubmenu.modelPreview {
    padding-left: 0px;
    width: 100%;
}

.model.modelSubmenu.modelPreview .modelPrice {
    right: 32px;
}

.model.modelSubmenu.modelPreview .modelVision {
    right: 0px;
}

.configurableModel {
    width: 100%;
}

.inputPrice {
    color: rgb(209, 152, 19);
    font-size: 12px;
}

.modelsMenuModelsText {
    display: flex;
    align-items: center;
}

.desktopVendorItemsDiv {
    margin-top: 0px;
    opacity: 0;
    transition: opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1.0);
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    overflow-y: auto;
    height: 100%;
    width: 100%;
}

.desktopVendorItemsDivShow {
    opacity: 1;
}

.vendorItemsInline {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    margin-top: 2px;
}

.modelsMenuPreview .vendorItemsInline {
    width: 100%;
    max-height: calc(4 * 32px + 4 * 4px);
    row-gap: 4px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.modelsMenuVendorOnly .modelOptionsContainer {
    display: none;
}

.modelsMenuVendorOnly .vendorItemsInline {
    display: none;
}

.modelsMenuVendorOnly .modelsMenuButtons {
    display: none;
}

.modelsMenuNoSelect .modelOptionsTitle {
    display: none;
}
