
.simpleButtonConfigPopupContainer {
    background: rgb(12, 28, 28);
    filter: drop-shadow(-4px 4px 4px rgb(12, 28, 28, 0.8));
    min-width: 350px;
    transform: translate(-110px, -8px);
    padding-right: 20px;
}

.simpleButtonConfigPopup {
    margin-top: 1px;
    width: 100%;
}

#simpleButtonMenuTrigger {
    width: 32px;
    height: 32px;
    background: rgb(12, 28, 28)
}

#simpleButtonMenuTrigger .simpleIcon svg path {
    fill: #e7e7e7;
}

#simpleButtonMenuTrigger .simpleIcon svg circle {
    fill: #e7e7e7;
}


.simpleButtonConfigPopupMobile {
    position: fixed;
    width: calc(100vw - 32px);
    left: 20px;
    min-width: auto;
}
    
.simpleButtonConfigPopup .simpleButtonConfig  {
    width: 100%;
}

.simpleButtonWithConfig {
    display: flex;
    column-gap: 4px;
    align-items: center;
}

.simpleButtonWithConfig .simpleButtonWithLabel .simpleButtonLabel {
    padding-right: 0 !important;
}
