.keyboardHome {
    width: 100%;
    height: calc(100% - env(safe-area-inset-top));
    overflow-y: auto;
    margin-bottom: env(safe-area-inset-bottom)
}

.keyboardHomeContent {
    width: 100%;
    padding-bottom: 15px;
}

.homeDesktopContainer {
    height: 100%;
    width: 100%;
}

.homeButton {
    width: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 500;
}

.homeButtonLabels {
    font-weight: normal;
    display: flex;
    flex-direction: column;
    margin-bottom: 3px;
}

.homeButtonLabel {
    text-transform: uppercase;
    font-size: 12px;
    color: rgb(115, 153, 157);
}

.homeButtonSublabel {
    font-size: 12px;
    font-style: italic;
    color: #ff3d00;
}

.homeButtonButton {
    color: #e7e7e7;
    display: flex;
    width: 100%;
    height: 32px;
    background: rgb(0, 147, 132);
    align-items: center;
    font-size: 16px;
    cursor: pointer;
}
.homeButtonWithIcon .homeButtonButton {
    padding-left: 0px;
}

.homeButtonButtonLeft {
    display: flex;
    align-items: center;
}

.homeButtonButtonIcon {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.homeButtonButtonLabel {
    display: flex;
    align-items: center;
    justify-content: center;
}

.homeButtonButtonIcon div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.homeButtonButtonIcon svg {
    width: 32px;
    height: 32px;
    fill: #e7e7e7;
}

.keyboardHomeBottom {
    margin-top: 30px;
    width: 100%;
}

.keyboardHomeButtons {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

.keyboardHomeSavedButtons {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    row-gap: 1px;
    width: 100%;
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 500;
}

.keyboardHomeSavedButtonsList {
    display: flex;
    flex-direction: column;
    row-gap: 1px;
    width: 100%;
}

.savedButtonCategory {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.savedButtonCategoryMenu {
    display: flex;
    align-items: center;
    font-size: 16px;
    background: #64618c;
    cursor: pointer;
}

.savedButtonCategoryOpen .savedButtonCategoryMenu {
    font-weight: bold;
}

.savedButtonCategoryIcon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.savedButtonIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
}

.savedButtonIcon div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.savedButtonIcon svg {
    height: 32px;
    width: 32px;
}

.savedButton {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    background: rgb(93, 91, 132);
    width: calc(100% - 0px);
    column-gap: 1px;
}

.savedButtonCategoryButtons {
    display: flex;
    flex-direction: column;
    row-gap: 1px;
    margin-top: 1px;
}

.savedButtonLabel {
    padding-top: 10px;
    padding-bottom: 10px;
}

.savedButtonLeft {
    display: flex;
}

.savedButtonRight {
    column-gap: 1px;
    display: flex;
}

.savedButtonCategoryMenu .savedButtonIcon svg {
    height: 32px;
    width: 32px;
}

.savedButtonDelete .uiOKCancelCancel {
    background: rgb(95, 18, 10);
}

.savedButtonShare .uiOKCancelCancel {
    background: #3c65c9;
}

.savedButton .savedButtonIcon svg {
    fill: #e7e7e7;
}

.keyboardHomeHeader {
    display: flex;
    justify-content: space-between;
    height: 32px;
    align-items: center;
}

.keyboardHomeHeader .keyboardHeaderTitle {
    width: 100%;
    display: flex;
    justify-content: center;
}

.keyboardHomeTop {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.keyboardHomeShape {
    pointer-events: none;
    position: relative;
    width: calc(100% - 20px);
    overflow: hidden;
}

.keyboardHomeShape svg {
    width: 100%;
}

.keyboardHomeStats {
    top:0 ;
    width: 100%;
    height: 100%;
    position: absolute;
}

.keyboardHomeStat {
    position: absolute;
    display: flex;
    flex-direction: column;
}

.keyboardHomeStatLabel {
    font-weight: bold;
    text-transform: uppercase;
    color: #e5e0bf;
    font-size: 11px;
}

.keyboardHomeStatValue {
    color: #e7e7e7;
    font-size: 42px;
    font-weight: 500;
}

.statAvailable {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    transform: translate(36.5%, -7%);
}

.statAvailable .keyboardHomeStatLabel {
    font-size: 16px;
}

.statUsed {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    transform: translate(15%, 0%);
}

.statPurchased {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    transform: translate(15%, 30%);
}

.openAIStatusDescriptionOpenAI {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 550;
    fill: #009384;
    text-anchor: middle;
    transform: translate(0, -18px);
}

.openAIStatusDescription {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 550;
    fill: #009384;
    text-anchor: middle;
}

.openAIStatusNone .openAIStatusDescription {
    fill: rgb(0, 147, 132);;
}

.openAIStatusNone .openAIStatusDescriptionOpenAI {
    fill: rgb(0, 147, 132);;
}

.benxtAppMobile .openAIStatusDescription {
}

.statPurchased .keyboardHomeStatLabel {
    font-size: 11px;
}

.statPurchased .keyboardHomeStatValue {
    font-size: 22px;
}

.statUsed .keyboardHomeStatLabel {
    font-size: 11px;
}

.statUsed .keyboardHomeStatValue {
    font-size: 22px;
}

.keyboardHomeAccountSpacer0 {
    margin-bottom: 16px;
}

.keyboardHomeAccountSpacer1 {
    height: 30px;
}

.keyboardHomeAccountSpacer2 {
    height: 30px;
}

.keyboardHomeAccountDelete {
    width: 100%;
}

.keyboardHomeAccountDelete .homeButtonButton {
    background: #63130a;
    width: 100%;
}


.homeButtonButtonRight .simpleIcon {
    min-width: 32px;
    min-height: 32px;
}

.keyboardHomeAccountDeleteConfirm .homeButtonButton {
    background: rgb(184, 38, 26);
}

.keyboardHomePhone {
    width: 100%;
    display: flex;
    row-gap: 1px;
    flex-direction: column;
    align-items: flex-end;
}

.keyboardHomePhone .inputControlKeyboardButton .uiOKCancelOK {
    margin-right: 10px;
}

.keyboardHomeWordPacksLowIcon svg {
    height: 32px;
    width: 32px;
}

.keyboardHomeWordPacksLow {
    width: calc(100% - 40px);
    margin-left: 10px;
    margin-right: 10px;
    background: rgb(53, 91, 180);
    display: flex;
    align-items: center;
    padding: 5px;
}

.heroMaskLeft {
    fill: #086461;
    opacity: .92;
}

.heroMaskMiddle {
    fill: #086461;
    opacity: .92;
}

.keyboardHomeWordPacksLowStatus .heroMaskMiddle {
    fill: rgb(53, 91, 180);
}


.heroMaskRight {
    fill: #95e2c2;
}

.wordPackBuyButtonLow .homeButtonButton {
    background: rgb(53, 91, 180);
}

.keyboardHomeWordPacksLowIcon {
    display: flex;
    max-height: 32px;
    width: 32px;
    justify-content: center;
    align-items: center;
}

.keyboardHomeWordPacksLowIcon div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.keyboardHomeWordPacksLowMessage {
    padding-left: 10px;
    display: flex;
}

.openAIStatusMajor {
    fill: rgb(99, 19, 9);
}

.openAIStatusMinor {
    fill: rgb(205, 87, 60);
}
.openAIStatusMinor text {
    fill: #e7e7e7;
}

.openAIStatusMajor text {
    fill: #e7e7e7;
}

.keyboardHomeKeyboardActivate {
    background: rgb(53, 91, 180);
    display: flex;
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 10px;
    position: relative;
}

.keyboardHomeKeyboardActivate img {
    width: calc(100% - 50px);
    padding-bottom: 10px;
    padding-right: 10px;
}

.keyboardHomeKeyboardActivate  .keyboardHomeWordPacksLowIcon {
    align-items: flex-start;
    padding: 10px;
    padding-top: 0px;
}

.keyboardHomeKeyboardActivateBlurb {
    position: absolute;
    right: 5px;
    top: 10px;
    width: 45%;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: center;
}

.keyboardPage {
    width: 100%;
    height: cacl(100% - 32px);
    margin-top: 32px;
}
    
.savedButtonTabsContainer {
    display: flex;
    flex-direction: column;
}

.savedButtonTabs {
    display: flex;
    width: 100%;
    background: #64618c;
    margin-bottom: 1px;
}

.savedButtonTab {
    display: flex;
    width: 50%;
    height: 32px;
    align-items: center;
    background: rgb(39, 52, 69);
    cursor: pointer;
}

.savedButtonTabSelected {
    background: #64618c;
}

.savedButtonTabIcon  {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.savedButtonTabIcon  div {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.savedButtonTabIcon svg {
    height: 32px;
    width: 32px;
}

.savedButtonTabLabel {
    height: 100%;
    display: flex;
    align-items: center;
}

.wordPackBuyButton {
}

.benxtAppNative .wordPackBuyButton {
    display: flex;
}

::-webkit-scrollbar {
    display: none;
}

.inputReferralCode .homeButtonSublabel {
    color: #e7e7e7;
}

.keyboardHome input:disabled {
}

.homeButtonRight {
    display: flex;
    justify-content: center;
    width: 100%;
}

.homeButtonRightBusy {
    margin-top: 20px;
}

.homeButtonRight svg {
    height: 182px;
    width: 182px;
}

.intellikeyLinks {
    display: flex;
    justify-content: flex-end;
    width: calc(100% - 10px);
    display: flex;
    column-gap: 8px;
    margin-top: -45px;
    margin-bottom: 32px;
}

.benxtAppMobile .intellikeyLinks {
    margin-top: calc(-7.5vw);
    column-gap: 1px;
}

.benxtAppAssistant .intellikeyLinks {
    display: none;
}

.benxtAppMobile .intellikeyLinks svg {
    height: calc(6.5vw);
}

.intellikeyLinks svg {
    height: 32px;
    width: 32px;
    cursor: pointer;
}

.accountKeyboardSettings {
    width: calc(100% - 20px);
    margin-left: 10px;
}

.accountKeyboardSettingsCheckboxes {
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    width: 100%;
}


.keyboardCheckbox {
    width: 100%;
    display: flex;
    height: 32px;
    column-gap: 1px;
}

.keyboardCheckboxLabel {
    height: 32px;
    flex-grow: 1;
    color: #e7e7e7;
    background: rgb(7, 100, 97);
    display: flex;
    align-items: center;
    padding-left: 20px;
}

.keyboardCheckboxLeft {
    height: 32px;
    min-width: 32px;
    background: rgb(1, 154, 158);
}

.keyboardCheckboxRight {
    height: 32px;
    width: 32px;
    background: rgb(7, 100, 97);
}

.keyboardCheckboxOn {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    color: #e7e7e7;
    background: rgb(59, 101, 201);
    width: 80px;
    cursor: pointer;
}

.keyboardCheckboxOff {
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #e7e7e7;
    background: rgb(12, 28, 28);
    height: 32px;
    width: 80px;
}

.keyboardCheckboxUnselected {
    display: flex;
    column-gap: 1px;
}

.keyboardCheckboxSelected {
    display: flex;
    column-gap: 1px;
}

.keyboardCheckboxOffButton {
    height: 32px;
    width: 32px;
    background: rgb(99, 19, 9);
}

.apiSpec {
    margin-left: 10px;
    margin-top: 15px;
    width: calc(100% - 20px);
}

.apiSpecTitle {
    text-transform: uppercase;
    font-size: 12px;
    color: rgb(115, 153, 157);
}

.apiSpecUrl {
    background: gray;
    height: 45px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 5px;
}

.gpt {
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
}
.gpt > div {
    display: flex;
    height: 100%;
    width: 100%;
}

.gpt webview {
    margin-top: 0px;
    height: calc(100% - 0px);
    width: 100%;
}

.gpt webview a {
    display: inline-block !important;
    visibility: visible !important;
    background: red;
    min-width: 100px;
    min-height: 100px;
}

.gptPage .bnBackButton {
    position: absolute;
    right: 5px;
}

.gptBack {
    position: absolute;
    top: 2.5px;
    left: calc(100% - 45px);
    max-height: 32px;
}

.homeButton .keyboardRadioButtonOn {
    min-width: 100px;
}

.homeButton .keyboardRadioButtonOff {
    min-width: 100px;
}

.homeGPT .keyboardHomeStats {
    display: none;
}

.attunewiseHomeButtons {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding-top: 0px;
    height: 100%;
    overflow-y: auto;
    background: rgb(0, 10, 5);
}


.benxtAppMobile .attunewiseHomeButtons {
    padding-top: 10px;
}

.benxtAppDesktop .attunewiseHomeButtons {
    left: 0;
}

.benxtAppNative .attunewiseHomeButtons {
    height: calc(100% - 65px);
}


.attunewiseUsageCal {
    position: relative;
    margin-top: 0px;
    color: #e7e7e7;
    padding-left: 10px;
    padding-right: 10px;
    width: calc(100% - 20px);
    background: rgb(12, 28, 28);
}

.attunewiseUsageCal .usageCalendar {

    padding-bottom: 10px;
}

.attunewiseUsage {
    width: 100%;
    margin-top: 2px;
    row-gap: 4px;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    padding-bottom: 10px;
    flex-grow: 1;
}

.attunewiseUsage .modelCategoryButtons {
    row-gap: 3px;
    margin-top: 3px;
}

.attunewiseUsagePreview {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    row-gap: 4px;
    max-height: 215px;
    overflow-y: hidden;
}


.attunewiseTotalUsageLabel {
    font-weight: 550;
}

.attunewiseTotalUsage {
    margin-top: 1px;
    background: rgb(10, 69, 87);
    min-height: 32px;
    height: 32px;
    width: 100%;
    color: #e7e7e7;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 10px;
    position: relative;
}
.attunewiseTotalUsage .modelPrice {
    right: 32px;
}

.attunewiseTotalUsageValue {
    display: flex;
    align-items: center;
}

.attunewiseUsageView {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.benxtAppNative.benxtAppMobile .attunewiseUsageView {
    padding-bottom: 10px;
    height: calc(100% - env(safe-area-inset-top));
}

.benxtAppMobile .attunewiseUsageView .attunewiseUsageCal {
    padding: 0;
    width: 100%;
}

.benxtAppMobile .attunewiseUsageView .attunewiseUsage {
    padding: 0;
    width: 100%;
}


.settingsInfoHeader {
    font-size: 12px;
    color: #e7e7e7;
    padding: 10px;
    box-sizing: border-box;
    background: rgb(12, 28, 28);
    margin-top:4px;
    min-height: 68px;
}


.settingsSectionHeader {
    font-size: 16px;
    color: #e7e7e7;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.providerTopRow .simpleButton .simpleIcon svg path {
    fill: rgb(0, 255, 0);
}

.homeFooterMargin {
    height: 15px;
    width: 100%;
}

.providerTopRow {
    width: calc(100% - 0px);
    display: flex;
    justify-content: space-between;
    margin-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
}


.providerTopRow .simpleIcon {
}

.providerTopRow0 {
    margin-top: 0px;
}

.providerTopRow .simpleButton {
    min-width: 32px;
}

.providerView {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    row-gap: 1px;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
}

.importedModel {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: rgb(12, 28, 28);
}

.benxtAppDesktop .importedModelSelected {
    background: rgb(10, 69, 97);
}


.importedModel .modelVision {
    position: static;
}

.importedModelTopRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.importedModelBottomRow {
    width: 100%;
    padding-left: 32px;
    padding-bottom: 5px;
}


.importedModelTopRowLeft {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 550;
}

.importedModel .modelVersionId {
    position: static;
}

.providerViewModels {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
}


.importedModelButtons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.simpleHomeButton .simpleButton {
    background: rgb(1, 79, 75);
    margin-right: 10px;
    width: 120px;
}

.customProvidersList {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    padding-top: 4px;
    background: rgb(0, 10, 5);
}

.customProviderSpacer1 {
    height: 10px;
    background: rgb(12, 28, 28);
}

.customProviderButtons {
    display: flex;
    margin-right: 10px;
}

.customProviderButtons .threadDeleteButton {
    background: rgb(95, 18, 10);
}

.customProviderButtons  .inputControlKeyboardButton .uiOKCancelOK {
    margin-right: 1px;
}
                                                                          
.customProviderTopRow {
    padding-left: 0;
    width: calc(100% - 10px);
}

.providerViewTopLine {
    width: 100%;
    display: flex;
    background: rgb(12, 28, 28);
}

.providerHostsSelection {
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    width: 100%;
}


.modelsTopLevel {
    width: 100%;
    height: 100%;
}

.modelsTopLevel .chatGPTModelsMenuTitle {
    display: none;
}

.modelsTopLevel .chatGPTModelsMenuTitleNormal {
    display: none;
}

.copyButton {
    justify-content: flex-end;
    display: flex;
}

.copyButton .simpleButton {
    background: #3c65c9;
}


.keyboardHomeTop {
    display: none;
}

.keyboardHomeHeader {
    display: none;
}


.homeCard {
    width: calc(100% - 20px);
    background: rgb(4, 17, 17);
    box-sizing: border-box;
    margin-left: 0px;
    margin-right: 0px;
    border-radius: 5px;
}


.benxtAppDesktop .homeCard {
    width: 100%;
    max-width: none;
}

.benxtAppMobile .homeCard {
    width: 100%;
}


.homeCardTitleContainer {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    padding-top: 0px;
}

.homeCardEmpty {
    height: 32px;
}

.homeCardEmpty not(.homeCardButton) .homeCardTitleContainer {
    padding-bottom: 0;
    padding-right: 0;
}

.homeCardButton .homeCardTitleContainer {
    padding-bottom: 0;
}

.homeCardTitleBar {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.homeCardTitle {
    color: #e7e7e7;
    font-weight: 500;
}

.homeCardTitleBar .simpleButton .simpleIcon svg path {
    fill: rgb(0, 255, 0) !important ;
}

.homeCardContent {
    width: 100%;
    height: fit-content;
    overflow-x: hidden;
}


.homeButtonLabel {
    display: none;
}

.homeButtonButton {
    background: rgb(10, 69, 87);
    padding-left: 10px;
    width: calc(100% - 20px);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    padding-right: 10px;
    width: 100%;
    padding: 0;
}

.benxtAppDesktop .homeButtonButton {
}

.homeButtonButton .simpleIcon svg path {
    fill: #e7e7e7 !important ;
}

.homeButtonButtonIcon {

}

.keyboardHeader .inputControlKeyboardButton1 .uiOKCancelCancel {
    background: transparent;
}

.keyboardHeader .inputControlKeyboardButton1 .uiOKCancelCancelIcon svg path {
    fill: !important ;
}

.inferenceRecent {
    display: flex;
    flex-direction: column;
    row-gap: 3px;
}


.recentUsage {
    width: 100%;
    height: auto;
}



.recentUsage .bnContentPage {
    height: auto;
}

.recentUsage .bnPageBackground {
    height: auto;
}

.recentUsage .bnPageCamera {
    height: auto;
}

.recentUsage .bnPageContent {
    height: auto;
    display: block;
    position: static;
}

.recentUsage .bnPageContentSidebarContainer {
    width: auto;
}

.recentUsage .bnPageContentSidebar {
   width: auto;
}

.recentUsage .bnPageScroller {
    height: auto;
}

.recentUsage .bnPageBody {
    height: auto;
}

.recentUsage .bnContentPageBackground {
    height: auto;
}

.recentUsage .bnContentArea {
    height: auto;
}

.recentUsage .bnPage {
    height: auto;
}

.recentUsage .attunewiseUsageView {
    height: auto;
}

.recentUsage .bnPageContentWithDetail {
    height: auto;
}

.recentUsage .desktopVendorItemsDiv {
    display: none;
}

.datasetPreview {
    display: flex;
    width: 100%;
    background: rgb(12, 28, 28);
    flex-direction: column;
    cursor: pointer;
}

.datasetPreviewRow1 {
    display: flex;
    width: 100%;
    align-items: center;
    background: rgb(12, 28, 28);
    justify-content: space-between;
    height: 32px;
}

.datasetPreviewRow2 {
    padding-left: 32px;
    font-size: 13px;
    padding-bottom: 5px;
}

.datasetPreviewLeft {
    display: flex;
    align-items: center;
}

.datasetPreviewLeft .simpleIcon {
    min-width: 32px;
}

.datasetPreviewRight {
    display: flex;
    align-items: center;
    padding-right: 15px;
}

.datasetsRecent {
    display: flex;
    width: 100%;
    flex-direction: column;
    row-gap: 3px;
    margin-bottom: 3px
}

.recentFineTuningJobs {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 3px;
}

.fineTuningJobPreview {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    background: rgb(12, 28, 28);
    cursor: pointer;
}

.fineTuningJobPreviewTop {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.fineTuningJobPreviewBottom {
    font-size: 12px;
    color: #d0d0d0;
    padding-left: 32px;
    padding-bottom: 5px;
}

.fineTuningJobPreview-succeeded .fineTuningJobPreviewLeft svg path {
    fill: rgb(0, 255, 0);
}

.fineTuningJobPreview-cancelled .fineTuningJobPreviewLeft svg path {
    fill: rgb(255, 104, 18);
}

.fineTuningJobPreview-running .fineTuningJobPreviewLeft svg path{
    fill: rgb(255, 255, 0);
} 

.fineTuningJobPreview-failed .fineTuningJobPreviewLeft svg path {
    fill: rgb(202, 6, 8);
}

.fineTuningJobPreviewLeft {
    display: flex;
    flex-direction: column;
}

.fineTuningJobPreviewLeft .projectModelRow .simpleIcon {
    min-height: 32px;
    display: none;
}

.fineTuningJobPreview .fineTuningJobPreviewDataset .simpleIcon {
    min-width: 32px;
    min-height: 32px;
}

.fineTuningJobPreviewLeft .projectModelRow {
    opacity: 0.7;
    margin-left: 32px;
    margin-top: -15px;
}

.fineTuningJobPreviewRight {
}

.fineTuningJobPreviewRightContent {
    height: 32px;
    display: flex;
    align-items: center;
    padding-right: 15px;
    position: relative;
}

.fineTuningJobPreviewDataset {
    display: flex;
    align-items: center;
    position: relative;
}


.fineTuningJobPreviewRight .modelPrice {
    right: 70px;
}

.bigButton {
    margin-top: 1px;
    width: 100%;
}

.bigButton .simpleButton {
    background: rgb(1, 29, 39);
    width: 100%;
    justify-content: center;
}

.creditsUsed {
    min-height: 100%;
    background: rgb(248, 171, 15);
}

.creditsAvailable {
    min-height: 100%;
    background: rgb(37, 219, 23);
}

.creditsLeft {
    width: 50%;
    min-height: 100%;
    display: flex;
    box-sizing: border-box;
    background: rgb(0, 26, 25);

}

.creditsRight {
    width: 50%;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
}

.creditsPreview {
    width: 100%;
    display: flex;
    column-gap: 10px;
}

.creditsInfo {
    display: flex;
    justify-content: space-between;
    background: rgb(12, 28, 28);
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
}

.creditsInfoValue {
    font-size: 16px;
}

.availableCredits .creditsInfoValue {
    font-weight: 550;
    color: rgb(37, 219, 23);
}

.usedCredits .creditsInfoValue {
    color: rgb(248, 171, 15);
}

.systemPromptsPreview {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
}

.systemPromptPreview {
    background: rgb(12, 28, 28);
    cursor: pointer;
}

.systemPromptPreview .systemPromptDate {
    margin-right: 0px; 
}

.recentUsage .systemPromptTopRowRight {
    padding-right: 20px;
}

.keyboardOof .homeButton {
    width: calc(100% - 10px);
}

.fineTuningJobETA {
    position: absolute;
    right: 10px;
    bottom: 10px;
}

.popup-content .editMenuTop {
    transform: translate(0, 10px);
}


.desktopSubpage {
    position: absolute;
    left: 360px;
    width: calc(100% - 360px);
    height: 100%;
}

.homeDesktop {
    height: 100%;
    width: 100%;
    display: flex;
    color: #e7e7e7;
    padding-right: 0px;

}

.homeDesktop .chatGPT {

}

.homeDesktop .attunewiseLayer  {

}

.homeMobile {
    height: 100%;
    width: 100%;
    color: #e7e7e7;
}

.homeDesktopSidebar {
    height: 100%;
    width: 430px;
    overflow-y: auto;
    min-width: 430px;
    padding-right: 10px;
    padding-left: 10px;
    background: rgb(4, 17, 17);
    overflow-x: hidden;
}

.homeDesktopSidebar .fineTunedModelsPage {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
}

.homeDesktopSidebar .attunewiseUsage {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
}

.homeDesktopSidebar .chatGptToolsetsMenu {
    width: 100%;
    padding: 0;
}

.homeDesktopSidebar .chatGptThreadsMenu-all {
    height: calc(100% - 100px - env(safe-area-inset-bottom));
}

.homeDesktopCardLayout {
    height: 100%;
    width: 1200px;
}

.homeDesktopCardLayout {
    height: 100%;
    width: 1200px;
}

.homeDesktopCardLayout .homeCard {
   /*  max-width: 400px; */
}


.cards3Cols {
    display: flex;
    column-gap: 10px;
    padding: 10px;
}

.cardCol {
    display: flex;
    flex-direction: column;
    width: 33%;
    row-gap: 10px;
}


.homeDesktopMain {
    height: 100%;
    width: 800px;
    padding: 0px;
    border-radius: 5px;
    background: rgb(4, 17, 17);
    box-sizing: border-box;
    padding-bottom: 0px;
}

.homeDesktopMainVisible {
    opacity: 1
}

.homeDesktopMainWide {
    width: 800px;
}

.homeDesktopMain .attunewiseLayer {
    background: transparent;
}

.homeDesktopMain .attunewiseLayer {
    background: transparent;
}

.homeDesktopMain .fineTunedModelsPage {
    background: transparent;
}

.homeDesktopMain .attunewiseUsageCal {
    background: transparent;
}

.homeDesktopMain .attunewiseUsage {
    background: transparent;
}

.benxtAppDesktop .keyboardHomeContent {
}


.datasetsRightFolderTitle {
    height: 0px;
    display: flex;
    width: 100%;
    color: #e7e7e7;
    min-height: 0px
}

.datasetsRight {
    display: flex;
    width: 100%;
    height: calc(100%);
    display: flex;
    flex-direction: column;
}

.desktopCalendar {
    margin-top: 0px;
    width: 100%;
}

.accountInfoTitle {
    width: 100%;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.accountInfoDesktop {
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
}

.topLevelModelsView {
    width: 100%;
    height: 100%;
}

.topLevelModelsView .modelsView {
    height: auto
}

.topLevelModelSettings {
    width: 100%;
    padding-left: 0px;
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    padding-top: 32px;
}

.topLevelModelSettings .homeButton {
    margin: 0;
    padding: 0;
    width: 100%;
}

.topLevelModelSettings .settingsSectionHeader {
    padding-bottom: 0px;
    padding-top: 0px;
    display: flex;
    align-items: center;
}


.benxtAppMobile .topLevelModelSettings{
    width: 100%;
    padding-left: 0;
}

.customProviderButtons {
    margin-right: 0;
}

.topLevelModel {
    width: 100%;
    height: 100%;
    min-height: 100%;
    overflow-y: auto;
}

.topLevelModel .customProviderButtons {
    margin-top: 15px;
}

.topLevelModel .keyboardHomePhone {
    background: rgb(12, 28, 28);
    padding-bottom: 10px;
}

.topLevelModel .modelsMenuInline {
    height: auto;
    width: 100%;
    margin: 0;
}

.topLevelModel .chatGPTModelsMenuTitleNormal {
    display: none;
}
    

.homeDesktop .lossGraphContainerStyle {
    height: 100%;
    margin-top: 0px;
    width: 100%;
    padding: 0;
}

.desktopCalendarView {
    padding-top: 0px;
    width: 100%;
    height: 100%;
}

.bnPageContentSidebar .attunewiseUsage {
    padding: 0;
    height: 100%;
}


.modelsPreviewScroller {
    width: calc(100%);
    height: 100%;
}

.homeButtonSignOut {
    margin-top: 15px;
}
    

.selectedCard  {
    width: 100%;
    height: 100%;
}

.selectedCard .homeCard {
    width: 100%;
    height: 100%;
    width: none;
}

.details {
    width: 100%;
    height: 100%;
}

.detailsFadeIn {
    animation: fadeIn 0.4s cubic-bezier(0.25, 0.1, 0.25, 1.0);
}

@keyframes fadeIn {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}


.homeDesktopPage {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

.toolsPreview {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
}

.toolsetPreview {
    background: rgb(12, 28, 28);
    cursor: pointer;
}

div.keyboardAppContent > div > div > div > div > div.bnPageCamera > div {
    background: transparent;
}


.fineTuningJobBody {
    display: flex;
    flex-direction: column;
    padding-left: 32px;
    width: calc(100% - 32px);
}

.fineTuningJobBodyRow1 {
    width: calc(100% - 32px);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.fineTuningJobBodyRow2 {
    width: calc(100% - 32px);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.fineTuningJobBodyRow3 {
    width: calc(100% - 0px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    display: none;
}

.fineTuningJobBodyButtonRow {
    margin-top: 8px;
    display: flex;
}

.fineTuningJobBodyButtonRow .simpleButton {
    background: rgb(23, 68, 66);
    min-width: 120px;
}

.fineTuningJobBodyModelPrice {
    color: rgb(209, 152, 19);
}


.host {
    cursor: pointer;
}

.hostSelected {
    background: rgb(60, 101, 201);
    cursor: pointer;
}

.toolsPreview .markdownContainer {
    padding-left: 20px;
}


.splitView {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.splitViewTop {
    width: 100%;
}

.splitViewBottom {
    width: 100%;
    height: auto;
    min-height: 0;
    display: flex;
    flex-grow: 1;
}

.splitViewSidebar {
    min-width: calc(min(100vw, 435px));
    max-width: calc(min(100vw, 435px));
    height: 100%;
}

.splitViewDetail {
    width: calc(100% - 425px);
    height: 100%;
}

.homeDesktopContainer {
    width: 100%;
    height: calc(100% - 80px);
}

