.bnAppContentSearchGpt .attunewiseHeader {
    background: black;
    color: white;
}

.bnAppContentSearchGpt > .attunewiseHeader .attunewiseHeaderBottomRowLeft {
    visibility: hidden;
}

.bnAppContentSearchGpt .attunewiseHeaderBottomRowRight .simpleButton .simpleIcon svg path {
    fill: white !important;
}
