.inputControlKeyboardButton1 .SVG-Nano-001 {
    height: 20px !important;
    width: 20px !important;
}

.inputControlSpeechLangIcon .SVG-Nano-001 {
    height: 20px !important;
    width: 20px !important;
}

.keyboardWindow {
    background: rgb(0, 26, 25);
    color: #e7e7e7;
    height: 100%;
    width: 100%;
}

.keyboardWindowInactive {
    height: calc(100% - env(safe-area-inset-top));
    padding-bottom: 0;
}

.keyboard {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 15px;
    width: calc(100% - 10px);
    min-height: calc(100% - 10px);
    padding: 5px;
}


.keyboardHeader {
    display: flex;
    justify-content: space-between;
    height: 32px;
    align-items: center;
    margin-bottom: 15px;
}

.keyboardHeaderButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;

    cursor: pointer;
    background: rgb(51, 51, 51);
}

.keyboardHeaderButton.keyboardHeaderButtonBack {
}

.keyboardHeaderButton.keyboardHeaderButtonCancel {
    background: rgb(99, 19, 9);
}

.keyboardHeaderButtonUndo svg {
    height: 32px;
    width: 32px;
}

.keyboardHeaderButtonRedo svg {
    height: 32px;
    width: 32px;
}

.keyboardHeaderButtonCancel svg {
    height: 32px;
    width: 32px;
    fill: #e7e7e7;
}

.keyboardHeaderButton div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.keyboardHeaderButton svg {
    height: 32px;
    width: 32px;
}
    

.keyboardHeaderButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
}

.keyboardHeaderButton div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.keyboardHeaderTitle {
    display: flex;
    align-items: center;
    column-gap: 5px;
    color: #e7e7e7;
}

.keyboardHeaderTitle img {
    height: 32px;
    width: 32px;
    display: none;
}

.keyboardHeaderTitleText {
    font-weight: 500;
    font-size: 16px;
}

.keyboardSendButtonContainer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    background: rgb(227, 227, 227);
    column-gap: 5px;
    padding-right: 15px;
    position: relative;
}


.keyboard .uiChatInput {
    white-space: pre-wrap;
}

.keyboardOriginalContainer {
    row-gap: 0px;
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 3;
    width: calc(100% - 10px);
}

.keyboardInstructionList {
    display: flex;
    flex-direction: column;
    max-height: 25vh;
    overflow-y: auto;
    row-gap: 6px;
    margin-bottom: 6px;
    width: 100%;
    min-height: 35px;
}

.keyboardInstructionListStreaming {
    min-height: calc(100vh - 215px);
}

.keyboardOriginal {
    padding: 20px;
    color: gray;
    font-style: italic;
    width: calc(100% - 50px);
    white-space: pre-wrap;
    height: calc(100% - 50px);
}

.keyboardEditing {
    white-space: pre-wrap;
    width: 100%;
}

.keyboardEditing .selectedText {
    color: #e7e7e7;
}

.precedingText {
    color: #e7e7e7;
}

.followingText {
    color: #e7e7e7
}

.keyboarEditDocument.selectedText {
    color: rgb(227, 227, 227);

}

.uiChatEditorContainer {
    
}

.uiChatInput {
    max-height: 45vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.keyboardCancel {
    background:  rgb(184, 38, 26);
    height: 50px;
    cursor: pointer;
    color: rgb(250, 250, 250);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    font-size: 16px;
    cursor: pointer;
    padding-left: 2px;
    padding-right: 20px;
}

.keyboardCancel:hover {
   background: #CA0000;

}

.keyboardEdit {
    color: rgb(120, 120, 120);
}

.keyboardEdit .uiOKCancel {
    transform: translate(0px, 1px);
}

.keyboardEditDocumentTop {
    width: 100%;
    margin-bottom: 6px;
}

.keyboardEditDocument {
    position: relative;
    min-height: 32px;
    margin-top: 6px;
    background: rgb(2, 77, 87);
    color: #e7e7e7;
    width: 100%;
    display: flex;
    overflow-x: hidden;
}

.keyboardEditDocumentText {
    padding-top: 10px;
    padding-bottom: 10px;
    width: calc(100% - 50px);
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box;    
    box-sizing: border-box;
    white-space: pre-wrap;
    max-height: 100%;
}

.keyboardEditDocumentTextStreaming {
}

.keyboardEditDocumentIcon {
    width: 50px;
    height: 50px;
}

.keyboardEdit {
    display: flex;
    flex-direction: column;

}

.keyboardEditInstruction {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    background: rgb(0, 44, 43);
    color: #e7e7e7;
    position: relative;
    width: 100%;
}

.keyboardEdit .keyboardOof {
    margin-top: 6px;
}

.keyboardEdit .keyboardOof .homeButton {
    margin: 0;
    width: 100%;
}

.keyboardEditInstructionMode {
    width: 120px;
    display: flex;
    justify-content: center;
    background: rgb(0, 26, 25);
}
.keyboardEditInstructionModeSuccinct {
    width: 32px;
}

.keyboardEditInstructionSpin {
    width: 32px;
}

.keyboardEditInstructionSpin div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
}

.keyboardEditInstructionSpin .SVG-Nano-001 {
    height: 20px !important;
    width: 20px !important;
}

.keyboardEditInstructionMode .inputControlKeyboardButton .uiOKCancelOK {
    background: rgb(50, 47, 103);
}

.editSpan {
    width: 20px;
    display: inline-block;
    position: relative;
}

.editSpan div {
    height: 32px;
    width: 32px;
    position: absolute;
    top: -13px;
    left: -3px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.editSpan svg {
    background: rgb(49, 47, 102, 0);
    height: 32px;
    width: 32px;
}

.keyboardInstructionBlurb {
    pointer-events: none;
    color: rgb(227, 227, 227);
    display: inline-block;
    width: calc(100% - 20px);
    padding: 10px;
    padding-top: 5px;
    padding-bottom: 15px;
    margin-left: 50px;
    width: calc(100% - 70px);
    vertical-align: middle;
}


.keyboardEditInstructionText {
    padding-top: 7px;
    padding-bottom: 10px;
    width: 100%;
    overflow: hidden;
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box;    
    box-sizing: border-box;
    white-space: pre-wrap;
    padding-right: 5px;
}

.keyboardEditInstructionMenuLabel {
    color: rgb(9, 90, 90);
}

.keyboardEditInstructionIcon {
    height: 50px;
    fill: #BBBBBB;
    display: flex;
    align-items: center;

}

.keyboardEdit svg {
    height: 50px;
    width: 50px;
    background: 
}

.keyboardMenuAutocompletes {
    width: 100%;
    position: absolute;
    left: 0px;
    z-index: 88;
    top: 86px;
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    overflow-y: auto;
    background: rgb(0, 26, 25);
    filter: drop-shadow(0px 0px 4px rgb(0, 0, 0, 0,2));
}

.keyboardMenuAutocompletesFull {
}

.keyboardMenuAutocompletes .keyboardMenuItem {
    background: rgb(0, 44, 43);
}

.keyboardMenuItem {
    cursor: pointer;
    display: flex;
    background: rgb(49, 47, 102);
    color: #e7e7e7;
    font-size: 15px;
    width: 100%;
}

.keyboardMenuItemIcon {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 32px;
    height: 100%;
}

.keyboardMenuItemIcon div {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.keyboardMenuItemIcon svg {
    height: 32px;
    width: 32px;
    fill: #e7e7e7;
}

.keyboardMenuItemCategory {
    position: relative;
}

.keyboardMenuItemCategory svg {
    right: 5px;
    top: 10px;
    height: 32px;
    width: 32px;
    fill: #e7e7e7;
}

.keyboardMenuItemLabel {
    font-weight: normal;
    color: rgb(225, 225, 225);
    padding-top: 5px;
    padding-bottom: 10px;
    padding-right: 5px;
    overflow: hidden;
}

.keyboardMenuItemBack {
    justify-content: flex-start;
}

.keyboardMenuItemBack .keyboardMenuItemLabel {
    font-weight: bold;
}

.keyboardMenuItemBack div {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.keyboardMenuItemBack svg {
    height: 32px;
    width: 32px;
    fill: #e7e7e7;

}

.keyboardMenuItem:hover {
    opacity: 100%;
}


.keyboardMenuContainer {
    position: absolute;
    top: 0;
    height: 100%;
    width: calc(100% - env(safe-area-inset-left) - env(safe-area-inset-right));
    left: env(safe-area-inset-left);
    display: flex;
}

.keyboardMenuContainer {
    position: fixed;
    z-index: 10;
}

.keyboardMenu {
    z-index: 10;
    position: absolute;
    display: flex;
    flex-direction: column;
    padding-right: 0;
    width: calc(100% - 10px);
    left: auto !important;
    right: 5px;
    row-gap: 2px;
    background: rgb(0, 26, 25);
    padding-top: 2px;
    overflow-y: auto;
}

.benxtAppDesktop .keyboardMenu {
    right: 5px;
    max-width: 590px;
    transform: translate(calc((100vw - 600px)/-2 + 0px), 0);
}


.keyboardMenu::-webkit-scrollbar {
    background: transparent;
    width: 0px;
}


.keyboardAddButton {
    cursor: pointer;
    background: rgb(49, 47, 102);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 32px;
    width: 32px;
    right: 0px;
}

.keyboardAddButton  div  {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.keyboardAddButton svg {
    fill: #e7e7e7;
    height: 32px;
    width: 32px;
}

.bbTimelinePopupMenu:hover .bbAddButton {
    background: black;
    cursor: pointer;
}

.keyboardEditInstructionIcon {
    display: flex;
    align-items: center;
}

.keyboardEditInstructionIcon.keyboardEditInstructionIconEdit {
    background: rgb(0, 44, 43);
}

.aiEditIcon svg path {
    fill: rgb(9, 90, 9) !important;
}

.keyboardInstructionInput {
    height: 50px;
    width: calc(100% - 50px);
    display: flex;
    bottom: 0;
}

.keyboardInstructionInput textarea:focus {
    outline: none;
}

.keyboardInstructionInput textarea {
    padding: 10px;
    margin: 0;
    resize: none;
    font-size: 16px;
    border: 0;
    outline: none;
    display: flex;
    max-width: auto;
    color: #333333;
    min-width: 100%;
    font-family:  OpenSans, Arial, Helvetica, sans-serif;
    background: rgb(227, 227, 227);
    width: calc(100% - 20px);
    height: calc(100% - 20px);

}

.keyboardServerError {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(184, 38, 26);
    color: #e7e7e7;
    cursor: pointer;
}

.uiKeyboardOriginalContainer {
    width: 100%;
}

.keyboardInputContainer {
    display: flex;
    flex-direction: column;
    left: 5px;
    position: absolute;
    bottom: 0;
    width: calc(100% - 10px);
    right: 0px;
    z-index: 2;
}

.keyboardInputContainerCanCommit {
}

.keyboardInputMessageEditorRow {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    position: relative;
    margin-top: 6px;
    background: rgb(0, 26, 25);
}

.keyboardUndoRow {
    display: flex;
    justify-content: flex-end;
}

.keyboardUndoRowButtons {
    margin-top: 1px;
    display: flex;
    column-gap: 2px;
}

.keyboard .uiChatEditorContainer {
    height: 32px;
    width: calc(100% - 2px);
    position: relative;
    left: 1px;
}

.keyboardEditor {
    position: relative;
    width: 100%;
}

.keyboard .uiChatInput {
    font-weight: normal;
    left: 10px;
    min-height: 18px;
    width: calc(100% - 55px);
    bottom: 8px;
}

.keyboardEditorBg {
    position: absolute;
    left: 0px;
    bottom: 0px;
    background: rgb(227, 227, 227);
    width: 100%;

    padding-top: 6px;
    padding-bottom: 11px;
    min-height: 2px;
}

.keyboardMicButton {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    background: rgb(41, 90, 200);
    display: flex;
    align-items: center;
    height: 50px;
    width: 115px;
    cursor: pointer;
}

.keyboardSpeakIcon  {
    display: flex;
    justify-content: center;
    align-items: center;
}

.keyboardSpeakIcon div  {
    display: flex;
    justify-content: center;
    align-items: center;
}

.keyboardSpeakIcon svg {
    height: 50px;
    width: 50px;
}

.keyboardHeaderButton.keyboardEditorMic svg {
    height: 50px;
    width: 50px;
}

.keyboardMicButton.keyboardEditorMicActive  {
    background: rgb(99, 19, 9);
}


.keyboardHeaderButton.keyboardEditorSend {
    background: rgb(8, 196, 51);
}

.keyboardEditorButtonsLeft {
    position: absolute;
    left: 0;
}

.keyboardEditorButtonsRight {
    position: absolute;
    top: -36px;
    right: 0;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    width: 50px;
    row-gap: 1px;
    backround: yellow;
}

.keyboardHeaderButton.keyboardEditorMenu {
    background: rgb(49, 47, 102)
}

.keyboardHeaderButton.keyboardEditorMenu svg {
    height: 50px;
    width: 50px;
}

.keyboard .uiOKCancel svg {
    height: 50px;
    width: 50px;
}

.keyboardOkCancelRow {
    position: relative;
    width: 100%;
}

.keyboardOkCancelRow .uiOKCancel {
    position: absolute;
    right: 50px;
}

.keyboardEditInstructionLeftIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    min-width: 32px;
    overflow: hidden;
}

.keyboardEditInstructionLeftIcon div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.keyboardEditInstructionLeftIcon svg {
    height: 32px;
    width: 32px;
    fill: #e7e7e7;
}

.keyboardEditIconAndInstruction {
    display: flex;
    width: 100%;
}

.keyboardAutocompleteContainer {
    right: 0;
    bottom: 0;
    width: 100%;
}

.keyboardPopupMenu {
    position: absolute;
    right: 0;
    width: 100%;
}

.keyboardEditorAutocompleteEmbed {
}

.keyboardEditorButtonsLeft {
    display: flex;
    min-width: 100%;
}

.keyboardAutocompleteRow {
    height: 32px;
    width: 100%;
}

.editorAutocompleteCompletion {
    min-height: 34px;
    padding-right: 10px;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background:rgb(51, 51, 51);

    color: #e7e7e7;
    cursor: pointer;
    min-width: 50px;
    white-space: nowrap;
    flex-grow: 1;
}

.keyboardSpeakSettings {
    display: flex;
    align-items: center;
    background: rgb(51, 51, 51);
    position: absolute;
    left: 118px;
    bottom: 0;
}

.keyboardSpeakSettingsActive {
    background: rgb(41, 90, 200);
}

.keyboardSpeakLabel {
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    margin-left: 4px;
}

.keyboardLangLang {
    background: rgb(41, 90, 200);
    height: 32px;
    min-height: 32px;
    display: flex;
    align-items: center;
    padding-left: 0px;
    padding-right: 10px;
    white-space: nowrap;
    cursor: pointer;
}

.keyboardLangMenu {
    background: rgb(0, 26, 25);
    position: absolute;
    bottom: 42px;
    left: 118px;
    display: flex;
    flex-direction: column-reverse;
    row-gap: 1px;
    max-height: 360px;
    overflow-y: auto;
}


.keyboardLangBack {
    background: rgb(41, 90, 200);
    height: 32px;
    width: 32px;
    display: flex;
    position: absolute;
    z-index: 5;
    top: -32px;
    left: 118px;
    justify-content: center;
    align-items: center;
}

.keyboardLangBack div {
    display: flex;
    justify-content: center;
    align-items: center;
}


.keyboardLangBack svg {
    height: 32px;
    width: 32px;
    fill: #e7e7e7;
}

.keyboardLangSubmenu {
    position: absolute;
    display: flex;
    flex-direction: column;
    overflow-y: auto
}

keyboardLangDialectMenu {
    position: relative;
}

.keyboardLangDialects {
    max-height: 350px;
    overflow-y: auto;
    position: relative;
}

.keyboardLangIcon {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.keyboardLangIcon div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.keyboardBlurbContainer {
}

.keyboardBlurb {
    pointer-events: none;
    font-size: 16px;
    position: absolute;
    height: 100%;
    width: calc(100% - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #e7e7e7;
    padding-left: 50px;
    padding-right: 50px;
}

.keyboardBlurbFocused {
    bottom: 25vh;
}

.blurb1 {
    font-size: 16px;
    pointer-events: none;
}

.blurb2 {
    font-size: 15px;
    color: rgb(200, 200, 200);
    padding-left: 15px;
    padding-right: 15px;
}

.keyboardLangIcon {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
}

.inputControl {
    display: flex;
    flex-direction: column;
    row-gap: 1px;
    width: 100%;
    position: relative;
}

.keyboardInputMessageEditorRow {
    position: relative
}

.keyboardInputMessageEditorRow .inputControl {
    position: relative;
    z-index: 999;
}

.inputControlTopRow {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.inputControlEditorPlaceholder {
    font-size: 16px;
    transform: translate(20px, 6px);
    max-width: calc(100% - 20px);
    overflow: hidden;
    color: #6d6d6d;
    pointer-events: none;
    white-space: nowrap;
}

.benxtAppWindows .inputControlEditorPlaceholder {
    transform: translate(20px, 11px);
}

.inputControlMiddleRow {
    width: 100%;
    position: relative;
    display: flex;
}

.inputControlMiddleRowContainer {
    width: 100%;
    position: absolute;
}

.inputControlBottomRow {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    column-gap: 2px;
    background: rgb(0, 26, 25);
}

.inputControlAutocompleteRow {
    min-height: 32px;
    width: 100%;
    column-gap: 1px;
    margin-top: 1px;
    display: flex;
    justify-content: space-between;
}

.inputControlMenu .inputControlBottomRow {
    width: calc(100%);
}

.keyboardInputControlSpeech {
    display: flex;
    align-items: center;
    opacity: 1.0;
    transition: opacity 0.4s;
    width: 100%;
}

.inputControlSpeechSpeaker {
    position: absolute;
    right: 0;
}

.inputControlSpeechLangIcon {
    height: 32px;
    width: 32px;
    background: rgb(50, 70, 100);
    cursor: pointer;
    color: #e7e7e7;
}

.inputControlSpeechLangIcon svg {
    height: 32px;
    width: 32px;
    fill: #e7e7e7;
}

.inputControlSpeechLangMenuIcon  {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    right: 5px;
}

.inputControlSpeechLangVoice {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
}

.inputControlSpeechLangSelected {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inputControlSpeechLangVoice div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.inputControlSpeechLangSelected div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.inputControlSpeechLangVoice svg {
    height: 32px;
    width: 32px;
    fill: #e7e7e7;
}

.inputControlSpeechLangSelected svg {
    height: 32px;
    width: 32px;
    fill: #e7e7e7;
}

.inputControlSpeechLangLang {
    color: #e7e7e7;
    background: rgb(50, 70, 100);
    height: 32px;
    min-height: 32px;
    display: flex;
    align-items: center;
    padding-left: 0px;
    padding-right: 10px;
    white-space: nowrap;
    width: calc(100vw - 132px);
    cursor: pointer;
}

.langMenuSep {
    min-height: 1px;
    height: 1px;
    width: 100%;
    background: rgb(0, 26, 25);
}

.benxtAppDesktop .inputControlSpeechLangLang {
    width: calc(700px - 232px);
}

.inputControlSpeechLangContainer {
    position: relative;
    min-height: 32px;
}

.inputControlSpeechLangMenuContainer {
 
}

.inputControlSpeechLangMenu {
    display: flex;
    left: 0px;
    flex-direction: column-reverse;
    max-height: 360px;
    overflow-y: auto;
    position: absolute;
    bottom: 41px;
    z-index: 100;
}

.inputControlLangMenuDownward .inputControlSpeechLangMenu {
    z-index: 3;
    top: 32px;
    padding-top: 1px;
    bottom: auto;
    flex-direction: column;
}

.inputControlLangMenuDownward .inputControlSpeechLangSubmenu {
    padding-top: 1px;
    height: auto;
    z-index: 4;
    transform: auto;
    flex-direction: column;
    position: absolute;
    bottom: auto;
    top: 121px;
}

.keyboardInputControlSpeechLangSelection {
    position: absolute;
    left: 121px;
    min-height: 32px;
    min-width: 32px;
}

.inputControlSpeechLangBack {
    background: rgb(50, 70, 100);
    height: 32px;
    width: calc(100% - 24px);
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 0px;
    margin-left: 0px;
    margin-top: -1px;
}

.inputControlSpeechLangSubmenuLang {
    font-weight: 550;
    position: absolute;
    left: 32px;
    color: #e7e7e7;
    display: flex;
    align-items: center;
    height: 100%;
}

.benxtAppDesktop .inputControlSpeechLangBack {
    width: calc(100%);
}

.inputControlLangMenuDownward .inputControlSpeechLangSubmenu {

}

.inputControlLangMenuDownward .inputControlSpeechLangMenu {

}

.inputControlSpeechLangBackIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
}

.inputControlSpeechLangBackIcon div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
}


.inputControlSpeechLangBack svg {
    height: 32px;
    width: 32px;
    fill: #e7e7e7;
}

.inputControlSpeechLangSubmenu {
    display: flex;
    flex-direction: column;
    row-gap: 1px;
    width: calc(100vw - 105px);
    transform: translate(0, -32px);
    background: rgb(0, 26, 25);
    padding-top: 2px;
}

.inputControlLangMenuDownward .inputControlSpeechLangSubmenu {
    transform: translate(0, -80px);
}

.benxtAppDesktop .inputControlSpeechLangSubmenu {
    width: calc(700px - 230px);
}

inputControlSpeechLangDialectMenu {
    position: relative;
}

.inputControlSpeechLangDialects {
    max-height: 350px;
    overflow-y: auto;
    position: relative;
}

.inputControlSpeechLangIcon {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inputControlSpeechLangIcon div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.inputControlSpectrumAnalyzer {
    height: 32px;
    min-width: calc(100% - 145px);
    width: calc(100% - 145px);
    position: absolute;
    left: 161px;
}

.inputControlSpectrumAnalyzer .micSpectrumAnalyzerContainer {
    width: calc(100% - 32px);
    margin-left: 39px;
    height: 32px;
}

.inputControlSpectrumAnalyzer canvas {
    height: 32px;
}


.inputControlInputEditorContainer {
    height: 32px;
    position: relative;
    width: 100%;
}

.editorAutocomplete {
    height: 50px;
    color: #e7e7e7;
}

.editorAutocompletes {
    z-index: 1;
    width: 100%;
    height: 50px;
    position: absolute;
    top: 100%;
}

.inputControlInputEditorInput {
    font-size: 16px;
    white-space: pre-wrap;
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
    font-weight: normal;
    /*
    left: 10px;
    min-height: 18px;
    width: calc(100% - 55px);
    bottom: 8px;
    width: calc(100% - 45px);
    font-size: 16px;
    left: 0px;
    pointer-events: auto;
    min-height: 18px;
   */
    min-height: 20.5px;
    left: 20px;
    width: calc(100% - 55px);
    font-size: 16px;
    color: black;
    position: absolute;
    bottom: 6px;
}

.benxtAppWindows .inputControlInputEditorInput {
    bottom: 10px;
    transform: translate(0px, 1px);
}

.benxtAppWindows .inputControlEditorDownward .inputControlInputEditorInput {
    top: 12px;
    transform: none;
}

.inputControlInputEditorInput:focus {
    outline: none;
}

.inputControlInputEditor.inputControlEditorDownward {
    width: calc(100% - 32px);
}

.inputControlEditorDownward .inputControlInputEditorInput {
    bottom: auto;
    top: 6px;
}

.inputControlInputEditorBg {
    position: absolute;
    left: 0px;
    bottom: 0px;
    background: #A2A2A2;
    background: #e5e5e5;
    width: 100%;
    min-height: 32px;
}

.inputControlEditorDownward .inputControlInputEditorBg {
    bottom: auto;
    top: 0px;
}

.inputControlInputEditor {
    flex-grow: 1;
    position: relative;
    min-height: 32px;
}

.inputControlEditorCross {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    cursor: pointer;
    z-index: 10;
}

.inputControlEditorCross div {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inputControlEditorCross svg {
    height: 32px;
    width: 32px;
    fill: #bbbbbb  !important; 
}

.inputControlEditorFocused .inputControlEditorCross svg {
    fill: #bbbbbb !important; 
}

.inputControlKeyboardButton {
    height: 32px;
    width: fit-content;
}

.inputControlKeyboardButton .uiOKCancel {

}

.inputControlKeyboardButton1 {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inputControlKeyboardButton1 svg {
    height: 32px !important;
    width: 32px !important;
}

.inputControlKeyboardButton svg {
    height: 32px !important;
    width: 32px !important;
}

.inputControlKeyboardButton1Disabled {
    
}

.inputControlKeyboardButton1Disabled .uiOKCancelCancel {

}

.iconRedo .uiOKCancelCancel {
}

.iconUndo {
}

.iconCanUndo {

}

.iconRedo {
}

.iconCanRedo {

}

.inputControlKeyboardButton1.iconStop .uiOKCancelCancel {
    background: rgb(181, 67, 26);
}
.inputControlKeyboardButton1.iconPlay .uiOKCancelCancel {
    background: rgb(0, 144, 129);
}

.inputControlKeyboardButton1.iconSave .uiOKCancelCancel {
    background: rgb(49, 47, 102);
}

.inputControlKeyboardButton1.iconSpeaker .uiOKCancelCancel {
    background: rgb(60, 100, 200);
}

.inputControlKeyboardButton1.iconSpeakerActive .uiOKCancelCancel {
    background: rgb(138, 0, 0);
}

.inputControlKeyboardButton1 .uiOKCancelCancel {
    background: rgb(8, 59, 50);
}

.inputControlEditorFocused .inputControlInputEditorBg {
    background: #e5e5e5;
}

.keyboardInputControlSpeech .inputControlKeyboardButton .uiOKCancelOK {
    background: rgb(60, 100, 200);
}

.keyboardInputControlSpeechActive .inputControlKeyboardButton .uiOKCancelOK {
    background: rgb(200, 75, 25);
}

.keyboardInstructionInput {
    width: 100%;
    display: flex;
    min-height: 145px;

    bottom: -100px;
}

.keyboardButtonApplyLeft {
    margin-right: auto;
    display: flex;
    column-gap: 1px;
}

.template {
    width: calc(100% - 65px);
    display: inline-block;
}

.templateVariableContainer {
    position: relative;
    display: inline;
}

.templateVariableSelectionList {
    position: absolute;
    z-index: 99;
    background: rgb(49, 47, 102);
    filter: drop-shadow(4px 4px 4px rgb(0, 0, 0, 0.7));
    padding: 5px;
    transform: translate(-15px, 0);
}
.templateVariableSelectListOption {
    white-space: nowrap;
    display: flex;
    padding-left: 10px;
    paddign-right: 10px;
}

.templateVariable {
    display: inline;
    background: rgb(255, 255, 255, 0.15);
    color: #eeeeee;
    cursor: pointer;
    vertical-align: top;
    font-weight: 500;
}

.templateVariableContainerNested .templateVariable {
/*    white-space: nowrap; */
}

.templateVariable:focus {
    outline: none;
}

.templateVariableSelected .templateVariable {
    color: rgb(2, 77, 87);
    background: #e7e7e7;
}

.keyboardCode {
    width: calc(100vw - 10px);
    transform: translate(-32px, 0);
    color: #333333;
    font-size: 12px;
    position: relative;
    margin-top: 5px;
}

.benxtAppDesktop .keyboardCode {
    width: calc(min(700px, 100vw) - 110px);
}

.keyboardCode .cm-scroller {
    width: 100%;
}

.keyboardCode .cm-content {
}

.keyboardCodeCopy {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.keyboardCodeCopy .uiOKCancelCancel {
    background: rgb(1, 160, 144);
}

.keyboardMenuItemIcon .IntelliKeyLogo {
    height: 32px;
    width: 32px;
}

.blockParagraph {

}

.blockListItemSelected {
    color: rgb(2, 77, 87);
    background: #eeeeee;
}


.inputControlTempSlider {
    height: 32px;
    position: absolute;
    width: 100%;
    top: 82px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.inputControlTempSlider div {
    padding: 5px;
    color: white;
}

.tableFunContainer {
    display: flex;
    padding: 1px;
    width: 100%;
    overflow-x: auto;
}

.tableFun {
    font-size: 15px;
    display: table;
    border-collapse: collapse;
    color: #e7e7e7;
    padding: 0;
}

.tableRowFun {
    display: table-row;
    padding: 0;
}

.tableHeaderFun {
    display: table-row;
}

.tableColumnFun {
    display: table-cell;
    text-align: center;
    border-spacing: 0;
    border: 1px solid #e7e7e7;
}

.tableColumnFun input {
    border: none;
    appearance: none;
    padding: 5px;
    color: #e7e7e7;
    font-size: 15px;
    background: rgb(2, 77, 87);
    box-sizing: content-box;
}

.tableColumnFunCell {
    display: inline-block;
    padding: 5px;
    color: #e7e7e7;
    font-size: 15px;
    background: rgb(2, 77, 87);
    box-sizing: content-box;
}

.tableColumnFun input:focus {
    outline: none;
    padding: 5px;
    color: #333333;
    background: #e7e7e7;
    font-size: 15px;
}

.tableHeaderFun .tableColumnFun input {
    font-size: 15px;
    text-align: center;
}


.keyboardInstructionListContainer {
    width: 100%;
    overflow: hidden;
    padding-top: 32px;    
}

.keyboardInstructionListContainerNonEmpty {

}

.cancelIcon .uiOKCancelOK {
    background: rgb(138, 0, 0);
}

.keyboardTemplateVariableDisplay {
    font-weight: 500;
    color: #f0f0f0;
}

.keyboardEditDocumentText code {
    color: #f0f0f0;
    display: inline;
    font-weight: 500;
    font-family: apparat;
}

.IKBackground
{
  background : linear-gradient(-45deg, rgba(5, 29, 41, 1) 0%, rgba(2, 26, 29, 1) 50.41%, rgba(0, 24, 23, 1) 100%);
}

.bnInputFieldClear {

}

.inputControlEditorIcon svg {
    height: 32px;
    width: 32px;
    position: absolute;
    left: 0;
}

.inputControlEditorIcon svg path {
    fill: #6d6d6d;
}

.inputControlEditorIcon  {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inputControlEditorIcon div {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inputControlEditorWithIcon .inputControlInputEditorInput {
    left: 32px;
    width: calc(100% - 55px - 12px);
}

.inputControlEditorWithIcon .inputControlEditorPlaceholder {
    transform: translate(32px, 6px);
    max-width: calc(100% - 20px);
}
