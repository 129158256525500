.slider {
    position: relative;
    font-size: 0px;
    height: 10px;
    transform: translate3d(0, 0, 0);
    display: flex;
    justify-content: center;
}

.dot-holders {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 10px;
}

.dot-holder {
    width: 10px;
    height: 10px;
    background-color: lightGray;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
}

.dot-holder-clickable {
    cursor: pointer;
}

.dot {
    width: 8px;
    height: 8px;
    background-color: rgb(59, 101, 201);
    background-color: rgb(12, 28, 28);
    position: absolute;
    left: 1px;
    top: 1px;
    border-radius: 50%;
    transition: left 200ms ease-in;
    pointer-events: none;
}

