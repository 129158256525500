.bnClient.benxtAppDesktop {
    display: flex;
    width: 100%;
    justify-content: center;
    height: 100%;
}

.benxtAppDesktop .keyboardSplash {
    position: absolute;
    font-family: Apparat;
}


/*
.benxtAppDesktop  .bnPageBody {
    width: 600px;
    padding-left: calc((100vw - 600px)/2);
    padding-right: calc((100vw - 600px)/2);
    clip-path: inset(0 calc((100vw - 600px)/2) 0 calc((100vw - 600px)/2));
}
.benxtAppDesktop  .keyboardHome {
    width: 600px;
    padding-left: calc((100vw - 600px)/2);
    padding-right: calc((100vw - 600px)/2);
    clip-path: inset(0 calc((100vw - 600px)/2) 0 calc((100vw - 600px)/2));
}

.benxtAppDesktop .keyboardLogin {
    width: 600px;
    padding-left: calc((100vw - 600px)/2);
    padding-right: calc((100vw - 600px)/2);
    clip-path: inset(0 calc((100vw - 600px)/2) 0 calc((100vw - 600px)/2));
}

.benxtAppDesktop .keyboardWordPackContent {
    width: 600px;
    padding-left: calc((100vw - 600px)/2);
    padding-right: calc((100vw - 600px)/2);
    clip-path: inset(0 calc((100vw - 600px)/2) 0 calc((100vw - 600px)/2));
}
*/

.bnAppContent {
    padding-top: calc(env(safe-area-inset-top));
    height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    width: 100%;
    position: fixed;
}

.benxtAppNative .bnAppContent {
    background: rgb(0, 9 5);
}

.benxtAppDesktop .bnAppContent {
    width: 100%;
    height: 100%;
    padding: 1px;
    position: absolute;
    top: 0;
    left: calc(max(0px, (100vw - 1200px) / 2));
    width: calc(min(100vw, 1200px));
}

.benxtAppDesktop .keyboardApp {
    width: 100%;
    top: 0;
    left: 0;
    width: 100%;
    position: relative;
    height: calc(100% - 0px);
}

.benxtAppDesktop .keyboardAppContent {
}


.keyboardApp {
    position: absolute;
    width: calc(100% - env(safe-area-inset-left));
    height: calc(100% - 0px - env(safe-area-inset-top));
    background: #000a05;
    display: flex;
    flex-direction: column;
}


.keyboardAppContent {
    height: 100%;
    width: 100%;
}

.bnPhoneSignIn .bnForm {
    padding-top: 0;
}


#recaptcha-fun {
    display: none;
}

iframe {
    top: env(safe-area-inset-top);
}

.keyboardSignIn {
    margin-top: calc(60px + 30px);
}

.keyboardVerificationCode {
    margin-top: calc(60px)
}

.keyboardSplashLogo img {
    height: 60px;
    width: 60px;
}

.keyboardSplash {
    position: fixed;
    background: rgb(4, 28, 35);
    color: #e7e7e7;
    font-family: -apple-system, apparat;
    font-weight: 500;
    font-size: 16px;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: 99;
}

.benxtAppDesktop .keyboardSplash {
    max-width: 700px;
}

.keyboardSplashContent {
    position: absolute;
    top: 140px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.keyboardLoginInProgressPage {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 100;
}

.attunewiseHeader {
    height: calc(env(safe-area-inset-top) + 80px);
    background: rgb(0, 9 5);
    position: relative;
}

.benxtAppDesktop .attunewiseHeader {
    width: calc(min(1200px, 100vw));
}

.attunewiseHeader img {
    height: 30px;
    width: 30px;
}

.attunewise {
    display: flex;
}

.benxtAppNative .attunewiseHeader {
    height: 80px;
}

.attune {
    font-weight: 400;
    color: rgb(0, 10, 6);
}

.wise {
    color: rgb(37, 77, 57);
}

.attunewiseHeaderBottomRow {
    display: flex;
    align-items: center;
    position: absolute;
    left: 10px;
    bottom: 10px;
    justify-content: space-between;
    width: calc(100% - 15px);
}

.attunewiseHeaderBottomRowLeft {
    display: flex;
    column-gap: 5px;
    align-items: center;
}

.attunewiseHeaderBottomRowRight {
    display: flex;
    align-items: center;
}

.attunewiseHeaderBottomRowRight .simpleButton .simpleIcon svg path {
    fill: rgb(0, 26, 25) !important;
}

.attunewiseHeaderBottomRowRight .simpleButton .simpleIcon svg circle {
    fill: rgb(0, 26, 25) !important;
}

.attunewiseLargeLogo svg {
    height: 32px;
}
