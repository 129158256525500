.fakeInputFieldContainer {
    margin-top: 4px;
    background: rgb(1, 42, 44);
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 10px;
    padding-bottom: 5px;
}

.fakeInputField {
    color: black;
    background: #e7e7e7;
    opacity: 0.9;
    display: flex;
    align-items: center;
    padding: 7px;
}

.searchError {
    margin-top: 30px;
    color: #e7e7e7;
    display: flex;
    align-items: center;
    column-gap: 4px;
}

.searchError .simpleButton {
    background: rgb(3, 74, 122);
}

.searchErrorMessage {
    background: rgb(95, 18, 10);
    padding-left: 10px;
    padding-right: 10px;
    color: #e7e7e7;
    height: 100%;
    display: flex;
    align-items: center;
}

.google-results {
    margin-top: 4px;
    width: 100%;
    font-family: arial, sans-serif;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
}

.result-item {
    padding: 32px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: calc(100% - 64px);
    background: rgb(1, 42, 44);
}

.result-url {
    color: #e7e7e7;
    font-size: 14px;
    margin-bottom: 4px;
    display: block;
    max-width: 100%;
    overflow: hidden;
}

.result-title {
    color: #6dad88;
    font-size: 16px;
    margin-bottom: 6px;
    text-decoration: none;
    font-weight: 550;
    display: block;
    cursor: pointer;
    max-width: 100%;
}

.result-title:hover {
    text-decoration: underline;
    cursor: pointer;
}

.result-snippet {
    color: #bbbbbb;
    font-size: 14px;
    line-height: 1.58;
}
