.apiHighlights {
    height: 100%;
    width: 100%;
}

.apiHighlightsBlurb {
    font-size: 14px;
    display: flex;
    width: 100%;
    justify-content: center;
}
.apiHighlightsBlurb .markdownContainer {
    width: auto;
}

.apiHighlights img {
    width: 500px;
}

.apiHighlightsImgContainer {
    display: flex;
    width: 100%;
    margin-top: 0px;
    justify-content: center;
}
