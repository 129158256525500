.scrollbar-container {
    padding: 0px;
    height: 100%;
    display: flex;
    column-gap: 4px;
}

.viewport-indicator {
  background-color: rgba(255, 255, 255, 0.4);
  position: absolute;
  left: 0;
  width: 100%;
  min-height: 1px;
  display: none;
}

.minimap-viewport-indicator {
  background-color: rgba(255, 255, 255, 0.6);
  position: absolute;
  left: 0;
  width: 100%;
  min-height: 1px;
}

.minimap-track {
  position: relative;
  width: 10px;
  height: 100%;
}

.scrollbar-track {
  position: relative;
  width: 10px;
  background-color: rgb(60, 101, 201);
}

.dataset-minimap {
  position: relative;
  width: 10px;
  height: 100%;
  background-color: rgba(60, 101, 201, 0.2);
}

.minimap-thumb {
    min-height: 1px;
    background-color: rgb(60, 101, 201);
    width: 10px;
}

.window-indicator {
  position: absolute;
  left: 0;
  width: 100%;
  background-color: rgb(60, 101, 201);
  min-height: 1px;
  cursor: pointer;
}

.thumb-indicator {
  position: absolute;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  min-height: 1px;
  cursor: pointer;
}

.scrollbar-thumb {
    position: absolute;
    left: -5px;
    width: 20px;
    height: 10px;
    background-color: rgb(60, 101, 201);
    cursor: pointer;
    filter: drop-shadow(-2px 2px 2px rgb(0, 25, 26, 0.8));
}

.stats-container {
  margin-top: 16px;
}

.stat-item {
  font-size: 14px;
  margin-bottom: 8px;
}
