.toolContainer {
    margin-left: 0;
    width: calc(100% - 0px);
}

.toolContainer .toolCode {
    white-space: pre-wrap;
    padding: 10px;
    font-size: 14px;
}
